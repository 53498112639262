.formRow {
  margin-top: 20px;
}

.button {
  margin-right: 10px;
}

.fullHeight {
  height: 100%;
}

.fullHeightTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fitContentTableContainer {
  width: fit-content;
  flex: 1;
  overflow-y: auto;
}

.workflowsTable select,
.workflowsTable input {
  margin-top: 5px;
  width: 100%;
}

.workflowsTable {
  border-collapse: separate;
  border-spacing: 0;
}

.workflowsTable th {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  z-index: 1;
  white-space: nowrap;
  vertical-align: top !important;
}

.workflowsTable td {
  border-right: 1px solid #dee2e6;
}

.workflowsTable th:first-child,
.workflowsTable td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid #dee2e6;
}

.workflowsTable tr:last-child td {
  /* Apply a left border on the first <td> or <th> in a row */
  border-bottom: 1px solid #dee2e6;
}

.workflowsTable {
  overflow-x: inherit;
}

.workflowsTableContainer {
  overflow-x: inherit;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.queryRow {
  margin-bottom: 20px;
}

.pagination button {
  margin: 5px;
}

.actionButton {
  height: 38px;
  margin: 5px;
}

.actionButton svg {
  margin: -3px -4px 2px -8px;
  width: 25px;
}

.actionsRow {
  width: 305px;
}

.topButtons {
  margin: 5px;
}

.workflowModalBody,
.workflowModalFooter,
.workflowModalHeader {
  padding: 50px;
}

.workflowModalBodyRow {
  padding: 0 20px;
}

.workflowModalFooterRow {
  padding: 20px;
  text-align: left;
}

.workflowModal {
  max-width: 90%;
}

.workflowModalCheckbox {
  margin-left: 0px;
  width: 25px;
  height: 25px;
}

.toast {
  max-width: 100% !important;
  --bs-toast-max-width: 100% !important;
  margin-bottom: 20px;
}

.toastBody,
.toastHeader {
  max-width: 100% !important;
  --bs-toast-max-width: 100% !important;
}

.selectableRow {
  cursor: pointer;
}

.contactsCount {
  padding: 3px;
}
