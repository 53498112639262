import * as React from 'react';
import { TabPane, Row, Col } from 'reactstrap';
import { Credentials } from './Credentials';
import { ArchivedCredentials } from './ArchivedCredentials';
import { CREDENTIALS_TAB, ARCHIVED_CREDENTIALS_TAB } from './AccountForm';
import { useState } from 'react';
import { useOnCall } from '../../hooks/firebase';
import { Loader } from '../../components/Loader';
import { logger } from '../../logging';

interface GetCredentialsProps {
  system: string;
  account: {
    key: string;
  };
}

export const CredentialsTabs = ({ account, system, setAccountHasCreds }) => {
  const [reload, setReload] = useState(false);
  const [credentialsResponse, loading, error] = useOnCall(
    'accountManager-getCredentials',
    {
      system,
      account: {
        key: account.key,
      },
    },
    reload
  );

  let credentials = [];

  const env = process.env.NODE_ENV;
  // logger.debug(`CredentialsTab: Node env: ${env}`);

  if (credentialsResponse) {
    credentials = credentialsResponse.data;
  }

  if (loading) {
    return (
      <TabPane tabId={CREDENTIALS_TAB}>
        <Loader loading={true} />
      </TabPane>
    );
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <React.Fragment>
      <TabPane tabId={CREDENTIALS_TAB}>
        <Row>
          <Col sm="12">
            <Credentials
              system={system}
              account={account}
              credentials={credentials}
              setReload={setReload}
              reload={reload}
              setAccountHasCreds={setAccountHasCreds}
            />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId={ARCHIVED_CREDENTIALS_TAB}>
        <Row>
          <Col sm="12">
            <ArchivedCredentials
              system={system}
              account={account}
              credentials={credentials}
              setReload={setReload}
              reload={reload}
            />
          </Col>
        </Row>
      </TabPane>
    </React.Fragment>
  );
};
