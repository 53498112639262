import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { AbilityContext } from '../../../auth/Can';
import * as R from 'ramda';
import { logger } from '../../../logging';
import { ref } from '../../../utils/firebase';
import { ref as fbRef, update } from 'firebase/database';
import { checkIfOptinActive, updateBrazeOptin } from './optins';
import { Loader } from '../../../components/Loader';
import { BrazeSMSCountryAllowListRow } from './BrazeSMSCountryAllowListRow';

const brazeStyles = require('./braze.css');

export const BrazeOptinRow = ({
  channel,
  account,
  enumerations,
  setTabSaving,
  showSubscriptionGroup,
  handleOptinActiveChange,
}) => {
  // logger.debug(`[BrazeOptinRow] channel: ${JSON.stringify(channel)}`);
  const [saving, setSaving] = useState(false);
  const [tag, setTag] = useState(
    account && account.optins && account.optins[`${channel.value}_channel_tag`]
      ? account.optins[`${channel.value}_channel_tag`]
      : account
      ? `${account.party_name}_${account.account_name}_${channel.value}`
      : ''
  );
  const [category, setCategory] = useState(
    account &&
      account.optins &&
      account.optins[`${channel.value}_channel_category`]
      ? account.optins[`${channel.value}_channel_category`]
      : 'main_artist'
  );
  const [subscription_group_id, setSubscriptionGroupId] = useState(
    account &&
      account.optins &&
      account.optins[`${channel.value}_channel_subscription_group_id`]
      ? account.optins[`${channel.value}_channel_subscription_group_id`]
      : ''
  );
  const [subscription_group_name, setSubscriptionGroupName] = useState(
    account &&
      account.optins &&
      account.optins[`${channel.value}_channel_subscription_group_name`]
      ? account.optins[`${channel.value}_channel_subscription_group_name`]
      : ''
  );
  const [loading, setLoading] = useState(true);
  const [isOptinActive, setIsOptinActive] = useState(false);
  useEffect(() => {
    logger.debug(
      `[BrazeEmailOptin][useEffect] about to checkIfOptinActive(${account.account_id}_${channel.value})`
    );
    const fetchActive = async () => {
      const isActive = await checkIfOptinActive(
        `${account.account_id}_${channel.value}`
      );
      logger.debug(`[BrazeEmailOptin][useEffect] isActive: ${isActive}`);
      setIsOptinActive(isActive);
      handleOptinActiveChange(
        `${account.account_id}_${channel.value}`,
        isActive
      );
      setLoading(false);
    };

    fetchActive();
  }, []);

  const ability = useContext(AbilityContext);

  const handleTagChanged = (e) => {
    setTag(e.target.value);
  };

  const handleCategoryChanged = (e) => {
    setCategory(e.target.value);
  };

  const handleSubscriptionGroupIdChanged = (e) => {
    setSubscriptionGroupId(e.target.value);
  };

  const handleSubscriptionGroupNameChanged = (e) => {
    setSubscriptionGroupName(e.target.value);
  };

  const handleActiveChanged = (e) => {
    logger.debug(
      `[BrazeOptinRow][handleActiveChanged] e.target.checked: ${e.target.checked}`
    );
    setIsOptinActive(e.target.checked);
  };

  const saveBrazeOptins = async () => {
    logger.debug(
      `[BrazeOptinRow][saveBrazeOptins] save called to update ${channel.value} tag: ${tag}, category: ${category}`
    );

    setSaving(true);
    setTabSaving(true);
    try {
      // 1 update account settings
      // accounts/braze/[]/optins/sms_channel_tag/
      // accounts/braze/[]/optins/sms_channel_category/
      const accountOptinsRef = ref(`accounts/braze/${account.key}/optins/`);
      const optinsToUpdate = {};
      optinsToUpdate[`${channel.value}_channel_tag`] = tag;
      optinsToUpdate[`${channel.value}_channel_category`] = category;
      optinsToUpdate[`${channel.value}_channel_subscription_group_id`] =
        subscription_group_id;
      optinsToUpdate[`${channel.value}_channel_subscription_group_name`] =
        subscription_group_name;
      await update(accountOptinsRef, optinsToUpdate);

      // 2 update the optin
      logger.debug(
        `[BrazeOptinRow][saveBrazeOptins] now updating optin, account: ${JSON.stringify(
          account
        )}`
      );

      await updateBrazeOptin({
        channel: channel.value,
        active: isOptinActive,
        tag,
        category,
        account,
        target_id: `${account.account_id}_${channel.value}`,
        subscription_group_id,
        subscription_group_name,
      });

      handleOptinActiveChange(
        `${account.account_id}_${channel.value}`,
        isOptinActive
      );
    } catch (e) {
      logger.error(
        `[BrazeOptinRow][saveBrazeOptins] error updating account or optin: ${e}`
      );
    }

    setSaving(false);
    setTabSaving(false);
  };

  const categories = enumerations.enumerations.brazeCategories;
  const categoryCodes = Object.keys(categories);
  const categoryOptions = R.map((categoryCode) => {
    return (
      <option value={categoryCode} key={categoryCode}>
        {categories[categoryCode].label}
      </option>
    );
  }, categoryCodes);

  const saveDisabled = () => {
    if (
      (showSubscriptionGroup === true &&
        (!subscription_group_id || !subscription_group_name)) ||
      !tag
    ) {
      return true;
    }
    return false;
  };

  if (loading || !enumerations) {
    return <Loader loading={true} />;
  }

  const isActiveDisabled = account.active === false;

  return (
    <React.Fragment>
      <Row
        className={
          channel.value === 'sms'
            ? brazeStyles.smsOptinEnabledRow
            : brazeStyles.optinEnabledRow
        }
      >
        <Col sm={1}>
          <Label for={`${channel.value}_channel_channel`}>Channel</Label>
          <Input
            id={`${channel.value}_channel_channel`}
            type="text"
            disabled
            value={channel.label}
          ></Input>
        </Col>
        <Col sm={2}>
          <Label for={`${channel.value}_channel_tag`}>Tag</Label>
          <Input
            id={`${channel.value}_channel_tag`}
            type="text"
            value={tag}
            onChange={handleTagChanged}
          ></Input>
        </Col>
        <Col sm={2}>
          <Label for={`${channel.value}_channel_category`}>Category</Label>
          <Input
            id={`${channel.value}_channel_category`}
            type="select"
            value={category}
            onChange={handleCategoryChanged}
          >
            {categoryOptions}
          </Input>
        </Col>
        {showSubscriptionGroup && (
          <Col sm={2}>
            <Label for={`${channel.value}_channel_subscription_group_id`}>
              Subscription Group ID
            </Label>
            <Input
              id={`${channel.value}_channel_subscription_group_id`}
              type="text"
              value={subscription_group_id}
              onChange={handleSubscriptionGroupIdChanged}
            ></Input>
          </Col>
        )}
        {showSubscriptionGroup && (
          <Col sm={2}>
            <Label for={`${channel.value}_channel_subscription_group_name`}>
              Subscription Group Name
            </Label>
            <Input
              id={`${channel.value}_channel_subscription_group_name`}
              type="text"
              value={subscription_group_name}
              onChange={handleSubscriptionGroupNameChanged}
            ></Input>
          </Col>
        )}
        <Col sm={1}>
          <FormGroup switch className={brazeStyles.channel_switch}>
            <Input
              type="switch"
              id={`${channel.value}_channel_enabled`}
              name={`${channel.value}_channel_enabled`}
              checked={isOptinActive}
              disabled={isActiveDisabled}
              onChange={handleActiveChanged}
            />
            <Label for={`${channel.value}_channel_enabled`}>
              {channel.label} Channel Optin Enabled
            </Label>
          </FormGroup>
        </Col>
        <Col sm={1}>
          <Button
            color="primary"
            className={brazeStyles.optinSaveButton}
            onClick={(e) => saveBrazeOptins()}
            disabled={
              ability.cannot('manage', 'braze_optins') ||
              saving ||
              saveDisabled()
            }
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </Col>
      </Row>
      {channel.value === 'sms' && (
        <BrazeSMSCountryAllowListRow
          account={account}
          setTabSaving={setTabSaving}
          key={channel.value}
        />
      )}
    </React.Fragment>
  );
};
