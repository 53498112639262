import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import React, { useState } from 'react';
import StepButton from '@mui/material/StepButton';
import { logger } from '../logging';

const stepperStyles = require('./stepper.css');

export const StepperBar = ({ steps, toggle }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  // const [completed, setCompleted] = React.useState<{
  //   [k: number]: boolean;
  // }>({});

  // const totalSteps = () => {
  //   return steps.length;
  // };

  // const completedSteps = () => {
  //   return Object.keys(completed).length;
  // };

  // const handleComplete = () => {
  //   setCompleted({
  //     ...completed,
  //     [activeStep]: true,
  //   });
  // };

  return (
    <Stepper nonLinear activeStep={activeStep} alternativeLabel>
      {steps.map((step, index) => {
        // logger.debug(`[StepperBar] step: ${JSON.stringify(step)}`);
        return (
          <Step
            key={step.label}
            completed={step.complete}
            onClick={() => {
              if (step.disabled) {
                return;
              }
              toggle(step.tab);
              setActiveStep(step.step);
            }}
            className={stepperStyles.stepper}
            disabled={step.disabled}
          >
            <StepButton>{step.label}</StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};
