import { useEffect, useRef } from 'react';
import useEventCallback from '@restart/hooks/useEventCallback';
import useClickOutside from './useClickOutside';
import { noop } from '../../utils';
function useRootClose(onRootClose, options) {
  var ref = useRef(null);
  var onClose = onRootClose || noop;
  useClickOutside(ref, onClose, options);
  var handleKeyUp = useEventCallback(function (e) {
    if (e.key === 'Escape') {
      onClose(e);
    }
  });
  useEffect(function () {
    var _ref$current;
    if (options.disabled || ref == null) return undefined;
    var doc = ((_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.ownerDocument) || document;

    // Store the current event to avoid triggering handlers immediately
    // https://github.com/facebook/react/issues/20074
    var currentEvent = (doc.defaultView || window).event;
    var onKeyUp = function onKeyUp(e) {
      // skip if this event is the same as the one running when we added the handlers
      if (e === currentEvent) {
        currentEvent = undefined;
        return;
      }
      handleKeyUp(e);
    };
    doc.addEventListener('keyup', onKeyUp);
    return function () {
      doc.removeEventListener('keyup', onKeyUp);
    };
  }, [ref, options.disabled, handleKeyUp]);
  return ref;
}
export default useRootClose;