import * as React from 'react';
import {
  Input,
  Button,
  Collapse,
  ListGroup,
  ListGroupItem,
  FormGroup,
  FormFeedback,
  Alert,
} from 'reactstrap';
import { ref } from '../../utils/firebase';
import { useState, useContext } from 'react';
import { AbilityContext } from '../../auth/Can';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import { arrayToOptions } from '../../utils/react';
import { logger } from '../../logging';
import { getDAMApp } from '../../services/firebase';
import moment from 'moment';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const accountStyles = require('./account.css');

const CredentialsAuditRow = ({ credentialAudit }) => {
  return (
    <ListGroupItem>
      Action: {credentialAudit.action} | User: {credentialAudit.email} | Date:{' '}
      {credentialAudit.date}
    </ListGroupItem>
  );
};

export const CredentialsRow = ({
  setError,
  system,
  credential,
  account,
  accountId,
  reload,
  setReload,
}) => {
  const damApp = getDAMApp();
  const functions = getFunctions(damApp);
  const damAuth = getAuth(damApp);
  const [credentialTypes] = useListVals(
    ref('enumerations/credentialTypes') as any
  );

  // useobject val fixes a setState on unmounted error
  const [credentialsAuditResult, credentialsAuditLoading] = useObjectVal(
    ref(`credentials_audit/${credential.key}`) as any
  );
  const [credentialsAudit, setCredentialsAudit] = useState([]);

  if (
    !credentialsAuditLoading &&
    credentialsAuditResult &&
    credentialsAudit.length === 0
  ) {
    setCredentialsAudit(
      Object.keys(credentialsAuditResult).map((key) => {
        const val = credentialsAuditResult[key];
        return { ...val, key };
      })
    );
  }

  const [name, setName] = useState(credential.name);
  const [username, setUsername] = useState(credential.username);
  const [password, setPassword] = useState(credential.password);
  const [webhookSecret, setWebhookSecret] = useState(credential.webhook_secret);
  const [appName, setAppName] = useState(credential.app_name);
  const [apiKeyName, setApiKeyName] = useState(credential.api_key_name);
  const [apiKey, setApiKey] = useState(credential.api_key);
  const [apiToken, setApiToken] = useState(credential.api_token);
  const [apiTokenLabel, setApiTokenLabel] = useState(
    credential.api_token_label
  );
  const [apiTokenCreatedDate, setApiTokenCreatedDate] = useState(
    credential.api_token_created_date
  );
  const [daysLeft, setDaysLeft] = useState(
    365 - moment().diff(moment.unix(credential.api_token_created_date), 'days')
  );
  const [loading, setLoading] = useState(false);

  const ability = useContext(AbilityContext);

  const user = damAuth.currentUser;

  const [isOpen, setIsOpen] = useState(false);

  if (credentialsAuditLoading) {
    return <tr></tr>;
  }

  const checkCommunityCredential = async () => {
    const callable = httpsCallable(
      functions,
      'accountManager-callableCheckCommunityToken'
    );
    await callable({ account_id: account.account_id, api_token: apiToken });
  };

  const updateCredentials = async () => {
    try {
      setLoading(true);

      // check validity of community cred before attempting to store in vault
      try {
        if (system === 'community') {
          await checkCommunityCredential();
        }
      } catch (e) {
        if (e.status) {
          logger.error(`e.status = ${e.status}`);
        }
        logger.error(`Error with Community Credential: ${e}`);
        setLoading(false);
        setError('Error checking Community Credential');
        return;
      }

      const callable = httpsCallable(
        functions,
        'accountManager-updateCredential'
      );
      const data: any = {
        system,
        accountId,
        id: credential.key,
        name,
        ...(system !== 'treasure_data' &&
          system !== 'community' &&
          system !== 'braze' && { username }),
        ...(system !== 'treasure_data' &&
          system !== 'community' &&
          system !== 'braze' && { password }),
        ...(system === 'slicktext' && { webhookSecret }),
        ...(system === 'attentive' && { webhookSecret }),
        ...(system === 'attentive' && { appName }),
        ...(system === 'attentive' && { apiKey }),
        ...(system === 'braze' && { apiKeyName }),
        ...(system === 'braze' && { apiKey }),
        ...(system === 'treasure_data' && { apiKey }),
        ...(system === 'community' && { apiToken }),
        ...(system === 'community' && { apiTokenLabel }),
        ...(system === 'community' && { apiTokenCreatedDate }),
      };

      await callable(data);
      setLoading(false);
      setReload(!reload);
    } catch (e) {
      logger.error(e);
      setError(e.message);
      setLoading(false);
    }
  };

  const deleteCredentials = async () => {
    // this should probably call encrypt() somewhere (httpscallable)

    try {
      if (user) {
        setLoading(true);
        const callable = httpsCallable(
          functions,
          'accountManager-deleteCredential'
        );
        await callable({ system, id: credential.key, accountId, name });
        setLoading(false);
        setReload(!reload);
      } else {
        logger.info('Delete failed - unable to obtain user');
      }
    } catch (e) {
      logger.error(e);
      setError(e.message);
      setLoading(false);
    }
  };

  const handleApiTokenCreatedDateChange = (e) => {
    const date = e.target.value;
    const ts = moment(date).unix();
    const dt = moment.unix(ts).format('YYYY-MM-DD');
    logger.debug(
      `[handleApiTokenCreatedDateChange] date: ${date} (${ts}) (${dt})`
    );
    setApiTokenCreatedDate(ts);
  };

  const fields = {
    name,
    ...(system !== 'treasure_data' &&
      system !== 'community' &&
      system !== 'braze' && { username }),
    ...(system !== 'treasure_data' &&
      system !== 'community' &&
      system !== 'braze' && { password }),
    ...(system === 'slicktext' && { webhookSecret }),
    ...(system === 'attentive' && { webhookSecret }),
    ...(system === 'attentive' && { appName }),
    ...(system === 'attentive' && { apiKey }),
    ...(system === 'braze' && { apiKeyName }),
    ...(system === 'braze' && { apiKey }),
    ...(system === 'treasure_data' && { apiKey }),
    ...(system === 'community' && { apiToken }),
    ...(system === 'community' && { apiTokenLabel }),
    ...(system === 'community' && { apiTokenCreatedDate }),
  };

  const dirty = () => {
    for (const field of Object.values(fields)) {
      if (field !== '') {
        return true;
      }
    }

    return false;
  };

  const isApiTokenCreatedDateInvalid = () => {
    const nowUnix = moment().unix();
    const dtNow = moment.unix(nowUnix).format('YYYY-MM-DD');
    const tsNow = moment(dtNow).unix();
    const isDateInFuture = apiTokenCreatedDate > tsNow;
    return isDateInFuture;
  };

  const valid = () => {
    for (const [name, field] of Object.entries(fields)) {
      if (system === 'attentive' && name === 'webhookSecret') {
        // we don't require this to be set for attentive
        continue;
      }

      if (name === 'apiTokenCreatedDate') {
        const inValid = isApiTokenCreatedDateInvalid();
        if (inValid) {
          return false;
        }
      } else if (field === '') {
        return false;
      }
    }

    return true;
  };

  const toggleAudit = () => setIsOpen(!isOpen);
  const isDisabled = account.active === false && system !== 'braze';

  return (
    <React.Fragment>
      <tr>
        <td>
          <FormGroup>
            <Input
              disabled
              name="name"
              invalid={dirty() && name === ''}
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="select"
            >
              {arrayToOptions(credentialTypes)}
            </Input>
            <FormFeedback>Please select a type</FormFeedback>
          </FormGroup>
        </td>
        {system === 'attentive' && (
          <td>
            <FormGroup>
              <Input
                disabled={isDisabled}
                name="appName"
                invalid={dirty() && appName === ''}
                placeholder="App Name"
                value={appName}
                onChange={(e) => setAppName(e.target.value)}
              />
              <FormFeedback>Please enter the app name</FormFeedback>
            </FormGroup>
          </td>
        )}
        {system === 'braze' && (
          <td>
            <FormGroup>
              <Input
                disabled={isDisabled}
                name="apiKeyName"
                invalid={dirty() && apiKeyName === ''}
                placeholder="Api Key Name"
                value={apiKeyName}
                onChange={(e) => setApiKeyName(e.target.value)}
              />
              <FormFeedback>Please enter the api key name</FormFeedback>
            </FormGroup>
          </td>
        )}
        {(system === 'attentive' ||
          system === 'treasure_data' ||
          system === 'braze') && (
          <td>
            <FormGroup>
              <Input
                disabled={isDisabled}
                name="apiKey"
                invalid={dirty() && apiKey === ''}
                placeholder="Api Key"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
              <FormFeedback>Please enter the api key</FormFeedback>
            </FormGroup>
          </td>
        )}
        {system === 'community' && (
          <td>
            <FormGroup>
              <Input
                disabled={isDisabled}
                name="apiTokenLabel"
                invalid={dirty() && apiTokenLabel === ''}
                placeholder="Api Access Token Label"
                value={apiTokenLabel}
                onChange={(e) => setApiTokenLabel(e.target.value)}
              />
              <FormFeedback>
                Please enter the api access token label
              </FormFeedback>
            </FormGroup>
          </td>
        )}
        {system === 'community' && (
          <td>
            <FormGroup>
              <Input
                disabled={isDisabled}
                name="apiToken"
                invalid={dirty() && apiToken === ''}
                placeholder="Api Access Token"
                value={apiToken}
                onChange={(e) => setApiToken(e.target.value)}
              />
              <FormFeedback>Please enter the api access token</FormFeedback>
            </FormGroup>
          </td>
        )}
        {system === 'community' && (
          <td>
            <FormGroup>
              <Input
                type="date"
                disabled={isDisabled}
                name="apiTokenCreatedDate"
                invalid={isApiTokenCreatedDateInvalid()}
                value={moment.unix(apiTokenCreatedDate).format('YYYY-MM-DD')}
                onChange={handleApiTokenCreatedDateChange}
              />
              <FormFeedback>
                Please enter the api access token created date
              </FormFeedback>
            </FormGroup>
          </td>
        )}
        {system === 'community' && (
          <td>
            {daysLeft > 7 ? (
              <Alert color="info">{daysLeft} days until token expires</Alert>
            ) : daysLeft > 0 ? (
              <Alert color="warning">{daysLeft} days until token expires</Alert>
            ) : (
              <Alert color="danger">Token expired</Alert>
            )}
          </td>
        )}
        {system !== 'treasure_data' &&
          system !== 'community' &&
          system !== 'braze' && (
            <td>
              <FormGroup>
                <Input
                  disabled={isDisabled}
                  name="username"
                  invalid={dirty() && username === ''}
                  placeholder="API User Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <FormFeedback>Please enter a username</FormFeedback>
              </FormGroup>
            </td>
          )}
        {system !== 'treasure_data' &&
          system !== 'community' &&
          system !== 'braze' && (
            <td>
              <FormGroup>
                <Input
                  disabled={isDisabled}
                  name="password"
                  invalid={dirty() && password === ''}
                  placeholder="API Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormFeedback>Please enter a password</FormFeedback>
              </FormGroup>
            </td>
          )}
        {system === 'slicktext' || system === 'attentive' ? (
          <td>
            <FormGroup>
              <Input
                disabled={isDisabled}
                name="webhookSecret"
                invalid={dirty() && webhookSecret === ''}
                placeholder="Webhook Key"
                value={webhookSecret}
                onChange={(e) => setWebhookSecret(e.target.value)}
              />
              <FormFeedback>Please enter the webhook secret</FormFeedback>
            </FormGroup>
          </td>
        ) : null}
        <td>
          <Button
            disabled={
              ability.cannot('create', 'credential') ||
              !valid() ||
              isDisabled ||
              loading
            }
            onClick={async (e) => await updateCredentials()}
            className={accountStyles.actionButton}
          >
            Update
          </Button>
          <Button
            onClick={(e) => toggleAudit()}
            className={accountStyles.actionButton}
          >
            {isOpen === false ? 'Show' : 'Hide'} History
          </Button>
          <Button
            disabled={
              ability.cannot('delete', 'credential') || isDisabled || loading
            }
            onClick={(e) => deleteCredentials()}
            className={accountStyles.actionButton}
          >
            Delete
          </Button>
          {loading && (
            <img
              src="/images/loading.gif"
              className={accountStyles.actionLoading}
            />
          )}
        </td>
      </tr>
      <tr>
        <td colSpan={4} className={accountStyles.credentialsAuditRow}>
          <Collapse isOpen={isOpen}>
            <ListGroup className={accountStyles.credentialsAuditGroup}>
              {credentialsAudit.map((c) => (
                <CredentialsAuditRow key={c.key} credentialAudit={c} />
              ))}
            </ListGroup>
          </Collapse>
        </td>
      </tr>
    </React.Fragment>
  );
};
