import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Alert,
  Input,
} from 'reactstrap';
import { Field } from '@availity/form';
import { SelectField } from '@availity/select';
import '@availity/yup';
import { Loader } from '../../../components/Loader';
import { Can } from '../../../auth/Can';
import { logger } from '../../../logging';
import { useFormikContext } from 'formik';
import { BrandInputField } from '../../../components/brand/BrandInputField';
import { BrandService } from '../../../components/brand/BrandLookup';
import { EnumerationsContext } from '../../enumerations/EnumerationsContext';
import { AbilityContext } from '../../../auth/Can';
import {
  checkDataAcquisitionValidForAccountId,
  checkIfBrazeCredsExistForAccountId,
} from './validation';

const accountStyles = require('../account.css');

export const BrazeFields = ({
  model,
  setCanSave,
  system,
  account_id,
  errors,
  setAccountHasCreds,
}) => {
  const formik = useFormikContext();
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeErrorMessage, setActiveErrorMessage] = useState('');
  const [hasCreds, setHasCreds] = useState(null);
  const { enumerations, loadingEnumerations } = React.useContext(
    EnumerationsContext as any
  ) as any;

  const ability = useContext(AbilityContext);
  const canAccessBraze = ability.can('read', 'braze');
  const canAdminBraze = ability.can('manage', 'braze');
  const canAdminBrazeOptins = ability.can('manage', 'braze_optins');

  const checkIfCanSave = () => {
    if (errorMessage === '') {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  };

  useEffect(() => {
    if (errors) {
      const invalidFields = Object.keys(errors);
      // logger.debug(`invalidFields: ${JSON.stringify(invalidFields)}`);
      if (invalidFields.length > 0) {
        setErrorMessage(
          `The following fields are set incorrectly: ${JSON.stringify(
            invalidFields
          )}`
        );
        checkIfCanSave();
      } else {
        setErrorMessage('');
        checkIfCanSave();
      }
    }
  });

  useEffect(() => {
    // logger.debug(`useEffect hasCredsCheck - hasCreds: ${hasCreds}`);

    (async () => {
      if (hasCreds === null) {
        const key = formik.values['key'];
        // logger.debug(`useEffect hasCredsCheck - key: ${key}`);
        const hasCredsResult = await checkIfBrazeCredsExistForAccountId(key);

        // logger.debug(
        //   `useEffect hasCredsCheck - hasCredsResult: ${hasCredsResult} (${typeof hasCredsResult})`
        // );
        if (hasCredsResult !== null) {
          setHasCreds(hasCredsResult);
          setAccountHasCreds(hasCredsResult);
        }
      }
    })();
  }, []);

  if (!enumerations.enumerations || loadingEnumerations) {
    return <Loader loading={true} />;
  }

  if (canAccessBraze === false) {
    return (
      <React.Fragment>
        <Row>
          <Col>Sorry you don't have permission to view Braze accounts.</Col>
        </Row>
      </React.Fragment>
    );
  }

  const handlePartyIdChange = (partyId, partyName, rollup_id) => {
    logger.debug(
      `[BrazeFields] handlePartyIdChange: partyId: ${partyId}, partyName: ${partyName}, rollup_id: ${rollup_id}`
    );
    formik.setFieldValue('party_id', partyId);
    formik.setFieldValue('party_name', partyName);
    formik.setFieldValue('rollup_id', rollup_id);
  };

  const handleNameChange = (e) => {
    logger.debug(
      `[BrazeFields] handleNameChanged: e.target.value: ${e.target.value}`
    );
    const nameClean = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    formik.setFieldValue('account_name', nameClean);
  };

  const handleAccountIDChange = (e) => {
    logger.debug(
      `[BrazeFields] handleAccountIDChange: e.target.value: ${e.target.value}`
    );
    const idClean = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    formik.setFieldValue('account_id', idClean);
  };

  // active/inactive toggle confirmation modal
  const toggle = (ok?) => {
    if (ok === true) {
      formik.setFieldValue('active', !formik.values['active']); // Maybe look at default here instead
    }
    setModal(!modal);
  };

  // this is called when trying to toggle active. It opens the confirmation modal and stores the desired active value
  const confirmSetActive = (value: boolean) => {
    toggle();
  };

  // this is called when the confirm modal button is clicked. It sets the active value and closes the modal.
  const setActiveConfirmed = () => {
    toggle(true);
  };

  const setActiveCancelled = () => {
    toggle();
  };

  const checkIfActiveDisabled = () => {
    if (!account_id) {
      return true;
    }

    if (canAdminBraze === false) {
      return true;
    }

    return false;
  };

  const showTempActiveErrorMessage = (message: string) => {
    setActiveErrorMessage(message);
    setTimeout(() => {
      setActiveErrorMessage('');
    }, 5000);
  };

  const handleIsActiveChanged = async (e) => {
    setActiveErrorMessage('');
    const check = e.target.checked;
    logger.debug(
      `[handleIsActiveChanged] called - e.target.checked: ${e.target.checked} (${check}), hasCreds: ${hasCreds} for account_id: ${account_id}, model.key: ${model.key}`
    );

    // 1. does it have all necessary fields
    if (!model.key) {
      logger.debug(
        `[handleIsActiveChanged] cannot change to active as account not saved yet`
      );
      showTempActiveErrorMessage(
        'Cannot change account to active as account not saved yet.'
      );
      return;
    }

    // 2. is there a credential
    const hasCredsResult = await checkIfBrazeCredsExistForAccountId(model.key);
    if (hasCredsResult !== null) {
      setHasCreds(hasCredsResult);
      setAccountHasCreds(hasCredsResult);
    }

    logger.debug(
      `[handleIsActiveChanged] e.target.checked: ${e.target.checked} (${check}), hasCredsResult: ${hasCredsResult}`
    );
    if (check === true && hasCredsResult === false) {
      logger.debug(
        `[handleIsActiveChanged] cannot change to active as has no creds`
      );

      showTempActiveErrorMessage(
        'Cannot change account to active as account has no active credentials'
      );
      return;
    }

    // 3. is data acq set
    const hasDataAcqSelection = await checkDataAcquisitionValidForAccountId(
      model.key
    );
    logger.debug(
      `[handleIsActiveChanged] e.target.checked: ${e.target.checked} (${check}), hasDataAcqSelection: ${hasDataAcqSelection}`
    );
    if (check === true && !hasDataAcqSelection) {
      showTempActiveErrorMessage(
        'Cannot change account to active as account has no Data Acquisition Selection saved.'
      );
      return;
    }

    // if ok to change then call:
    confirmSetActive(check);
  };

  const brazeInstanceIdValues =
    (Object.values(enumerations.enumerations.brazeInstanceIDs) as any) || [];
  const participationValues =
    (Object.values(enumerations.enumerations.participationOptions) as any) ||
    [];
  const b2bOrB2c =
    (Object.values(enumerations.enumerations.b2bOrB2c) as any) || [];
  const umgOrPartner =
    (Object.values(enumerations.enumerations.umgOrPartner) as any) || [];
  const languages =
    (Object.values(enumerations.enumerations.languages) as any) || [];
  const timezones =
    (Object.values(enumerations.enumerations.timezones) as any) || [];
  const ipMappings =
    (Object.values(enumerations.enumerations.ipMappings) as any) || [];

  const isNewForm = !account_id;
  const disableAllFields = canAdminBraze === false ? true : false;
  const disableAccountId = disableAllFields || hasCreds === true;
  const isActiveDisabled = checkIfActiveDisabled();
  const activeIsSaved = model.active === formik.values['active'];

  return (
    <React.Fragment>
      {errorMessage !== '' ? (
        <Row>
          <Col sm={12}>
            <Alert color="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      ) : null}
      <Row className={accountStyles.formRowActive}>
        <Col sm="6">
          {activeErrorMessage && (
            <Alert color="info">{activeErrorMessage}</Alert>
          )}
          <Can I="delete" an="account">
            <FormGroup switch>
              <Input
                type="switch"
                id="active"
                name="active"
                onChange={handleIsActiveChanged}
                disabled={isActiveDisabled}
                checked={isNewForm ? false : formik.values['active']}
              />
              <Label for="active">
                Active {activeIsSaved ? '' : '(save account to take effect)'}
              </Label>
            </FormGroup>
          </Can>
        </Col>
      </Row>
      <Row className={accountStyles.formRow}>
        <Col sm="6">
          <Field
            name="account_name"
            label="Account Name"
            onChange={handleNameChange}
            required
            disabled={disableAllFields}
          />
          <Field
            name="account_id"
            label="Braze Account Id"
            onChange={handleAccountIDChange}
            required
            disabled={disableAccountId}
          />
          <BrandInputField
            name="party_id"
            label="Party ID"
            brandFieldName="party_name"
            brandFieldNameLabel="Party Name"
            required={true}
            disabled={disableAllFields}
            handleOnChange={handlePartyIdChange}
            brandService={BrandService.PARTY_SERVICE}
          />
          <Field name="rollup_id" label="Rollup Id" disabled={true} />
          <SelectField
            name="braze_instance_id"
            label="Braze Instance ID"
            options={brazeInstanceIdValues}
            isMulti={false}
            required
            isDisabled={disableAllFields}
          />
          <Field
            name="repertoire_owner"
            label="Repertoire Owner/Account Owner"
            required
            disabled={disableAllFields}
          />
        </Col>
        <Col sm="6">
          <SelectField
            name="participation_status"
            label="Participation Status"
            required
            options={participationValues}
            isMulti={false}
            isDisabled={disableAllFields}
          />
          <SelectField
            name="is_b2c_or_b2b"
            label="B2C/B2B"
            required
            options={b2bOrB2c}
            isMulti={false}
            isDisabled={disableAllFields}
          />
          <SelectField
            name="is_umg_or_partner"
            label="UMG/Partner"
            required
            options={umgOrPartner}
            isMulti={false}
            isDisabled={disableAllFields}
          />
          <SelectField
            name="language"
            label="Language"
            required
            options={languages}
            isMulti={false}
            isDisabled={disableAllFields}
          />
          <SelectField
            name="timezone"
            label="Timezone"
            required
            options={timezones}
            isMulti={false}
            isDisabled={disableAllFields}
          />
          <SelectField
            name="ipMapping"
            label="IP Mapping"
            required
            options={ipMappings}
            isMulti={false}
            isDisabled={disableAllFields}
          />
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={(e) => toggle(false)}>
        <ModalHeader toggle={(e) => toggle(false)}>Please Confirm</ModalHeader>
        <ModalBody>
          Please confirm that you would like to change the 'active' status of
          this account. Once saved this change will also update all of the Vault
          credentials' active flag for this account.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => setActiveConfirmed()}>
            Confirm
          </Button>{' '}
          <Button color="secondary" onClick={(e) => setActiveCancelled()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
