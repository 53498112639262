import * as React from 'react';
import { useContext, useState } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import * as firebaseService from '../../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Alert, Col, Row, TabPane } from 'reactstrap';
import { Loader } from '../../../components/Loader';
import { ref } from '../../../utils/firebase';
import { BRAZE_OPTINS_TAB } from '../AccountForm';
import { BrazeOptins } from './BrazeOptins';
import { getAuth } from 'firebase/auth';
import { EnumerationsContext } from '../../enumerations/EnumerationsContext';
import { BrazeEmailOptin } from './BrazeEmailOptin';
import { BrazeSubscriptionGroupOptins } from './BrazeSubscriptionGroupOptins';
import { update } from 'ramda';

const brazeStyles = require('./braze.css');

export const BrazeOptinsTab = ({ account, setAccountHasOptins }) => {
  const { enumerations, loadingEnumerations } = useContext(
    EnumerationsContext
  ) as any;
  const damApp = firebaseService.getDAMApp();
  const damAuth = getAuth(damApp);
  const [user, userLoading] = useAuthState(damAuth as any);
  const [accountValues, accountLoading, accountError] = useObjectVal(
    ref(`accounts/braze/${account.key}`)
  );
  const [saving, setSaving] = useState(false);
  const [activeOptins, setActiveOptins] = useState([]);

  if (accountError) {
    return (
      <TabPane tabId={BRAZE_OPTINS_TAB}>
        <React.Fragment>Error</React.Fragment>
      </TabPane>
    );
  }

  if (
    accountLoading ||
    userLoading ||
    !enumerations.enumerations ||
    loadingEnumerations
  ) {
    return <Loader loading={true} />;
  }

  if (!accountValues) {
    return (
      <TabPane tabId={BRAZE_OPTINS_TAB}>
        <div>No data available for account</div>
      </TabPane>
    );
  }

  const handleOptinActiveChange = (target_id, is_active) => {
    setActiveOptins((prevOptins) => {
      let updatedOptins;

      if (is_active) {
        updatedOptins = prevOptins.includes(target_id)
          ? prevOptins
          : [...prevOptins, target_id];
      } else {
        updatedOptins = prevOptins.filter((id) => id !== target_id);
      }

      setAccountHasOptins(updatedOptins && updatedOptins.length > 0);

      return updatedOptins;
    });
  };

  const isAccountActive = account && account.active ? true : false;

  return (
    <TabPane tabId={BRAZE_OPTINS_TAB}>
      {!isAccountActive && (
        <Row className={brazeStyles.optinAlert}>
          <Col>
            <Alert color="primary">
              Account must be active to activate optins.
            </Alert>
          </Col>
        </Row>
      )}
      <BrazeEmailOptin
        account={account}
        enumerations={enumerations}
        setTabSaving={setSaving}
        handleOptinActiveChange={handleOptinActiveChange}
      />

      <BrazeSubscriptionGroupOptins
        account={account}
        enumerations={enumerations}
        setTabSaving={setSaving}
        handleOptinActiveChange={handleOptinActiveChange}
      />

      <Row className={brazeStyles.optinTableRowHeader}>
        <Col sm={6}>
          <h4>Braze Optins Table For Account</h4>
        </Col>
      </Row>
      <Row className={brazeStyles.optinTableRow}>
        <Col>
          {!saving ? (
            <BrazeOptins account_id={account.account_id} />
          ) : (
            <Loader loading={true} />
          )}
        </Col>
      </Row>
    </TabPane>
  );
};
