import * as React from 'react';
import { useContext } from 'react';
import { EnumerationsContext } from '../../enumerations/EnumerationsContext';
import { Optins } from '../../optins/Optins';
import { Loader } from '../../../components/Loader';

export const BrazeOptins = ({ account_id }) => {
  const { enumerations, loadingEnumerations } = useContext(EnumerationsContext);
  if (!enumerations.enumerations || loadingEnumerations) {
    <Loader loading={true} />;
  }
  return <Optins enumerations={enumerations} braze_account_id={account_id} />;
};
