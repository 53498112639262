.formRow {
  margin-top: 20px;
}

.fullHeight {
  height: 100%;
}

.fullHeightTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fitContentTableContainer {
  width: fit-content;
  flex: 1;
  overflow-y: auto;
}

.systemSelector {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  margin-bottom: 20px;
}

.button {
  margin-right: 10px;
}

.columnsButton {
  margin: 15px 0;
}

.accountsTable select,
.accountsTable input {
  width: 100px;
  margin-top: 5px;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.accountsTableContainer {
  width: fit-content;
}

.accountsTable {
  border-collapse: separate;
  border-spacing: 0;
}

.accountsTable th {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  z-index: 1;
}

.accountsTable td {
  border-right: 1px solid #dee2e6;
}

.accountsTable th:first-child,
.accountsTable td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid #dee2e6;
}

.accountsTable tr:last-child td {
  /* Apply a left border on the first <td> or <th> in a row */
  border-bottom: 1px solid #dee2e6;
}

.countLoader {
  height: 40px;
  width: 40px;
  padding: 0px;
}

.pagination button {
  margin: 5px;
}

.addressbookSyncErrorCell {
  color: red;
}
