import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { logger } from '../../logging';
import { Field } from '@availity/form';
import { BrandService, useBrand } from './BrandLookup';
import { Col, Row } from 'reactstrap';
import { CrossIcon, TickIcon } from '../Icons';

const brandStyles = require('./brand.css');
const iconStyles = require('../../components/icons.css');

export const dataTypes = {
  [BrandService.CANOPUS]: 'STRING',
  [BrandService.PARTY_SERVICE]: 'NUMBER',
};

interface BrandInputFieldProps {
  name: string;
  label: string;
  brandFieldName: string;
  brandFieldNameLabel: string;
  required: boolean;
  disabled: boolean;
  handleOnChange: (
    brandId: string,
    brandName: string,
    rollup_id?: string
  ) => void;
  brandService: BrandService;
}

// To be used in Formik forms such as the account forms
export const BrandInputField: FC<BrandInputFieldProps> = ({
  name,
  label,
  brandFieldName,
  brandFieldNameLabel,
  required,
  disabled,
  handleOnChange,
  brandService,
}) => {
  const [brandId, setBrandId] = useState('');
  const [brand, brandLoading, brandError] = useBrand(brandId, brandService);
  const [brandChanged, setBrandChanged] = useState(false);

  useEffect(() => {
    //this handles initial load
    if (brandChanged === true) {
      logger.debug(
        `[BrandInputField] [useEffect] brandId: ${brandId}, brand.name: ${brand.name}, brandChanged: ${brandChanged}, brand.rollup_id: ${brand.rollup_id}`
      );
      handleOnChange(brandId, brand.name, brand.rollup_id);
    }
  }, [brandId, brand.name, brandChanged, brand.rollup_id]);

  const inputChange = async (e) => {
    const re =
      dataTypes[brandService] === 'STRING'
        ? '/^[a-zA-Z0-9 ]*$/g'
        : '/^[0-9]*$/g';
    const brandIdClean = e.target.value.replace(re, '').trim();
    logger.debug(`[BrandInputField] inputChange brandId: ${brandIdClean}`);
    setBrandId(brandIdClean);
    setBrandChanged(true);
  };

  return (
    <Row>
      <Col sm={6}>
        <Field
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          onChange={inputChange}
        />
      </Col>
      <Col sm={brandId ? 5 : 6}>
        <Field
          name={brandFieldName}
          label={brandFieldNameLabel}
          required={required}
          disabled={true}
          className={brandStyles.brandName}
        />
      </Col>
      {brandId && (
        <Col sm={1} className={brandStyles.brandValidCol}>
          {brandLoading && (
            <img
              src="/images/loading.gif"
              className={brandStyles.brandLoader}
            />
          )}
          {!brandLoading && brand.name && (
            <TickIcon fill="black" class={iconStyles.tick} />
          )}
          {!brandLoading && !brand.name && (
            <CrossIcon fill="black" class={iconStyles.cross} />
          )}
        </Col>
      )}
    </Row>
  );
};
