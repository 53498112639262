import * as firebaseService from '../../../services/firebase';
import { logger } from '../../../logging';
import { Channel } from '../../../../functions/src/optins/domain/optin';
import moment from 'moment';
import { index } from '../../../../functions/src/utils/indexes';
import {
  getDatabase,
  ref as fbRef,
  query,
  orderByChild,
  equalTo,
  get,
  update,
  push,
} from 'firebase/database';

const DEFAULT_CATEGORY = 'main_artist';

interface UpdateBrazeOptin {
  channel: Channel;
  active: boolean;
  tag;
  category;
  account: any;
  target_id: string;
  subscription_group_id?: string;
  subscription_group_name?: string;
}

export const updateBrazeOptin = async ({
  channel,
  active,
  tag,
  category,
  account,
  target_id,
  subscription_group_id,
  subscription_group_name,
}: UpdateBrazeOptin) => {
  const ifbApp = firebaseService.getIFBApp();
  const ifbDatabase = getDatabase(ifbApp);
  try {
    const now = moment().unix();
    // 1 get existing optin for account + channel
    let optin = null;
    const optinsRef = fbRef(ifbDatabase, 'optins');
    const optinRef = fbRef(ifbDatabase, 'optins');
    const optinQuery = query(
      optinRef,
      orderByChild('target_id'),
      equalTo(target_id)
    );

    const optinsSnap = await get(optinQuery);
    optinsSnap.forEach((snap) => {
      const existingOptin = snap.val();
      if (existingOptin.channel === channel) {
        optin = snap.val();
        logger.debug(
          `[updateBrazeOptin] Existing optin: ${JSON.stringify(optin)}`
        );
      }
    });

    // for new optins: if no optin build one & set to active = false (as will need brand assigning)
    if (!optin) {
      optin = {};
      optin['accountName'] = account.account_name;
      optin['target_id'] = target_id;
      optin['channel'] = channel;
      optin['created'] = now;

      logger.debug(`[updateBrazeOptin] New optin: ${JSON.stringify(optin)}`);
    }

    // always set latest party_id, active and vendor
    optin['brandId'] = account.party_id || '';
    optin['name'] = account.party_name || '';
    optin['active'] = active;
    optin['tag'] = tag;
    optin['category'] = category;
    optin['vendor'] = 'braze';
    optin['subscription_group_id'] = subscription_group_id;
    optin['subscription_group_name'] = subscription_group_name;

    // 2 update to correct active status
    // check party_id & channel enabled
    if (!account.party_name || account.active === false) {
      optin['active'] = false;
    }

    // 3 save
    optin['modified'] = now;

    if (!optin['key']) {
      const newRef = await push(optinsRef);
      if (!newRef.key) {
        throw new Error('[updateBrazeOptin] - newRef.key not present');
      }

      optin['key'] = newRef.key;
    }

    logger.debug(
      `[updateBrazeOptin] about to reindex optin: ${JSON.stringify(optin)}`
    );
    const indexed = index(optin); // always rebuild indexes on save
    const newOptinRef = fbRef(ifbDatabase, `optins/${indexed.key}`);
    logger.debug(
      `[updateBrazeOptin] about to update indexed optin: ${JSON.stringify(
        indexed
      )}`
    );

    await update(newOptinRef, indexed);
  } catch (e) {
    logger.error(`[updateBrazeOptin] error updating optin: ${e}`);
  }
};

export const checkIfOptinActive = async (
  target_id: string
): Promise<boolean> => {
  const ifbApp = firebaseService.getIFBApp();
  const ifbDatabase = getDatabase(ifbApp);
  const optinRef = fbRef(ifbDatabase, 'optins');
  const optinQuery = query(
    optinRef,
    orderByChild('target_id'),
    equalTo(target_id)
  );

  const optinsSnap = await get(optinQuery);
  let isActive = false;

  optinsSnap.forEach((snap) => {
    const existingOptin = snap.val();

    if (existingOptin.active === true) {
      isActive = true;
    }
  });

  return isActive;
};
