import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { logger } from '../../logging';
import { Field } from '@availity/form';
import { BrandService, useBrand } from './BrandLookup';
import { Col, Input, Label, Row } from 'reactstrap';
import { CrossIcon, TickIcon } from '../Icons';

const brandStyles = require('./brand.css');
const iconStyles = require('../../components/icons.css');

export const dataTypes = {
  [BrandService.CANOPUS]: 'STRING',
  [BrandService.PARTY_SERVICE]: 'NUMBER',
};

interface BrandInputProps {
  name: string;
  value: string | number;
  label: string;
  brandFieldName: string;
  brandFieldNameLabel: string;
  disabled: boolean;
  handleOnChange: (
    brandId: number | string,
    brandName: string,
    canopusId?: number | string
  ) => void;
  brandService: BrandService;
  hideCanopus?: boolean;
}

// To be used in regular forms such as the addressbook creation modal
export const BrandInput: FC<BrandInputProps> = ({
  name,
  value,
  label,
  brandFieldName,
  brandFieldNameLabel,
  disabled,
  handleOnChange,
  brandService,
  hideCanopus,
}) => {
  const [brandId, setBrandId] = useState(value);
  const [brand, brandLoading, brandError] = useBrand(brandId, brandService);

  useEffect(() => {
    // this handles initial load
    logger.debug(
      `[BrandInput] [useEffect] [call handleOnChange] brandId: ${brandId}, brandName: ${brand.name}, canopus_id: ${brand.canopus_id}`
    );
    handleOnChange(brandId, brand.name, brand.canopus_id);
  }, [brandId, brand.name]);

  const inputChange = async (e) => {
    const re =
      dataTypes[brandService] === 'STRING'
        ? '/^[a-zA-Z0-9 ]*$/g'
        : '/^[0-9]*$/g';
    const brandIdClean = e.target.value.replace(re, '').trim();
    logger.debug(`[BrandInput] inputChange brandId: ${brandIdClean}`);
    setBrandId(brandIdClean);
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={6}>
          <Label>{label}</Label>
          <Input
            type="text"
            value={brandId}
            name={`${name || ''}`}
            label={label}
            disabled={disabled}
            onChange={inputChange}
          />
        </Col>
        <Col sm={brandId ? 5 : 6}>
          <Label>{brandFieldNameLabel}</Label>
          <Input
            type="text"
            value={`${brand.name || ''}`}
            name={brandFieldName}
            label={brandFieldNameLabel}
            disabled={true}
            className={brandStyles.brandName}
          />
        </Col>
        {brandId && (
          <Col sm={1} className={brandStyles.brandValidCol}>
            {brandLoading && (
              <img
                src="/images/loading.gif"
                className={brandStyles.brandLoader}
              />
            )}
            {!brandLoading && brand.name && (
              <TickIcon fill="black" class={iconStyles.tick} />
            )}
            {!brandLoading && !brand.name && (
              <CrossIcon fill="black" class={iconStyles.cross} />
            )}
          </Col>
        )}
      </Row>
      {brandService === BrandService.PARTY_SERVICE && hideCanopus !== true ? (
        <Row className={brandStyles.canopusRow}>
          <Col sm={6}>
            <Label>Canopus ID</Label>
            <Input
              type="text"
              value={`${brand.canopus_id || ''}`}
              disabled={true}
            />
          </Col>
          <Col sm={6}></Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};
