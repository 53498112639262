.formRow {
  margin-top: 20px;
}

.button {
  margin-right: 10px;
}

.columnsButton {
  margin: 15px 0;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.mappingModalBody,
.mappingModalFooter,
.mappingModalHeader {
  padding: 50px;
}

.mappingInput {
  cursor: pointer;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mappingModalBodyRow {
  padding: 20px;
}

.mappingModalFooterRow {
  padding: 20px;
  text-align: left;
}

.mappingModal {
  max-width: 80%;
}

.validationError {
  min-width: 170px;
}

.optinEnabledRowHeader {
  margin-top: 25px;
}

.optinEnabledRow {
  margin-top: 15px;
}

.smsOptinEnabledRow {
  padding-top: 10px;
  background-color: #9b9b9b1a;
}

.smsCountryAllowListRow {
  margin-top: 0px;
  padding-bottom: 15px;
  background-color: #9b9b9b1a;
}

.optinTableRowHeader {
  margin-top: 25px;
}

.optinSaveButton {
  margin-top: 30px;
}

.optinTableRow {
  margin-top: 15x;
}

.channel_switch {
  margin-top: 25px;
}

.country_pill {
  margin: 2px;
  font-size: 0.9rem;
}

.country_pill button {
  margin-left: 3px;
  font-size: x-small;
}

.countrySaveButton {
  margin-top: 30px;
  color: white;
}

.allCountriesAddButton {
  color: white;
}

.allCountriesClearButton {
  margin-top: 15px;
  color: white;
}

.countrySaveButton:hover,
.allCountriesClearButton:hover,
.allCountriesAddButton:hover {
  color: white;
}

.stepperRow {
  margin-left: -60px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.optinAlert {
  margin-top: 20px;
}
