import * as React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Table,
  Label,
  ModalFooter,
  Alert,
} from 'reactstrap';
import * as firebaseService from '../../services/firebase';
import { logger } from '../../logging';
import moment from 'moment';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Loader } from '../../components/Loader';
import { ref } from '../../utils/firebase';
import { AddressbookMetadata } from '../../../functions/src/services/infra/dotdigital';
import { Addressbook } from '../account/dotdigital/AddressbooksTab';
import { checkOptinExists, sendCanopusToLytics } from './Addressbooks';
import { defaultCategoryValues } from '../optins/EmailCategoryRow';
import { BrandInput } from '../../components/brand/BrandInput';
import { BrandService } from '../../components/brand/BrandLookup';
import { Link } from 'react-router-dom';
import { AbilityContext } from '../../auth/Can';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  equalTo,
  orderByChild,
  query,
  get,
  ref as fbRef,
  getDatabase,
  set,
} from 'firebase/database';
import { waitForDebugger } from 'inspector';
import { sleep } from '../../../functions/src/utils/sleep';
const R = require('ramda');

const addressbookStyles = require('./addressbook.css');

const DEFAULT_CATEGORY = 'main_artist';

export const AddressbookCreateModal = ({
  account,
  setIsCreateAddressbookOpen,
  isCreateAddressbookOpen,
  setIsCreateAddressbookConfirmationOpen,
  setNewAddressbook,
}) => {
  // logger.debug(
  //   `[AddressbookCreateModal] loaded with isCreateAddressbookOpen: ${isCreateAddressbookOpen}`
  // );
  const ifbApp = firebaseService.getIFBApp();
  const ifbDatabase = getDatabase(ifbApp);
  const damApp = firebaseService.getDAMApp();
  const functions = getFunctions(damApp);
  const ability = useContext(AbilityContext);
  const canLPR = ability.can('manage', 'lpr');
  const canAdminCountry = ability.can('manage', 'addressbook_country');

  const defaultAddressbook = {
    name: '',
    accountId: parseInt(account.ddAccountId, 10),
    accountName: account.ddAccountName,
    country: account.territoryOperatingCountry.toUpperCase(),
    dataCapture: false,
    excludeFromReporting: false,
    nonConsented: false,
    doNotShare: false,
    allowMerchRecommendations: false,
    communityLeaderId: null,
    communityLeaderName: null,
    communityLeaderMappingValid: false,
    category: DEFAULT_CATEGORY,
    tag: '',
    canopusId: null,
    canopusValid: false,
    canopusName: null,
    party_id:
      (account.party_id &&
        /^\d+$/.test(account.party_id) &&
        parseInt(account.party_id, 10)) ||
      null,
    party_name: null,
    partyIdValid: false,
    custom_canopus: null,
  } as Addressbook;

  const [addressbook, setAddressbook] = useState(defaultAddressbook);
  const addressbookRef = useRef(addressbook);
  const [saveIsDisabled, setSaveIsDisabled] = useState(true);
  const [enumerations, loading, error] = useObjectVal(
    ref('enumerations') as any
  ) as any;
  const [category, setCategory] = useState(defaultAddressbook.category);
  const [categoryValues, categoryLoading, categoryErrors] = useObjectVal(
    ref(`optin_categories/email/${category}`)
  );
  const [countries, countriesLoading] = useObjectVal(
    ref('countries') as any
  ) as any;
  const [country, setCountry] = useState(account.territoryOperatingCountry);
  const [errorMessage, setErrorMessage] = useState('');
  const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const [availableLeadersSorted, setAvailableLeadersSorted] = useState([]);
  const [availableLeaders, setAvailableLeaders] = useState({});
  const [partyIdRequired, setPartyIdRequired] = useState(false);
  const [dataCaptureEditable, setDataCaptureEditable] = useState(false);
  const [excludeFromReportingEditable, setExcludeFromReportingEditable] =
    useState(false);
  const [nonConsentedEditable, setNonConsentedEditable] = useState(false);
  const [doNotShareEditable, setDoNotShareEditable] = useState(false);
  const [isPartyIdLookingUpLeaders, setIsPartyIdLookingUpLeaders] =
    useState(false);
  const [partyIdValid, setPartyIdValid] = useState(false);
  const [categoryDuplicates, setCategoryDuplicates] = useState([]);

  //for debug // not breaking
  useEffect(() => {
    logger.debug(`[useEffect] [addressbook]: ${JSON.stringify(addressbook)}`);
  }, [addressbook]);

  useEffect(() => {
    // logger.debug(
    //   `[useEffect][isCreateAddressbookOpen] called for isCreateAddressbookOpen: ${isCreateAddressbookOpen}`
    // );
    if (isCreateAddressbookOpen === false) {
      return;
    }

    // logger.debug(
    //   `[useEffect][isCreateAddressbookOpen] about to check party_id: ${account.party_id}`
    // );
    if (account.party_id) {
      (async () => {
        try {
          await partyIdCommunityLeaderLookup();
        } catch (e) {
          logger.error(
            `[useEffect][isCreateAddressbookOpen] error checking party_id: ${e}`
          );
        }
      })();
    }

    // logger.debug(
    //   `[useEffect][isCreateAddressbookOpen] about to check account.party_id: ${account.party_id}`
    // );
    if (account.party_id) {
      try {
        const partyIdValid =
          addressbook.party_name && addressbook.party_name !== '';
        setPartyIdValid(partyIdValid);
      } catch (e) {
        logger.error(
          `[useEffect][isCreateAddressbookOpen] error checking party_id: ${e}`
        );
      }
    }
  }, [isCreateAddressbookOpen]);

  useEffect(() => {
    // logger.debug(
    //   `[useEffect][categoryValues] changed to: ${JSON.stringify(
    //     categoryValues
    //   )}`
    // );
    if (categoryLoading || categoryErrors) {
      // logger.debug(
      //   '[useEffect][categoryValues] categoryLoading || categoryErrors'
      // );
      return;
    }

    // From category options
    const defaultValues = R.mapObjIndexed((value, key, obj) => {
      // logger.debug(`val: ${value}, key: ${key}`);
      return R.mapObjIndexed((v, k, o) => {
        return v.default;
      }, value);
    }, defaultCategoryValues);
    const defaultedCategories = R.mergeRight(defaultValues, categoryValues);

    // logger.debug(
    //   `[useEffect][categoryValues] setting defaultedCategories: ${JSON.stringify(
    //     defaultedCategories
    //   )}`
    // );

    setPartyIdRequired(defaultedCategories['partyId']['required']);
    setDataCaptureEditable(defaultedCategories['dataCapture']['editable']);
    setExcludeFromReportingEditable(
      defaultedCategories['excludeFromReporting']['editable']
    );
    setNonConsentedEditable(defaultedCategories['nonConsented']['editable']);
    setDoNotShareEditable(defaultedCategories['doNotShare']['editable']);

    const addressbookToUpdate = {
      ...addressbook,
      dataCapture: defaultedCategories['dataCapture']['default_value'],
      excludeFromReporting:
        defaultedCategories['excludeFromReporting']['default_value'],
      nonConsented: defaultedCategories['nonConsented']['default_value'],
      doNotShare: defaultedCategories['doNotShare']['default_value'],
    };

    // logger.debug(
    //   `[useEffect][categoryValues] setAddressbook: ${JSON.stringify(
    //     addressbookToUpdate
    //   )}`
    // );
    setAddressbook(addressbookToUpdate);

    checkFormValid(
      addressbook.name,
      partyIdValid,
      category,
      addressbook.tag,
      defaultedCategories['partyId']['required']
    );
  }, [categoryValues]);

  // this was breaking after the firebase update.
  useEffect(() => {
    // logger.debug(
    //   `[useEffect addressbook.partyIdValid] addressbook.partyIdValid: ${addressbook.partyIdValid}`
    // );
    try {
      partyIdCommunityLeaderLookup();
    } catch (e) {
      logger.error(
        `[useEffect addressbook.partyIdValid] error checking party_id: ${e}`
      );
    }
  }, [addressbook.partyIdValid, addressbook.party_id]);

  const resetAll = () => {
    logger.debug(
      `[resetAll] setAddressbook: ${JSON.stringify(defaultAddressbook)}`
    );

    setCategory(defaultAddressbook.category);

    const defaultValues = R.mapObjIndexed((value, key, obj) => {
      // logger.debug(`val: ${value}, key: ${key}`);
      return R.mapObjIndexed((v, k, o) => {
        return v.default;
      }, value);
    }, defaultCategoryValues);

    const defaultedCategories = R.mergeRight(defaultValues, categoryValues);

    // get partyId from account if possible
    const partyID =
      (account.party_id &&
        /^\d+$/.test(account.party_id) &&
        account.party_id) ||
      null;

    const partyName =
      (account.party_name &&
        /^\d+$/.test(account.party_name) &&
        account.party_name) ||
      null;

    const partyIdValid = partyID && partyName ? true : false;

    const newAddressbook = {
      ...defaultAddressbook,
      dataCapture: defaultedCategories['dataCapture']['default_value'],
      excludeFromReporting:
        defaultedCategories['excludeFromReporting']['default_value'],
      nonConsented: defaultedCategories['nonConsented']['default_value'],
      doNotShare: defaultedCategories['doNotShare']['default_value'],
      canopusId: null,
      canopusValid: false,
      canopusName: null,
      custom_canopus: null,
      category: defaultAddressbook.category,
      party_id: partyID,
      party_name: partyName,
      partyIdValid: partyIdValid,
      country: account.territoryOperatingCountry.toUpperCase(),
    };

    // logger.debug(`[AddressbookCreateModal] `);

    setAddressbook(newAddressbook);
    setSaveIsDisabled(true);
    setIsPartyIdLookingUpLeaders(false);
    setErrorMessage('');
    setCategoryErrorMessage('');
    setCategoryDuplicates([]);
    setSaving(false);
    setAvailableLeadersSorted([]);
    setAvailableLeaders({});
    setPartyIdRequired(defaultedCategories['partyId']['required']);
    setDataCaptureEditable(defaultedCategories['dataCapture']['editable']);
    setExcludeFromReportingEditable(
      defaultedCategories['excludeFromReporting']['editable']
    );
    setNonConsentedEditable(defaultedCategories['nonConsented']['editable']);
    setDoNotShareEditable(defaultedCategories['doNotShare']['editable']);
    setPartyIdValid(partyIdValid);
    setCountry(account.territoryOperatingCountry);
  };

  const handleClose = () => {
    logger.debug(`[AddressbookCreateModal] handleClose called`);
    resetAll();
    setIsCreateAddressbookOpen(false);
  };

  const handleSavedClosed = () => {
    // logger.debug(`[handleSavedClosed] called. calling handleClose`);
    handleClose();
  };

  const saveAddressbook = async () => {
    // logger.debug(
    //   `[saveAddressbook] called, with addressbook: ${JSON.stringify(
    //     addressbook
    //   )}`
    // );
    // clear old error messages
    setErrorMessage('');
    setSaveIsDisabled(true);
    setSaving(true);

    // check optin exists already:
    const optinIndex = 'country_channel_category_brandId_tag';
    const optinIndexValue =
      `${addressbook.country}_email_${addressbook.category}_${addressbook.party_id}_${addressbook.tag}`.toLowerCase();
    const optinExists = await checkOptinExists(optinIndex, optinIndexValue);
    if (optinExists === true) {
      setErrorMessage(
        'Duplicate optin - An optin already exists for this country/channel/category/brand/tag combination.'
      );
      setSaveIsDisabled(false);
      setSaving(false);
      return null;
    }

    // The name can't be that of an existing address book, or of the reserved books such as 'Test' or 'All contacts'.
    // Address book names have a limit of 128 characters.
    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    let addressbookMetadata = {} as AddressbookMetadata;
    const newAddressbook = {
      ...addressbook,
      accountId: parseInt(account.ddAccountId, 10),
      created: now,
      modified: now,
    };

    // create DD addressbook via DD api
    try {
      const callable = httpsCallable(
        functions,
        'accountManager-callableCreateAddressbook'
      );
      const res: any = await callable({
        accountId: account.ddAccountId,
        addressbook: {
          name: addressbook.name,
        },
      });

      logger.debug(`res: ${JSON.stringify(res)}`);

      addressbookMetadata = { ...res.data } as AddressbookMetadata;

      // logger.debug(
      //   `[saveAddressbook] created DD addressbook, res = addressbookMetadata: ${JSON.stringify(
      //     addressbookMetadata
      //   )}`
      // );
    } catch (e) {
      logger.error(`[saveAddressbook] error posting to DD: ${e}`);
      setErrorMessage(e.message);
      setSaveIsDisabled(false);
      setSaving(false);
      return null;
    }

    if (typeof addressbookMetadata.id === 'undefined') {
      logger.error(
        `[saveAddressbook] error posting to DD: ${JSON.stringify(
          addressbookMetadata
        )}`
      );
      setErrorMessage(
        'There was a problem creating the addressbook in DD - no id was returned'
      );
      setSaveIsDisabled(false);
      setSaving(false);
      return null;
    }

    // build addressbook object (including account details)
    const key = `${account.ddAccountId}_${addressbookMetadata.id}`;
    const isValid =
      addressbook.canopusId && addressbook.canopusId !== '' ? 'true' : 'false';
    const isPartyIdValid = addressbook.partyIdValid === true ? 'true' : 'false';
    if (!isPartyIdValid) {
      delete newAddressbook['party_id'];
      delete newAddressbook['party_name'];
    }

    // we use this to check the process of canopus being added to party service record after initial ingestion
    const hasPartyIdAndNoCanopusId =
      isValid === 'false' && isPartyIdValid === 'true';

    const cleanName = R.trim(addressbook.name).toLowerCase();
    newAddressbook['id'] = addressbookMetadata.id;
    newAddressbook['key'] = key;
    newAddressbook[
      'hascanopus_processed_accountid_addressbookid'
    ] = `${isValid}_false_${key}`;
    newAddressbook[
      'hascanopus_processed_addressbookid'
    ] = `${isValid}_false_${key}`;
    newAddressbook[
      'haspartyid_processed_accountid_addressbookid'
    ] = `${isPartyIdValid}_false_${key}`;
    newAddressbook[
      'haspartyid_processed_addressbookid'
    ] = `${isPartyIdValid}_false_${key}`;
    newAddressbook[
      'haspartyid_senttobq_accountid_addressbookid'
    ] = `${isPartyIdValid}_false_${key}`;
    newAddressbook[
      'account_addressbookname'
    ] = `${account.ddAccountId}_${cleanName}`;
    newAddressbook['has_party_id_and_no_canopus_id'] = hasPartyIdAndNoCanopusId;

    // create addressbook in firebase
    try {
      // logger.debug(
      //   `[saveAddressbook] writing to IFB - newAddressbook: ${JSON.stringify(
      //     newAddressbook
      //   )}`
      // );
      const addressbookRef = fbRef(ifbDatabase, `addressbooks/${key}`);
      const res = await set(addressbookRef, newAddressbook);
      // logger.debug(`[saveAddressbook] written to IFB ok. res ${res}`);
    } catch (e) {
      logger.error(`[saveAddressbook] error saving to IFB: ${e}`);
      setErrorMessage('There was a problem the addressbook in DAM');
      setSaveIsDisabled(false);
      setSaving(false);
      return null;
    }

    // send to lytics
    try {
      const addressbookCanopusData = {
        party_id: null,
        party_name: '',
        canopusId: newAddressbook.canopusId,
        id: `${newAddressbook.id}`,
        name: newAddressbook.name,
      };

      if (isPartyIdValid) {
        addressbookCanopusData['party_id'] = newAddressbook.party_id;
        addressbookCanopusData['party_name'] = newAddressbook.party_name;
      }

      // logger.debug(
      //   `[saveAddressbook] sending addressbook mapping to Lytics - addressbookCanopusData: ${JSON.stringify(
      //     addressbookCanopusData
      //   )}`
      // );

      if (isPartyIdValid) {
        const res = await sendCanopusToLytics(
          newAddressbook.country,
          newAddressbook.accountId,
          addressbookCanopusData
        );
        // logger.debug(`[saveAddressbook] sent to Lytics ok - res: ${res}`);
      }
    } catch (e) {
      logger.error(`[saveAddressbook] error sending to Lytics: ${e}`);
      setErrorMessage(
        'There was a problem sending the addressbook metadata to Lytics'
      );
      setSaveIsDisabled(false);
      setSaving(false);
      return null;
    }

    // open saved modal
    setNewAddressbook(newAddressbook);
    setIsCreateAddressbookConfirmationOpen(true);
    handleSavedClosed();
  };

  interface AddressbookDuplicate {
    key: string;
    name: string;
    accountName: string;
  }

  interface ValidationResult {
    valid: boolean;
    duplicates?: AddressbookDuplicate[];
  }

  const checkCategoryValid = async (
    category: string,
    account_level: string,
    account_type: string,
    dd_account_id: number,
    country: string,
    party_id: number,
    b2b_b2c: string
  ): Promise<ValidationResult> => {
    logger.debug(`[AddressbookCreateModal] checkCategoryValid called`);
    // only check if party_id exists
    if (party_id === null || typeof party_id !== 'number' || isNaN(party_id)) {
      logger.debug(`[checkCategoryValid]: party_id is null or NaN`);
      return;
    }

    try {
      const callable = httpsCallable(
        functions,
        'accountManager-callableCheckAddressbookCategoryValid'
      );

      const result = (await callable({
        category,
        account_level,
        account_type,
        dd_account_id,
        country,
        party_id,
        b2b_b2c,
      })) as any;

      logger.debug(`[checkCategoryValid]: result: ${result}`);

      logger.debug(
        `[checkCategoryValid]: result.data: ${JSON.stringify(result.data)}`
      );

      if (result.data && result.data.valid === false) {
        setCategoryErrorMessage(`${result.data.errorMessage}`);
        setCategoryDuplicates((result.data && result.data.duplicates) || []);
      } else {
        setCategoryErrorMessage('');
        setCategoryDuplicates([]);
      }

      return result.data;
    } catch (e) {
      logger.error(`[checkCategoryValid] e: ${e}`);
    }
  };

  const categoryChanged = async (e) => {
    logger.debug(`[categoryChanged] called with ${e.target.value}.`);
    setCategory(e.target.value);
    logger.debug(
      `[categoryChanged] categoryValues ${JSON.stringify(categoryValues)}`
    );

    const defaultValues = R.mapObjIndexed((value, key, obj) => {
      // logger.debug(`val: ${value}, key: ${key}`);
      return R.mapObjIndexed((v, k, o) => {
        return v.default;
      }, value);
    }, defaultCategoryValues);

    const defaultedCategories = R.mergeRight(defaultValues, categoryValues);

    // get partyId from account if possible
    const partyID =
      (account.party_id &&
        /^\d+$/.test(account.party_id) &&
        account.party_id) ||
      null;

    const partyName =
      (account.party_name &&
        /^\d+$/.test(account.party_name) &&
        account.party_name) ||
      null;

    const partyIdValid = partyID && partyName ? true : false;

    const newAddressbook = {
      ...defaultAddressbook,
      country: `${country.toUpperCase()}`,
      dataCapture: defaultedCategories['dataCapture']['default_value'],
      excludeFromReporting:
        defaultedCategories['excludeFromReporting']['default_value'],
      nonConsented: defaultedCategories['nonConsented']['default_value'],
      doNotShare: defaultedCategories['doNotShare']['default_value'],
      canopusId: null,
      canopusValid: false,
      canopusName: null,
      custom_canopus: null,
      category: e.target.value,
      name: addressbook.name,
      tag: addressbook.tag,
      party_id: partyID,
      party_name: partyName,
      partyIdValid: partyIdValid,
    };

    if (partyIdValid) {
      logger.debug(
        `[categoryChanged] partyIdValid ${partyIdValid}. setting Addressbook`
      );
      setAddressbook(newAddressbook);
      await getAvailableLeadersForPartyId(
        addressbook.party_id,
        partyIdValid,
        newAddressbook
      );

      let categoryValid = false;

      try {
        const res = await checkCategoryValid(
          e.target.value,
          account.ddAccountLevel,
          account.ddAccountType,
          account.ddAccountId,
          `${country.toUpperCase()}`,
          partyID,
          account.isB2COrB2B
        );

        logger.debug(
          `[categoryChanged]: checkCategoryValid res: ${JSON.stringify(res)}`
        );

        if (res) {
          categoryValid = res.valid;
        }

        // now do something with res - surface in ui
      } catch (e) {
        logger.error(`[AddressbookCreateModal] `);
      }

      checkFormValid(
        addressbook.name,
        partyIdValid,
        category,
        addressbook.tag,
        partyIdRequired,
        categoryValid
      );
      return;
    }

    logger.debug(`[categoryChanged] about to duplicates`);
    setCategoryDuplicates([]);
    logger.debug(`[categoryChanged] set duplicates`);
    setCategoryErrorMessage('');
    logger.debug(`[categoryChanged] set error messages`);
    setAddressbook(newAddressbook);
    logger.debug(
      `[categoryChanged] setAddressbook: ${JSON.stringify(
        newAddressbook
      )} (newAddressbook)`
    );

    checkFormValid(
      addressbook.name,
      partyIdValid,
      e.target.value,
      addressbook.tag,
      partyIdRequired
    );
    logger.debug(`[categoryChanged] checked form valid - end function`);
  };

  const booleanChanged = (e, fieldName) => {
    const val = e.target.value === 'true';
    const addressbookToUpdate = { ...addressbook, [fieldName]: val };
    // logger.debug(
    //   `[booleanChanged] setAddressbook: ${JSON.stringify(addressbookToUpdate)}`
    // );
    setAddressbook(addressbookToUpdate);
  };

  const partyIdCommunityLeaderLookup = async () => {
    // logger.debug(
    //   `[TX] partyIdLookup called. addressbookRef: ${JSON.stringify(
    //     addressbookRef
    //   )} - sleeping`
    // );
    await sleep(1000);
    // logger.debug(
    //   `[TX] partyIdLookup called. addressbook: ${JSON.stringify(addressbook)}`
    // );
    setIsPartyIdLookingUpLeaders(true);
    const party_id: number = addressbook.party_id;
    const valid = addressbook.partyIdValid;

    await getAvailableLeadersForPartyId(party_id, valid, addressbook);
    setIsPartyIdLookingUpLeaders(false);
  };

  const getAvailableLeadersForPartyId = async (
    party_id,
    valid,
    latestAddressbook
  ) => {
    // logger.debug(
    //   `[TX] getAvailableLeadersForPartyId called with: ${party_id}, ${valid}, ${JSON.stringify(
    //     latestAddressbook
    //   )}`
    // );

    if (!valid) {
      // logger.debug(
      //   `[TX] getAvailableLeadersForPartyId !valid so setting addressbook and returning.`
      // );
      setAvailableLeadersSorted([]);
      setAvailableLeaders({});
      const newAddressbook = {
        ...addressbook,
        ...latestAddressbook,
        communityLeaderId: null,
        communityLeaderName: null,
        communityLeaderMappingValid: false,
      };

      logger.debug(
        `[TX] [getAvailableLeadersForPartyId] setAddressbook: ${JSON.stringify(
          newAddressbook
        )} (newAddressbook)`
      );
      // setAddressbook(newAddressbook);
      return;
    }

    try {
      // logger.debug(`[TX] party_id: ${party_id} (${typeof party_id})`);
      const leadersRef = ref('community/leaders');
      const leadersQuery = query(
        leadersRef,
        orderByChild('party_id'),
        equalTo(party_id)
      );
      const leadersSnap = await get(leadersQuery);
      const leaders = {};
      leadersSnap.forEach((v) => {
        const leader = v.val();
        // logger.debug(`[TX] leader: ${leader}, party_id: ${party_id}`);
        if (party_id === leader.party_id) {
          // logger.debug(
          //   `[TX] leader match on party_id: leader: ${leader.party_id}`
          // );
          leaders[leader.id] = {
            id: leader.id,
            name: leader.name,
            party_id: leader.party_id,
            party_name: leader.party_name,
          };
        }
      });

      const sortedLeaders = R.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }, R.values(leaders));

      setAvailableLeadersSorted(sortedLeaders);
      setAvailableLeaders(leaders);
      // logger.debug(`[TX] setAvailableLeaders: ${JSON.stringify(leaders)}`);
    } catch (e) {
      logger.error(`[TX] getAvailableLeaders: ${e}`);
      setAvailableLeadersSorted([]);
      setAvailableLeaders({});
    }
  };

  const handlePartyIdChange = async (partyId, partyName, canopusId) => {
    // logger.debug(
    //   `[AddressbookCreateModal] handlePartyIdChange: partyId: ${partyId}, partyName: ${partyName}`
    // );
    const valid = typeof partyName === 'string' && partyName.trim() !== '';
    const addressbookToUpdate = {
      ...addressbook,
      partyIdValid: valid,
      party_id: parseInt(partyId, 10),
      party_name: partyName,
      canopusName: '',
      canopusValid: null,
      custom_canopus: null,
      canopusId: `${canopusId}`,
    };

    setAddressbook(addressbookToUpdate);
    // logger.debug(`[handlePartyIdChange] setPartyIdValid - valid: ${valid}`);
    setPartyIdValid(valid);

    if (valid) {
      try {
        let categoryValid = false;

        const res = await checkCategoryValid(
          addressbook.category,
          account.ddAccountLevel,
          account.ddAccountType,
          account.ddAccountId,
          `${country.toUpperCase()}`,
          parseInt(partyId, 10),
          account.isB2COrB2B
        );

        // logger.debug(
        //   `[handlePartyIdChange]: checkCategoryValid res: ${JSON.stringify(
        //     res
        //   )}`
        // );

        if (res) {
          categoryValid = res.valid;
        }

        // await partyIdCommunityLeaderLookup();

        checkFormValid(
          addressbook.name,
          valid,
          category,
          addressbook.tag,
          partyIdRequired,
          categoryValid
        );

        return;
      } catch (e) {
        logger.error(`[AddressbookCreateModal] `);
      }
    }

    // await partyIdCommunityLeaderLookup();

    checkFormValid(
      addressbook.name,
      valid,
      category,
      addressbook.tag,
      partyIdRequired
    );
    // formik.setFieldValue('party_id', partyId);
    // formik.setFieldValue('party_name', partyName);
  };

  const handleNameChanged = async (e) => {
    // logger.debug(`handleNameChanged called`);
    const name = e.target.value;
    if (name.length >= 128) {
      return;
    }

    const addressbookToUpdate = { ...addressbook, name };
    // logger.debug(
    //   `[handleNameChanged] setAddressbook: ${JSON.stringify(
    //     addressbookToUpdate
    //   )}`
    // );
    setAddressbook(addressbookToUpdate);
    checkFormValid(
      name,
      partyIdValid,
      category,
      addressbook.tag,
      partyIdRequired
    );
  };

  const handleNameBlur = (e) => {
    const addressbookToUpdate = {
      ...addressbook,
      tag: `${e.target.value}_${addressbook.accountName}`,
    };
    // logger.debug(
    //   `[handleNameBlur] setAddressbook: ${JSON.stringify(
    //     addressbookToUpdate
    //   )}`
    // );
    setAddressbook(addressbookToUpdate);
    checkFormValid(
      addressbook.name,
      partyIdValid,
      category,
      `${e.target.value}_${addressbook.accountName}`,
      partyIdRequired
    );
  };

  const handleTagChanged = (e) => {
    // logger.debug(`handleTagChanged called: e.target.value: ${e.target.value}`);
    const tag = e.target.value;
    if (tag.length >= 128) {
      return;
    }

    const addressbookToUpdate = { ...addressbook, tag };
    // logger.debug(
    //   `[handleTagChanged] setAddressbook: ${JSON.stringify(
    //     addressbookToUpdate
    //   )}`
    // );
    setAddressbook(addressbookToUpdate);
    checkFormValid(
      addressbook.name,
      partyIdValid,
      category,
      tag,
      partyIdRequired
    );
  };

  const handleLeaderChanged = (e) => {
    // logger.debug(`handleLeaderChanged called`);
    const communityLeaderName =
      (availableLeaders[e.target.value] &&
        availableLeaders[e.target.value].name) ||
      '';

    if (communityLeaderName === '') {
      const addressbookToUpdate = {
        ...addressbook,
        communityLeaderName: null,
        communityLeaderId: null,
        communityLeaderMappingValid: false,
      };

      // logger.debug(
      //   `[handleLeaderChanged] setAddressbook: ${JSON.stringify(
      //     addressbookToUpdate
      //   )} (communityLeaderName === '')`
      // );
      setAddressbook(addressbookToUpdate);
      return;
    }

    const addressbookToUpdate = {
      ...addressbook,
      communityLeaderName,
      communityLeaderId: e.target.value,
      communityLeaderMappingValid: true,
    };

    // logger.debug(
    //   `[handleLeaderChanged] setAddressbook: ${JSON.stringify(
    //     addressbookToUpdate
    //   )} (addressbookToUpdate)`
    // );
    setAddressbook(addressbookToUpdate);
  };

  const countryChanged = async (e) => {
    // logger.debug(`countryChanged e.target.value: ${e.target.value}`);
    setCountry(e.target.value);

    const addressbookToUpdate = {
      ...addressbook,
      country: `${e.target.value}`.toUpperCase(),
    };

    setAddressbook(addressbookToUpdate);

    checkFormValid(
      addressbook.name,
      partyIdValid,
      category,
      addressbook.tag,
      partyIdRequired
    );

    logger.debug(`[countryChanged] calling checkCategoryValid with: 
    category: ${addressbook.category},
    account level: ${account.ddAccountLevel}, 
    account type: ${account.ddAccountType},
    account id: ${account.ddAccountId},
    country: ${e.target.value.toUpperCase()},
    party_id: ${addressbook.party_id}, 
    b2b/b2c: ${account.isB2COrB2B}`);

    try {
      const res = await checkCategoryValid(
        addressbook.category,
        account.ddAccountLevel,
        account.ddAccountType,
        account.ddAccountId,
        `${e.target.value.toUpperCase()}`,
        addressbook.party_id,
        account.isB2COrB2B
      );
    } catch (e) {
      logger.error(`[countryChanged] e: ${e}`);
    }
  };

  const checkFormValid = (
    name: string,
    partyIdValid: boolean,
    category: string,
    tag: string,
    partyIdRequired: boolean,
    categoryValid?: boolean
  ) => {
    // logger.debug(
    //   `checkFormValid called. name: ${name}, partyIdValid: ${partyIdValid}, category: ${category}, tag: ${tag}, partyIdRequired: ${partyIdRequired}, categoryValid: ${categoryValid}`
    // );
    if (name.length < 2) {
      setErrorMessage('Name must be set (min 2 characters - max 128)');
      setSaveIsDisabled(true);
      return;
    }

    if (partyIdValid === false && partyIdRequired === true) {
      setErrorMessage('A valid party id must be set for this category');
      setSaveIsDisabled(true);
      return;
    }

    if (tag === '') {
      setErrorMessage('A valid tag must be set for this addressbook');
      setSaveIsDisabled(true);
      return;
    }

    setErrorMessage('');

    // now check category rules results
    if (categoryValid === false) {
      setSaveIsDisabled(true);
      return;
    }

    setSaveIsDisabled(false);
  };

  if (loading || categoryLoading || countriesLoading) {
    return <Loader loading={true} />;
  }

  if (error || categoryErrors) {
    return <div>{`Error: ${error.message}`}</div>;
  }

  const countriesValues = (Object.values(countries) as any) || [];
  const countryOptions = R.map((country) => {
    // logger.debug(
    //   `country.value: ${country.value}, country.label: ${country.label}, account.territoryOperatingCountry: ${account.territoryOperatingCountry}`
    // );
    if (country.value !== 'de' || account.territoryOperatingCountry === 'de') {
      return (
        <option value={country.value} key={country.value}>
          {country.label}
        </option>
      );
    }
  }, countriesValues);

  const addressbookCategories =
    Object.values(enumerations.addressbookCategories) || [];
  const categoryOptions = R.map((category) => {
    if (category.value === 'unknown_list') {
      return;
    }

    if (category.value === 'registration_list' && canLPR !== true) {
      return;
    }

    return (
      <option value={category.value} key={category.value}>
        {category.label}
      </option>
    );
  }, addressbookCategories);

  const booleanOptions = R.map((boolean) => {
    return (
      <option value={boolean.value} key={boolean.value}>
        {boolean.label}
      </option>
    );
  }, Object.values({ true: { value: 'true', label: 'True' }, false: { value: 'false', label: 'False' } }));

  const allowMerchRecommendationsForAddressbooks =
    account.allowMerchRecommendationsForAddressbooks === true;

  const leaderOptions = R.map((leader) => {
    return (
      <option value={leader.id} key={leader.id}>
        {leader.name}
      </option>
    );
  }, availableLeadersSorted);

  // logger.debug(`addressbook: ${JSON.stringify(addressbook)}`);

  const categoryDuplicatesRows = categoryDuplicates.map((duplicate, idx) => {
    return (
      <Row key={idx}>
        <Col sm={2}></Col>
        <Col sm={8}>
          <Alert
            color="warning"
            className={addressbookStyles.categoryDuplicatesAlert}
          >
            <Link
              to={`../../addressbooks?q=id-${
                duplicate.key && duplicate.key.split('_')[1]
              }-number`}
              target="_blank"
            >{`addressbook key: ${duplicate.key} | account name: ${duplicate.accountName} | addressbook name: ${duplicate.name}`}</Link>
          </Alert>
        </Col>
        <Col sm={2}></Col>
      </Row>
    );
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={isCreateAddressbookOpen}
        toggle={handleClose}
        className={addressbookStyles.createAddressbookModal}
      >
        <ModalHeader
          toggle={handleClose}
          className={addressbookStyles.createAddressbookModalHeader}
        >
          {`Create new Addressbook for DD Account: ${
            account.ddAccountName
          } (${account.territoryOperatingCountry.toUpperCase()})`}
        </ModalHeader>
        <ModalBody
          className={
            saving || loading || isPartyIdLookingUpLeaders
              ? addressbookStyles.createAddressbookModalBodyDisabled
              : addressbookStyles.createAddressbookModalBody
          }
        >
          {errorMessage !== '' ? (
            <Row>
              <Col sm={2}></Col>
              <Col sm={8}>
                <Alert color="danger">{errorMessage}</Alert>
              </Col>
              <Col sm={2}></Col>
            </Row>
          ) : null}
          {categoryErrorMessage !== '' ? (
            <Row>
              <Col sm={2}></Col>
              <Col sm={8}>
                <Alert color="danger">{categoryErrorMessage}</Alert>
              </Col>
              <Col sm={2}></Col>
            </Row>
          ) : null}
          {categoryDuplicates && categoryDuplicates.length > 0 ? (
            <React.Fragment>
              <Row className={addressbookStyles.categoryDuplicatesHeaderRow}>
                <Col sm={2}></Col>
                <Col sm={8}>Category rule violation - duplicates:</Col>
                <Col sm={2}></Col>
              </Row>
              {categoryDuplicatesRows}
            </React.Fragment>
          ) : null}
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Addressbook Country:</Label>
              <Input
                type="select"
                value={country}
                onChange={countryChanged}
                disabled={!canAdminCountry}
              >
                {countryOptions}
              </Input>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Optin Category:</Label>
              <Input type="select" value={category} onChange={categoryChanged}>
                {categoryOptions}
              </Input>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Addressbook Name:</Label>
              <Input
                type="text"
                value={addressbook.name || ''}
                onChange={handleNameChanged}
                onBlur={handleNameBlur}
              />
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Tag:</Label>
              <Input
                type="text"
                value={addressbook.tag || ''}
                onChange={handleTagChanged}
              />
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <BrandInput
                name="party_id"
                value={addressbook.party_id || ''}
                label="Party ID"
                brandFieldName="party_name"
                brandFieldNameLabel="Party Name"
                disabled={false}
                handleOnChange={handlePartyIdChange}
                brandService={BrandService.PARTY_SERVICE}
              />
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Data Capture Enabled:</Label>
              <Input
                type="select"
                value={addressbook.dataCapture === true ? 'true' : 'false'}
                onChange={(e) => booleanChanged(e, 'dataCapture')}
                disabled={!partyIdValid || dataCaptureEditable === false}
              >
                {booleanOptions}
              </Input>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Exclude from Reporting:</Label>
              <Input
                type="select"
                value={
                  addressbook.excludeFromReporting === true ? 'true' : 'false'
                }
                onChange={(e) => booleanChanged(e, 'excludeFromReporting')}
                disabled={excludeFromReportingEditable === false}
              >
                {booleanOptions}
              </Input>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Non Consented:</Label>
              <Input
                type="select"
                value={addressbook.nonConsented === true ? 'true' : 'false'}
                onChange={(e) => booleanChanged(e, 'nonConsented')}
                disabled={nonConsentedEditable === false}
              >
                {booleanOptions}
              </Input>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Do Not Share:</Label>
              <Input
                type="select"
                value={addressbook.doNotShare === true ? 'true' : 'false'}
                onChange={(e) => booleanChanged(e, 'doNotShare')}
                disabled={doNotShareEditable === false}
              >
                {booleanOptions}
              </Input>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row className={addressbookStyles.createAddressbookModalBodyRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Label>Allow Merch Recommendations:</Label>
              <Input
                type="select"
                value={
                  addressbook.allowMerchRecommendations === true
                    ? 'true'
                    : 'false'
                }
                onChange={(e) => booleanChanged(e, 'allowMerchRecommendations')}
                disabled={!allowMerchRecommendationsForAddressbooks}
              >
                {booleanOptions}
              </Input>
            </Col>
            <Col sm={2}></Col>
          </Row>
          {availableLeadersSorted.length === 0 ? (
            <Row className={addressbookStyles.createAddressbookModalBodyRow}>
              <Col sm={2}></Col>
              <Col sm={8}>
                <Label>
                  Community Leader Mapping: There are no leaders available to
                  map for this Party ID
                </Label>
              </Col>
              <Col sm={2}></Col>
            </Row>
          ) : (
            <React.Fragment>
              <Row className={addressbookStyles.createAddressbookModalBodyRow}>
                <Col sm={2}></Col>
                <Col sm={8}>
                  <Label>Community Leader Mapping:</Label>
                </Col>
                <Col sm={2}></Col>
              </Row>
              <Row className={addressbookStyles.mappingModalBodyRow}>
                <Col sm={2}></Col>
                <Col sm={8}>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Leader Name</td>
                        <td>Leader ID</td>
                        <td>Leader Party ID</td>
                        <td>Leader Party Name</td>
                      </tr>
                      <tr>
                        <td>
                          <Input
                            type="select"
                            value={addressbook.communityLeaderId || ''}
                            onChange={handleLeaderChanged}
                          >
                            <option value="" key="">
                              Please select
                            </option>
                            {leaderOptions}
                          </Input>
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={addressbook.communityLeaderId || ''}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={
                              (availableLeaders[
                                addressbook.communityLeaderId
                              ] &&
                                availableLeaders[addressbook.communityLeaderId]
                                  .party_id) ||
                              ''
                            }
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={
                              (availableLeaders[
                                addressbook.communityLeaderId
                              ] &&
                                availableLeaders[addressbook.communityLeaderId]
                                  .party_name) ||
                              ''
                            }
                            disabled={true}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </React.Fragment>
          )}
        </ModalBody>
        <ModalFooter className={addressbookStyles.createAddressbookModalFooter}>
          {saving && (
            <Row>
              <Col sm={2}></Col>
              <Col sm={8}>
                <Loader loading={true} />
              </Col>
              <Col sm={2}></Col>
            </Row>
          )}
          <Row className={addressbookStyles.createAddressbookModalFooterRow}>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Button
                onClick={saveAddressbook}
                disabled={saveIsDisabled}
                className={addressbookStyles.saveButton}
              >
                Save
              </Button>
            </Col>
            <Col sm={2}></Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
