.activeFormCheck div {
  display: inline;
}

.activeFormCheck input {
  margin-top: 3px;
}

.activeCheckbox {
  margin-top: 3px;
}

.activeSTFormCheck div {
  display: inline;
  margin-bottom: 20px;
  margin-top: 20px;
}

.activeSTCheckbox {
  margin-top: 4px;
}

.activeCheckboxLabel {
  margin: 0px;
}

.actionLoading {
  width: 39px;
  height: 39px;
}

.createLoading {
  width: 39px;
  height: 39px;
}

.auditGroup {
  margin: 20px 0 30px 0;
}

.CreateCredentialsRow {
  vertical-align: top;
}

.credentialsAuditRow {
  padding: 0 !important;
}

.credsExportRow {
  padding: 20px 0 30px 0;
}

.actionButton {
  margin-right: 20px;
}

.formRow {
  margin-top: 20px !important;
}

.formRowActive {
  margin-top: 25px !important;
}

.codesTable {
  margin-top: 50px;
}

.codesSelectRow {
  margin-top: 10px;
}

.codesSave {
  margin-top: 20px;
}

.sendToCDPWarning {
  margin-top: 15px;
}

.addCredButton {
  margin-bottom: 1rem;
}

.submitButton {
  margin-top: 20px;
}
