import * as React from 'react';
import { useState, useEffect, useRef, useContext } from 'react';
import * as firebaseService from '../../services/firebase';
import { Loader } from '../../components/Loader';
import { useObjectVal } from 'react-firebase-hooks/database';
import { fuzzyTextFilterFnExp } from '../accounts/AccountsContainer';
import { DefaultColumnFilter } from '../../components/table/DefaultFilter';
import {
  useFilters,
  useSortBy,
  useTable,
  usePagination,
  useRowSelect,
} from 'react-table';
import { NumberFilter } from '../../components/table/NumberFilter';
import { AddressbooksTable } from './AddressbooksTable';
import {
  Alert,
  Button,
  Col,
  Collapse,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import moment from 'moment';
import { logger } from '../../logging';
import { ColumnsSelector } from '../../components/table/ColumnsSelector';
import { getPartyServiceRecord } from '../tags/Tags';
import { CrossIcon, TickIcon } from '../../components/Icons';
import { useFeature } from 'flagged';
import { AddressbookMappingModal } from './AddressbookMappingModal';
import { AddressbookCreateModal } from './AddressbookCreateModal';
import { Feature } from 'flagged';
import { ref } from '../../utils/firebase';
import { defaultCategoryValues } from '../optins/EmailCategoryRow';
import { DDTableFilters } from '../../components/DDTableFilters';
import { AbilityContext, Can } from '../../auth/Can';
import { AddressbooksBulkUpdate } from './AddressbooksBulkUpdate';
import { ExportToExcel } from '../accounts/ExportToExcel';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  equalTo,
  get,
  orderByChild,
  query,
  update,
  ref as fbRef,
  getDatabase,
} from 'firebase/database';

const R = require('ramda');

const dotdigitalStyles = require('../account/dotdigital/dotdigital.css');
const addressbookStyles = require('./addressbook.css');
const iconStyles = require('../../components/icons.css');
const pagesStyles = require('../pages.css');

const countryOrder = {
  US: 0,
  AU: 1,
  CA: 2,
  DE: 3,
  FR: 4,
  GB: 5,
  ES: 6,
  SE: 7,
  JP: 8,
  IT: 9,
};
const searchQueryLabels = {
  accountName: 'DotDigital Account Name',
  id: 'DotDigital Addressbook ID',
  accountId: 'DotDigital Account ID',
  name: 'DotDigital Addressbook Name',
  party_id: 'Party Service ID',
  party_name: 'Party Service Name',
};
// this should be in features/optins/api/get getById()
export const checkOptinExists = async (
  index: string,
  value: string,
  key?: string
) => {
  // logger.debug(
  //   `[checkOptinExists] index: ${index}, value: ${value}, key: ${key}`
  // );
  const ifbApp = firebaseService.getIFBApp();
  const ifbDatabase = getDatabase(ifbApp);
  const optinRef = fbRef(ifbDatabase, 'optins');
  const optinQuery = query(optinRef, orderByChild(index), equalTo(value));
  const optinSnap = await get(optinQuery);
  let exists = false;
  optinSnap.forEach((snap) => {
    const optin = snap.val();
    // logger.debug(
    //   `[checkOptinExists] optin.target_id: ${optin.target_id}, key: ${key}`
    // );
    if (typeof key !== 'undefined') {
      if (optin.target_id !== key) {
        exists = true;
      }
    } else {
      exists = true;
    }
  });

  // logger.debug(`[checkOptinExists] exists: ${exists}`);
  return exists;
};

interface AddressbookCanopusData {
  party_id: number | string;
  canopusId?: string;
  id: string; // addressbook id
  name: string; // addressbook name
}

export const sendCanopusToLytics = async (
  country: string,
  accountId: number,
  addressbook: AddressbookCanopusData
) => {
  // logger.debug(
  //   `[sendCanopusToLytics] called with ${country}, ${accountId}, ${JSON.stringify(
  //     addressbook
  //   )}`
  // );

  // only send to lytics if addressbook has a valid party_id
  if (!addressbook.party_id) {
    // logger.debug(`[sendCanopusToLytics] exiting as no party_id`);
    return;
  }

  const damApp = firebaseService.getDAMApp();
  const functions = getFunctions(damApp);
  try {
    const callable = httpsCallable(
      functions,
      'accountManager-callableSendCanopusToLytics'
    );
    await callable({ country, accountId, addressbook });
  } catch (e) {
    logger.error(`[sendCanopusToLytics] e: ${e}`);
  }
};

export const booleanOptions = [
  <option value="true" key="true">
    True
  </option>,
  <option value="false" key="false">
    False
  </option>,
];

export const Addressbooks = ({ account, allAccounts, enumerations }) => {
  const ability = useContext(AbilityContext);
  const canBulkUpdate =
    ability.can('bulkUpdate', 'addressbooks') && allAccounts === false;
  const canLPR = ability.can('manage', 'lpr');
  const communityMappingEnabled = useFeature('community-mapping');
  const addressbooksNewFlagsEnabled = useFeature('addressbooks-new-flags');
  const [mappingModalIsOpen, setMappingModalIsOpen] = useState(false);
  const [mappingAddressbook, setMappingAddressbook] = useState({});
  const [mappingAddressbookIndex, setMappingAddressbookIndex] = useState(null);
  const [filteredValues, setFilteredValues] = useState([]);
  const [filtersIsOpen, setFiltersIsOpen] = useState(true);
  const [bulkUpdateIsOpen, setBulkUpdateIsOpen] = useState(false);
  const [bulkUpdateLoading, setBulkUpdateLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [categoriesValues, categoriesLoading, categoriesErrors] = useObjectVal(
    ref(`optin_categories/email`)
  );

  const TABLE_NAME = 'addressbooks';
  const damApp = firebaseService.getDAMApp();
  const functions = getFunctions(damApp);
  const ifbApp = firebaseService.getIFBApp();
  const ifbDatabase = getDatabase(ifbApp);
  const damAuth = getAuth(damApp);
  const countriesRef = fbRef(ifbDatabase, 'countries');
  const countriesQuery = query(
    countriesRef,
    orderByChild('has_dm_account'),
    equalTo(true)
  );
  const [countriesList]: [any[], any, any] = useObjectVal(countriesQuery);
  const orderedCountryList = React.useMemo(() => {
    if (!countriesList) return [];
    const countryLen = Object.values(countryOrder).length;
    const countryArr = new Array(countryLen);
    const sorted = Object.values(countriesList || {}).filter((country) => {
      if (countryOrder[country.id] !== undefined) {
        countryArr[countryOrder[country.id]] = country;
        return false;
      }
      return true;
    });
    return [...countryArr, ...sorted];
  }, [countriesList]);
  const [loading, setLoading] = useState(false);
  const [isStaggeredLoading, setIsStaggeredLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [valuesLoading, setValuesLoading] = useState(false);
  const [isColumnsSelectOpen, setIsColumnsSelectOpen] = useState(false);
  const [isCreateAddressbookOpen, setIsCreateAddressbookOpen] = useState(false);
  const [newAddressbook, setNewAddressbook] = useState({} as any);
  const [
    isCreateAddressbookConfirmationOpen,
    setIsCreateAddressbookConfirmationOpen,
  ] = useState(false);
  const uid = damAuth.currentUser.uid;
  const userColsRef = ref(`user_preferences/${uid}/columns/${TABLE_NAME}`);
  const [userCols, userColsLoading, userColsError] = useObjectVal(
    userColsRef as any
  );

  const categories = enumerations.enumerations.addressbookCategories;
  const categoryCodes = Object.keys(categories);
  const categoryOptions = R.map((categoryCode) => {
    return (
      <option value={categoryCode} key={categoryCode}>
        {categories[categoryCode].label}
      </option>
    );
  }, categoryCodes);

  // when filteredValues changes
  // and there are no values yet
  // put values into the correct shape + update state with mapped values
  useEffect(() => {
    logger.debug(
      `[Addressbooks][useEffect][1] called - addressbookList changed`
    );
    if (isCreateAddressbookOpen === true || mappingModalIsOpen === true) {
      return;
    }

    let isMounted = true;
    if (filteredValues === null) {
      if (isMounted) {
        setValuesLoading(true);
        setValues([]);
      }
    }

    if (filteredValues) {
      const addressbooks = Object.keys(filteredValues).filter((k) => {
        return filteredValues[k];
      });

      if (isMounted) {
        // const newRows = filteredValues.filter((row) => !row.deletedFromDD);
        const newRows = filteredValues;
        // logger.debug(`newRow: ${JSON.stringify(newRows)}`);
        setValuesLoading(true);
        setValues(
          addressbooks.map((k) => {
            // logger.debug(
            //   `[Addressbooks] useEffect filteredValues changed: values: ${JSON.stringify(
            //     values
            //   )}`
            // );
            const value = newRows[k];
            if (!value) {
              logger.debug(
                `[Addressbooks] returning {} as no value found for newRows[k] k=${k}`
              );
              return {};
            }

            const key = value
              ? value.key || `${value.accountId}_${value.id}`
              : k;
            // logger.debug(
            //   `[Addressbooks] useEffect filteredValues changed: k=${k}, value.key = ${
            //     value && value.key
            //   }, key: ${key}`
            // );
            return { ...value, key };
          })
        );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [filteredValues]); // when filteredValues changes

  useEffect(() => {
    logger.debug(
      `[Addressbooks][useEffect][2] called - isCreateAddressbookOpen changed`
    );
    if (isCreateAddressbookOpen === false) {
      logger.debug(
        `isCreateAddressbookOpen has been closed. now reload the values`
      );

      let isMounted = true;
      if (filteredValues) {
        const addressbooks = Object.keys(filteredValues).filter((k) => {
          return filteredValues[k];
        });

        if (isMounted) {
          // const newRows = filteredValues.filter((row) => !row.deletedFromDD);
          const newRows = filteredValues;
          setValuesLoading(true);
          setValues(
            addressbooks.map((k) => {
              const value = newRows[k];
              if (!value) {
                return {};
              }

              const key = value
                ? value.key || `${value.accountId}_${value.id}`
                : k;
              // logger.debug(
              //   `[Addressbooks] useEffect isCreateAddressbookOpen changed: k=${k}, value.key = ${
              //     value && value.key
              //   }, key: ${key}`
              // );
              return { ...value, key };
            })
          );
        }
      }

      return () => {
        isMounted = false;
      };
    }
  }, [isCreateAddressbookOpen]);

  useEffect(() => {
    // logger.debug(`[Addressbooks][useEffect][3] called - values changed`);
    setValuesLoading(false);
  }, [values]);

  interface AddressbookDuplicate {
    key: string;
    name: string;
    accountName: string;
  }

  interface ValidationResult {
    valid: boolean;
    errorMessage?: string;
    duplicates?: AddressbookDuplicate[];
  }

  const checkCategoryValid = async (
    category: string,
    account_level: string | null,
    account_type: string | null,
    dd_account_id: number,
    country: string,
    party_id: number,
    addressbook_key: string,
    b2b_b2c: string | null
  ): Promise<ValidationResult> => {
    logger.debug(
      `[checkCategoryValid] category: ${category}, account_level: ${account_level}, account_type: ${account_type},
       dd_account_id: ${dd_account_id}, country: ${country}, party_id: ${party_id}, addressbook_key: ${addressbook_key}`
    );

    let dd_account_level = account_level;
    let dd_account_type = account_type;
    let dd_b2b_b2c = b2b_b2c;
    const accounts = [];

    if (!account_level || !account_type || !dd_b2b_b2c) {
      // get account from dd_account_id
      logger.debug(
        `[checkCategoryValid]: getting DAM account details: ${dd_account_id}`
      );
      try {
        const accountRef = ref(`accounts/dotdigital`);
        const accountQuery = query(
          accountRef,
          orderByChild('ddAccountId'),
          equalTo(`${dd_account_id}`)
        );
        const accountSnap = await get(accountQuery);
        if (accountSnap.exists()) {
          accountSnap.forEach((snap) => {
            const account = snap.val();
            accounts.push(account);
          });

          logger.debug(
            `[checkCategoryValid]: got DAM account details: accounts[0] :${JSON.stringify(
              accounts[0]
            )}`
          );
          dd_account_level = accounts[0].ddAccountLevel;
          dd_account_type = accounts[0].ddAccountType;
          dd_b2b_b2c = accounts[0].isB2COrB2B;
        }
      } catch (e) {
        logger.errr(
          `[checkCategoryValid] unable to get DAM account details: ${e}`
        );
        return {
          valid: false,
          errorMessage: 'unable to get DAM account details',
        };
      }
    }

    const callable = httpsCallable(
      functions,
      'accountManager-callableCheckAddressbookCategoryValid'
    );
    logger.debug(
      `[checkCategoryValid]: now calling callable with dd_account_level: ${dd_account_level}`
    );
    const result = (await callable({
      category,
      account_level: dd_account_level,
      account_type: dd_account_type,
      dd_account_id,
      country,
      party_id,
      b2b_b2c: dd_b2b_b2c,
    })) as { data: ValidationResult };

    const validationResult: ValidationResult = result.data;

    logger.debug(
      `[checkCategoryValid]: result.data: ${JSON.stringify(result.data)}`
    );

    return validationResult;
  };

  const updateData = async (rowIndex, columnId, value, updateTableData) => {
    logger.debug(
      `[updateData] rowIndex: ${rowIndex}, columnId: ${columnId}, value: ${value}, key: ${values[rowIndex].key}`
    );
    const addressbookRef = fbRef(
      ifbDatabase,
      `addressbooks/${values[rowIndex].accountId}_${values[rowIndex].id}`
    );
    const addressbook = {};
    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const hasPartyService =
      (values[rowIndex].haspartyid_processed_accountid_addressbookid &&
        values[rowIndex].haspartyid_processed_accountid_addressbookid.split(
          '_'
        )[0]) ||
      false;

    let valueParsed = value;
    if (value === 'true') {
      valueParsed = true;
    } else if (value === 'false') {
      valueParsed = false;
    }

    addressbook[columnId] = valueParsed;
    addressbook['modified'] = now;
    addressbook[
      'haspartyid_senttobq_accountid_addressbookid'
    ] = `${hasPartyService}_false_${values[rowIndex].key}`;

    try {
      logger.debug(`[updateData] [update called]`);
      await update(addressbookRef, addressbook);
    } catch (e) {
      logger.error('[updateData] - error updating addressbook row: ', e);
    }

    if (updateTableData) {
      setValues((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              ...addressbook,
            };
          }
          return row;
        })
      );
    }
  };

  const tableDataUpdated = (rowIndex, columnId, value) => {
    logger.info(
      `tableDataUpdated called for col: ${columnId} with value: ${value}`
    );
    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
            ['modified']: now,
          };
        }
        return row;
      })
    );
  };

  const updateProcessedData = async (rowIndex, processed) => {
    const addressbookRef = fbRef(
      ifbDatabase,
      `addressbooks/${values[rowIndex].key}`
    );
    const addressbook = {};
    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const partyIdValid = values[rowIndex].partyIdValid || false;

    addressbook[
      'haspartyid_processed_accountid_addressbookid'
    ] = `${partyIdValid}_${processed}_${values[rowIndex].key}`;
    addressbook[
      'haspartyid_processed_addressbookid'
    ] = `${partyIdValid}_${processed}_${values[rowIndex].id}`;
    addressbook['modified'] = now;

    if (processed === 'false' && values[rowIndex].communityLeaderId !== null) {
      addressbook['communityLeaderId'] = null;
      addressbook['communityLeaderName'] = null;
      addressbook['communityLeaderMappingValid'] = false;
    }

    try {
      logger.debug(`[updateProcessedData] [update called]`);
      await update(addressbookRef, addressbook);
    } catch (e) {
      logger.error('[updateData] - error updating addressbook row: ', e);
    }

    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            ...addressbook,
          };
        }
        return row;
      })
    );
  };

  const invalidateTextwordAddressbookMapping = async (
    accountId,
    addressbookId
  ) => {
    // logger.debug(`[invalidateTextwordAddressbookMapping] called with (${accountId}, ${addressbookId})`);
    const textwords = [];
    const updates = {};
    const textwordsRef = ref(`textwords`);

    try {
      const textwordsWithMappingRef = ref(`textwords`);
      const twQuery = query(
        textwordsWithMappingRef,
        orderByChild(`mappingDDAddressbookId`),
        equalTo(addressbookId)
      );
      const textwordsWithMappingSnap = await get(twQuery);
      textwordsWithMappingSnap.forEach((snap) => {
        const snapVal = snap.val();
        const snapKey = snap.key;
        const record = { ...snapVal, key: snapKey };
        textwords.push(record);
      });
    } catch (e) {
      logger.error(`[invalidateTextwordAddressbookMapping] error: ${e}`);
    }

    // logger.debug(`[invalidateTextwordAddressbookMapping] textwords.length: ${textwords.length}`);

    for (const textword of textwords) {
      if (textword.mappingDDAccountId === accountId) {
        // addressbook Id & account Id match so prepare to update
        updates[textword.key] = { ...textword };
        updates[textword.key]['mappingStatus'] = 'invalid';
        updates[textword.key]['addressbookMappingValid'] = false;
        updates[textword.key]['mappingDDAccountId'] = null;
        updates[textword.key]['mappingDDAddressbookId'] = null;
        updates[textword.key]['mapped_senttobq'] = 'false_false';
      }
    }

    // logger.debug(`[invalidateTextwordAddressbookMapping] updates: ${JSON.stringify(updates)}`);

    if (Object.keys(updates).length > 0) {
      // logger.debug(`[invalidateTextwordAddressbookMapping] updating ${Object.keys(updates).length} textwords.`);
      // logger.debug(`[invalidateTextwordAddressbookMapping] record 1: [${Object.keys(updates)[0]}]${JSON.stringify(updates[Object.keys(updates)[0]])}`);
      try {
        await update(textwordsRef, updates);
      } catch (e) {
        logger.error(
          '[invalidateTextwordAddressbookMapping] error calling update. e: ',
          e.message
        );
      }
    }
  };

  const EditableSelectCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateData,
  }) => {
    const processed =
      (original.haspartyid_processed_accountid_addressbookid &&
        original.haspartyid_processed_accountid_addressbookid.split('_')[1]) ||
      false;
    const [value, setValue] = React.useState(initialValue);

    const onChange = async (e) => {
      setValue(e.target.value);
      // logger.debug(`[EditableSelectCell] [updateData called]`);
      await updateData(index, id, e.target.value, true);
    };

    React.useEffect(() => {
      // logger.debug(
      //   `[Addressbooks][useEffect][4] called - EditableSelectCell initialValue changed`
      // );
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {processed === 'true' ? (
          value === 'true' ? (
            'True'
          ) : (
            'False'
          )
        ) : (
          <Input type="select" value={value} onChange={onChange}>
            {booleanOptions}
          </Input>
        )}
      </React.Fragment>
    );
  };

  const EditableSelectAllowMerchCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateData,
  }) => {
    const processed =
      (original.haspartyid_processed_accountid_addressbookid &&
        original.haspartyid_processed_accountid_addressbookid.split('_')[1]) ||
      false;
    const allowMerchRecommendationsEnabledForAccount =
      original.allowMerchRecommendationsEnabledForAccount || false;
    const [value, setValue] = React.useState(initialValue);

    const onChange = async (e) => {
      setValue(e.target.value);
      logger.debug(`[EditableSelectAllowMerchCell] [update called]`);
      await updateData(index, id, e.target.value, true);
    };

    React.useEffect(() => {
      // logger.debug(
      //   `[Addressbooks][useEffect][5] called - EditableSelectAllowMerchCell initialValue changed`
      // );
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {processed === 'true' ||
        allowMerchRecommendationsEnabledForAccount === false ? (
          value === 'true' ? (
            'True'
          ) : (
            'False'
          )
        ) : (
          <Input type="select" value={value} onChange={onChange}>
            {booleanOptions}
          </Input>
        )}
      </React.Fragment>
    );
  };

  const EditableTextCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateData, // This is a custom function that we supplied to our table instance
  }) => {
    const processed =
      (original.haspartyid_processed_accountid_addressbookid &&
        original.haspartyid_processed_accountid_addressbookid.split('_')[1]) ||
      false;
    const [value, setValue] = React.useState(initialValue);

    const onChange = async (e) => {
      const val = e.target.value;
      setValue(val);
    };

    const onBlur = async (e) => {
      const val = e.target.value;
      logger.debug(`[EditableTextCell] [update called]`);
      await updateData(index, id, val, true);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      // logger.debug(
      //   `[Addressbooks][useEffect][6] called - EditableTextCell initialValue changed`
      // );
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {processed === 'true' ? (
          value
        ) : (
          <Input
            type="text"
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      </React.Fragment>
    );
  };

  const EditableProcessedCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateProcessedData,
  }) => {
    const processed =
      (original.haspartyid_processed_accountid_addressbookid &&
        original.haspartyid_processed_accountid_addressbookid.split('_')[1]) ||
      false;
    const [value, setValue] = React.useState(processed);

    const onChange = async (e) => {
      const processed = e.target.value;
      // logger.debug(`[EditableProcessedCell] onChange - e.target.value: ${e.target.value}, original: ${JSON.stringify(original)}`);
      setValue(processed);
      if (processed === 'false') {
        // need to update any textwords which have this addressbook mapped to them
        const accountId = original.accountId;
        const addressbookId = original.id;
        try {
          await invalidateTextwordAddressbookMapping(accountId, addressbookId);
        } catch (e) {
          logger.error(`[EditableProcessedCell] onChange - error: ${e}`);
        }
      }

      // logger.debug(`[EditableProcessedCell] [update called]`);
      await updateProcessedData(index, processed);
    };

    React.useEffect(() => {
      // logger.debug(
      //   `[Addressbooks][useEffect][7] called - EditableProcessedCell original.haspartyid_processed_accountid_addressbookid changed`
      // );
      const processed =
        (original.haspartyid_processed_accountid_addressbookid &&
          original.haspartyid_processed_accountid_addressbookid.split(
            '_'
          )[1]) ||
        false;
      setValue(processed);
    }, [original.haspartyid_processed_accountid_addressbookid]);

    return (
      <Input type="select" value={value} onChange={onChange}>
        {booleanOptions}
      </Input>
    );
  };

  // updates the party service data against the addressbook
  const updatePartyServiceData = async (
    rowIndex: number,
    value: any,
    isValid: boolean,
    name: string,
    canopusId: string
  ) => {
    logger.debug(
      `[updatePartyServiceData] called with: rowIndex: ${rowIndex} (key: ${values[rowIndex].key}), value: ${value}, isValid: ${isValid}, name: ${name}, canopusId: ${canopusId}`
    );
    const addressbookRef = fbRef(
      ifbDatabase,
      `addressbooks/${values[rowIndex].key}`
    );
    const addressbook = {};
    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const nowTs = moment().unix();

    const isCanopusValid = canopusId && canopusId !== '' ? true : false;
    // we use this to check the process of canopus being added to party service record after initial ingestion
    const hasPartyIdAndNoCanopusId =
      isCanopusValid === false && isValid === true;

    addressbook['party_id'] =
      (value && /^\d+$/.test(value) && parseInt(value, 10)) || null;
    addressbook['modified'] = now;
    addressbook[
      'haspartyid_processed_accountid_addressbookid'
    ] = `${isValid}_false_${values[rowIndex].key}`;
    addressbook[
      'haspartyid_processed_addressbookid'
    ] = `${isValid}_false_${values[rowIndex].id}`;
    addressbook[
      'haspartyid_senttobq_accountid_addressbookid'
    ] = `${isValid}_false_${values[rowIndex].key}`;
    addressbook['partyIdValid'] = isValid;
    addressbook['party_name'] = name;
    addressbook['canopusId'] = canopusId ? `${canopusId}` : null;
    addressbook['canopusName'] = ''; // clearing this as we're not pulling canopus record - just setting canopus ID based on party record - so for name look at party Name.
    addressbook['canopusValid'] = null; // clearing this as we're not pulling canopus record
    addressbook['custom_canopus'] = null; // clearing this as we're not pulling canopus record
    addressbook['sentPartyIdToLytics'] = nowTs;
    addressbook['has_party_id_and_no_canopus_id'] = hasPartyIdAndNoCanopusId;

    try {
      logger.debug(`[updatePartyServiceData] [update called]`);
      await update(addressbookRef, addressbook);
    } catch (e) {
      logger.error(
        '[updatePartyServiceData] - error updating addressbook row: ',
        e
      );
    }

    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            ...addressbook,
          };
        }
        return row;
      })
    );
    return;
  };

  const EditablePartyServiceIDCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updatePartyServiceData,
  }) => {
    const processed =
      (original.haspartyid_processed_accountid_addressbookid &&
        original.haspartyid_processed_accountid_addressbookid.split('_')[1]) ||
      false;

    const [value, setValue] = React.useState(initialValue);
    const [loading, setLoading] = React.useState(false);
    const partyIdValid = original.partyIdValid || false;
    const [error, setError] = React.useState('');

    const onChange = async (e) => {
      const partyId = e.target.value;
      const re = /^\d+$/g;
      if (!re.test(partyId) && partyId !== '') {
        return;
      }

      const optinIndex = 'country_channel_category_brandId_tag';
      const optinIndexValue =
        `${original.country}_email_${original.category}_${partyId}_${original.tag}`.toLowerCase();

      setLoading(true);
      const optinExists = await checkOptinExists(
        optinIndex,
        optinIndexValue,
        original.key
      );

      let categoryRulesCheckResult = {
        valid: true,
      } as ValidationResult;
      // do new check for rules:
      try {
        // only check when we know party id too
        if (original.category && partyId !== '') {
          const account_level = (account && account.ddAccountLevel) || null;
          const account_type = (account && account.ddAccountType) || null;
          const b2b_b2c = (account && account.isB2COrB2B) || null;
          categoryRulesCheckResult = await checkCategoryValid(
            original.category,
            account_level,
            account_type,
            original.accountId,
            original.country,
            partyId,
            original.key,
            b2b_b2c
          );

          logger.debug(
            `[partyId change] categoryRulesCheckResult: ${JSON.stringify(
              categoryRulesCheckResult
            )}`
          );
        }
      } catch (e) {
        logger.error(`Failed to checkCategoryValid - e: ${e}`);
        categoryRulesCheckResult.valid = false;
      }

      if (
        optinExists === false &&
        categoryRulesCheckResult &&
        categoryRulesCheckResult.valid === true
      ) {
        setValue(partyId);
        setError('');
        await lookupPartyId(partyId);
        setLoading(false);
      } else if (
        categoryRulesCheckResult &&
        categoryRulesCheckResult.valid === false
      ) {
        setError(`${categoryRulesCheckResult.errorMessage || ''}`);
        const oldValue = initialValue || '';
        setValue(oldValue);
        await lookupPartyId(oldValue);
        setLoading(false);
      } else {
        const oldValue = initialValue || '';
        setValue(oldValue);
        await lookupPartyId(oldValue);
        setError('Duplicate key for optin');
        setLoading(false);
      }
    };

    const initialCheck = async (val) => {
      // logger.debug(`[initalCheck] for row: ${index}`);
      const party_id = val;
      const re = /^\d+$/g;
      if (!re.test(party_id) && party_id !== '') {
        return;
      }

      setLoading(true);
      setValue(party_id);
      await lookupPartyId(party_id);
      setLoading(false);
    };

    const lookupPartyId = async (party_id) => {
      const partyServiceRecord = await getPartyServiceRecord(party_id);
      logger.debug(`partServiceRecord: ${JSON.stringify(partyServiceRecord)}`);

      await updatePartyServiceData(
        index,
        party_id,
        partyServiceRecord.party_name !== '',
        partyServiceRecord.party_name !== ''
          ? partyServiceRecord.party_name
          : '',
        partyServiceRecord.canopus_id
      );

      const addressbook = {
        party_id,
        id: original.id,
        name: original.name,
        canopusId: partyServiceRecord.canopus_id,
        party_name: partyServiceRecord.party_name,
      };

      // this sends party service to lytics along with canopus now
      // only send if valid party id or no party id where there previously was one
      if (partyServiceRecord.party_name !== '') {
        await sendCanopusToLytics(
          original.country,
          parseInt(original.accountId, 10),
          addressbook
        );
      }
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      // logger.debug(
      //   `[Addressbooks][useEffect][8] called - EditablePartyServiceIDCell initialValue, allAccounts changed`
      // );
      setValue(initialValue);
      if (
        typeof original.partyIdValid === 'undefined' &&
        typeof initialValue !== 'undefined' &&
        /^\d+$/.test(initialValue) &&
        allAccounts !== true
      ) {
        initialCheck(initialValue);
      }
    }, [initialValue, allAccounts]);

    return (
      <React.Fragment>
        {error !== '' ? (
          <Alert color="danger" className={addressbookStyles.validationError}>
            {error}
          </Alert>
        ) : null}
        {processed !== 'true' ? (
          <React.Fragment>
            <Row>
              <Col sm={10}>
                <Input type="text" value={value || ''} onChange={onChange} />
              </Col>
              <Col sm={2}>
                {loading && (
                  <img
                    src="/images/loading.gif"
                    className={dotdigitalStyles.canopusLoader}
                  />
                )}
                {!loading && partyIdValid && (
                  <TickIcon fill="black" class={iconStyles.tick} />
                )}
                {!loading && !partyIdValid && (
                  <CrossIcon fill="black" class={iconStyles.cross} />
                )}
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          `${value || ''}`
        )}
      </React.Fragment>
    );
  };

  const editMappingClick = (addressbook, index) => {
    // logger.debug(
    //   `[editMappingClick] clicked, with addressbook: ${JSON.stringify(
    //     addressbook
    //   )}`
    // );
    if (addressbook.partyIdValid === true) {
      setMappingAddressbook(addressbook);
      setMappingAddressbookIndex(index);
      setMappingModalIsOpen(true);
    }
  };

  const updateDataAndSetDefaultsForCategory = async (
    rowIndex,
    category,
    partyIdValid
  ) => {
    logger.debug(
      `[updateDataAndSetDefaultsForCategory] rowIndex: ${rowIndex}, category: ${category}, key: ${values[rowIndex].key}`
    );
    const addressbookRef = fbRef(
      ifbDatabase,
      `addressbooks/${values[rowIndex].accountId}_${values[rowIndex].id}`
    );
    const addressbook = {};
    const now = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const hasPartyService =
      (values[rowIndex].haspartyid_processed_accountid_addressbookid &&
        values[rowIndex].haspartyid_processed_accountid_addressbookid.split(
          '_'
        )[0]) ||
      false;

    // From category options
    const defaultValues = R.mapObjIndexed((value, key, obj) => {
      // logger.debug(`val: ${value}, key: ${key}`);
      return R.mapObjIndexed((v, k, o) => {
        return v.default;
      }, value);
    }, defaultCategoryValues);
    const defaultedCategories = R.mergeRight(
      defaultValues,
      categoriesValues[category]
    );

    // logger.debug(`defaultedCategories: ${JSON.stringify(defaultedCategories)}`);
    // need to check party id status
    const dataCapture =
      partyIdValid === true &&
      defaultedCategories['dataCapture']['default_value'] === true
        ? true
        : false;

    addressbook['category'] = category;
    addressbook['dataCapture'] = dataCapture;
    addressbook['excludeFromReporting'] =
      defaultedCategories['excludeFromReporting']['default_value'];
    addressbook['nonConsented'] =
      defaultedCategories['nonConsented']['default_value'];
    addressbook['doNotShare'] =
      defaultedCategories['doNotShare']['default_value'];
    addressbook['modified'] = now;
    addressbook[
      'haspartyid_senttobq_accountid_addressbookid'
    ] = `${hasPartyService}_false_${values[rowIndex].key}`;

    try {
      logger.debug(`[updateDataAndSetDefaultsForCategory] [update called]`);
      await update(addressbookRef, addressbook);
    } catch (e) {
      logger.error(
        '[updateDataAndSetDefaultsForCategory] - error updating addressbook row: ',
        e
      );
    }

    setValues((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            ...addressbook,
          };
        }
        return row;
      })
    );
  };

  const EditableCategoryCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateData,
    updateDataAndSetDefaultsForCategory,
  }) => {
    const processed =
      (original.haspartyid_processed_accountid_addressbookid &&
        original.haspartyid_processed_accountid_addressbookid.split('_')[1]) ||
      false;
    const active = original.deletedFromDD !== true;
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState('');

    const onChange = async (e) => {
      // logger.debug(`EditableCategoryCell onchange (${e.target.value})`);
      const category = e.target.value;

      // don't allow setting of 'unknown_list'
      if (category === 'unknown_list') {
        setValue(initialValue || '');
        return;
      }

      // don't allow setting of lpr cat if don't have role
      if (category === 'registration_list' && canLPR !== true) {
        setValue(initialValue || '');
        return;
      }

      const optinIndex = 'country_channel_category_brandId_tag';
      const optinIndexValue =
        `${original.country}_email_${category}_${original.party_id}_${original.tag}`.toLowerCase();
      const optinExists = await checkOptinExists(
        optinIndex,
        optinIndexValue,
        original.key
      );

      let categoryRulesCheckResult = {
        valid: true,
      } as ValidationResult;
      // do new check for rules:
      try {
        // only check when we know party id too
        if (original.party_id && category !== '') {
          const account_level = (account && account.ddAccountLevel) || null;
          const account_type = (account && account.ddAccountType) || null;
          const b2b_b2c = (account && account.isB2COrB2B) || null;
          categoryRulesCheckResult = await checkCategoryValid(
            category,
            account_level,
            account_type,
            original.accountId,
            original.country,
            original.party_id,
            original.key,
            b2b_b2c
          );
        }
      } catch (e) {
        logger.error(`Failed to checkCategoryValid - e: ${e}`);
        categoryRulesCheckResult.valid = false;
      }

      if (
        optinExists === false &&
        categoryRulesCheckResult &&
        categoryRulesCheckResult.valid === true
      ) {
        setValue(category);
        setError('');
        // logger.debug(`calling updateData to set value: ${category}`);
        // this needs to be one update:
        await updateDataAndSetDefaultsForCategory(
          index,
          category,
          original.partyIdValid
        );
      } else if (
        categoryRulesCheckResult &&
        categoryRulesCheckResult.valid === false
      ) {
        setError(`${categoryRulesCheckResult.errorMessage || ''}`);
        setValue(initialValue || '');
      } else {
        // handle showing some error message about duplicate optin exists
        // logger.debug(`setting category to initialValue: ${initialValue}`);
        setValue(initialValue || '');
        setError('Duplicate key for optin');
      }
    };

    React.useEffect(() => {
      // logger.debug(
      //   `[Addressbooks][useEffect][9] called - EditableCategoryCell initialValue changed`
      // );
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {error !== '' ? (
          <Alert color="danger" className={addressbookStyles.validationError}>
            {error}
          </Alert>
        ) : null}
        {processed === 'true' ? (
          categories && categories[value] && categories[value].label
        ) : (
          <Input
            type="select"
            value={value}
            onChange={onChange}
            disabled={!active}
          >
            <option value="" key="">
              Please Select
            </option>
            {categoryOptions}
          </Input>
        )}
      </React.Fragment>
    );
  };

  const EditableTagCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateData,
  }) => {
    const processed =
      (original.haspartyid_processed_accountid_addressbookid &&
        original.haspartyid_processed_accountid_addressbookid.split('_')[1]) ||
      false;
    const active = original.deletedFromDD !== true;
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState('');

    const onChange = async (e) => {
      const val = e.target.value;
      setValue(val);
    };

    const onBlur = async (e) => {
      // logger.debug(`EditableTagCell onBlur (${e.target.value})`);
      const newValue = e.target.value;
      const optinIndex = 'country_channel_category_brandId_tag';
      const optinIndexValue =
        `${original.country}_email_${original.category}_${original.party_id}_${newValue}`.toLowerCase();

      const optinExists = await checkOptinExists(
        optinIndex,
        optinIndexValue,
        original.key
      );
      if (optinExists === false) {
        setValue(newValue);
        setError('');
        // logger.debug(`calling updateData to set value: ${newValue}`);
        await updateData(index, id, newValue, true);
      } else {
        // handle showing some error message about duplicate optin exists
        // logger.debug(`setting tag to initialValue: ${initialValue}`);
        setValue(initialValue || '');
        setError('Duplicate key for optin');
      }
    };

    React.useEffect(() => {
      // logger.debug(
      //   `[Addressbooks][useEffect][10] called - EditableTagCell initialValue changed`
      // );
      setValue(initialValue);
    }, [initialValue]);

    return (
      <React.Fragment>
        {error !== '' ? (
          <Alert color="danger" className={addressbookStyles.validationError}>
            {error}
          </Alert>
        ) : null}
        {processed === 'true' ? (
          value
        ) : (
          <Input
            type="text"
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            disabled={!active}
          />
        )}
      </React.Fragment>
    );
  };

  const RenderBooleanCell = ({
    cell: { value: initialValue },
    row: { index, original },
    column: { id },
    updateData,
  }) => {
    const [value, setValue] = React.useState(initialValue);
    return value === 'true' ? 'True' : 'False';
  };

  const columns = React.useMemo(() => {
    let cols: any = [
      {
        Header: 'Key',
        accessor: 'key',
        id: 'key',
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
      },
      {
        Header: 'DD Account Name',
        accessor: 'accountName',
        id: 'accountName',
      },
      {
        Header: 'DD Account ID',
        accessor: 'accountId',
        id: 'accountId',
      },
      {
        Header: 'DD Addressbook ID',
        accessor: 'id',
        id: 'id',
      },
      {
        Header: 'Country',
        accessor: 'country',
        id: 'country',
      },
      {
        Header: 'Contacts Count',
        Filter: NumberFilter,
        filter: 'between',
        accessor: (row) => {
          return row.contacts_count || 0;
        },
        id: 'contacts_count',
      },
      {
        Header: 'Canopus ID',
        accessor: (row) => {
          return row.canopusId || '';
        },
        id: 'canopusId',
      },
      {
        Header: 'Canopus Name',
        accessor: 'canopusName',
        id: 'canopusName',
      },
      {
        Header: 'Party Service ID',
        accessor: (row) => {
          if (row.party_id) {
            return row.party_id;
          }

          return '';
        },
        Cell: EditablePartyServiceIDCell,
        id: 'party_id',
      },
      {
        Header: 'Party Service Name',
        accessor: 'party_name',
        id: 'party_name',
      },
      {
        Header: 'Optin Category',
        accessor: 'category',
        Cell: EditableCategoryCell,
        id: 'category',
      },
      {
        Header: 'Optin Tag',
        accessor: 'tag',
        Cell: EditableTagCell,
        id: 'tag',
      },
      {
        Header: 'Processed',
        accessor: (row) => {
          const processed =
            row.haspartyid_processed_accountid_addressbookid &&
            row.haspartyid_processed_accountid_addressbookid.split('_')[1];
          if (processed === 'true') {
            return 'true';
          }

          return 'false';
        },
        Cell: EditableProcessedCell,
        id: 'processed',
      },
      {
        Header: 'Connect Local Artist ID',
        accessor: 'connectLocalId',
        Cell: EditableTextCell,
        id: 'connectLocalId',
      },
      {
        Header: 'R2 ID',
        accessor: 'r2Id',
        Cell: EditableTextCell,
        id: 'r2Id',
      },
      {
        Header: 'Data Capture Enabled',
        accessor: (row) => {
          if (row.dataCapture === true) {
            return 'true';
          }

          return 'false';
        },
        Cell: EditableSelectCell,
        id: 'dataCapture',
      },
      {
        Header: 'Exclude from Reporting',
        accessor: (row) => {
          if (row.excludeFromReporting === true) {
            return 'true';
          }

          return 'false';
        },
        Cell: EditableSelectCell,
        id: 'excludeFromReporting',
      },
      {
        Header: 'Non Consented',
        accessor: (row) => {
          if (row.nonConsented === true) {
            return 'true';
          }

          return 'false';
        },
        Cell: EditableSelectCell,
        id: 'nonConsented',
      },
      {
        Header: 'Sent to BQ',
        accessor: (row) => {
          if (row.sentToBq) {
            return moment(row.sentToBq, 'YYYY-MM-DD HH:mm:ss Z').format(
              'YYYY-MM-DD HH:mm:ss'
            );
          }

          return '';
        },
        id: 'sentToBq',
      },
      {
        Header: 'Created',
        accessor: (row) => {
          if (row.created) {
            return moment(row.created, 'YYYY-MM-DD HH:mm:ss Z').format(
              'YYYY-MM-DD HH:mm:ss'
            );
          }

          return '';
        },
        id: 'created',
      },
      {
        Header: 'Modified',
        accessor: (row) => {
          if (row.modified) {
            return moment(row.modified, 'YYYY-MM-DD HH:mm:ss Z').format(
              'YYYY-MM-DD HH:mm:ss'
            );
          }

          return '';
        },
        id: 'modified',
      },
      {
        Header: 'Deleted from DD',
        accessor: (row) => {
          if (row.deletedFromDD === true) {
            return 'true';
          }

          return 'false';
        },
        Cell: RenderBooleanCell,
        id: 'isDeletedFromDD',
      },
    ];

    const communityLeaderIdCol = {
      Header: 'Community Leader ID',
      accessor: 'communityLeaderId',
      Cell: ({ row }) => {
        const hasPartyId =
          row.original.party_id && row.original.party_id !== '';
        const isValidPartyId = row.original.partyIdValid === true;
        const consented = row.original.nonConsented !== true;
        const dataCapture = row.original.dataCapture === true;
        const notDeleted = row.original.deletedFromDD !== true;
        const processed =
          row.original.haspartyid_processed_accountid_addressbookid &&
          row.original.haspartyid_processed_accountid_addressbookid.split(
            '_'
          )[1] === 'true';
        const allowMapping =
          hasPartyId &&
          isValidPartyId &&
          consented &&
          dataCapture &&
          notDeleted &&
          processed
            ? true
            : false;
        return (
          <div className={addressbookStyles.mappingDiv}>
            <span
              onClick={
                allowMapping
                  ? () => editMappingClick(row.original, row.index)
                  : null
              }
              className={addressbookStyles.mappingSpan}
            >
              <input
                type="text"
                value={row.original.communityLeaderId || ''}
                readOnly={true}
                disabled={allowMapping ? false : true}
                className={allowMapping ? addressbookStyles.mappingInput : null}
              />
            </span>
          </div>
        );
      },
      id: 'communityLeaderId',
    };

    const communityLeaderNameCol = {
      Header: 'Community Leader Name',
      accessor: 'communityLeaderName',
      Cell: ({ row }) => {
        const hasPartyId =
          row.original.party_id && row.original.party_id !== '';
        const isValidPartyId = row.original.partyIdValid === true;
        const consented = row.original.nonConsented !== true;
        const dataCapture = row.original.dataCapture === true;
        const notDeleted = row.original.deletedFromDD !== true;
        const processed =
          row.original.haspartyid_processed_accountid_addressbookid &&
          row.original.haspartyid_processed_accountid_addressbookid.split(
            '_'
          )[1] === 'true';
        const allowMapping =
          hasPartyId &&
          isValidPartyId &&
          consented &&
          dataCapture &&
          notDeleted &&
          processed
            ? true
            : false;
        return (
          <div className={addressbookStyles.mappingDiv}>
            <span
              onClick={
                allowMapping
                  ? () => editMappingClick(row.original, row.index)
                  : null
              }
              className={addressbookStyles.mappingSpan}
            >
              <input
                type="text"
                value={row.original.communityLeaderName || ''}
                readOnly={true}
                disabled={allowMapping ? false : true}
                className={allowMapping ? addressbookStyles.mappingInput : null}
              />
            </span>
          </div>
        );
      },
      id: 'communityLeaderName',
    };

    const communityLeaderMappingValidCol = {
      Header: 'Community Leader Mapping Valid',
      accessor: (row) => {
        if (row.communityLeaderMappingValid === true) {
          return 'Valid Mapping';
        } else if (row.communityLeaderMappingValid === false) {
          return 'Invalid Mapping';
        }

        return '';
      },
      id: 'communityLeaderMappingValid',
      Cell: ({ row }) => {
        const invalid = row.original.communityLeaderMappingValid === false;
        const valid = row.original.communityLeaderMappingValid === true;
        return (
          <div className={addressbookStyles.mappingDiv}>
            {invalid && <Alert color="danger">Invalid Mapping</Alert>}
            {valid && <Alert color="success">Valid Mapping</Alert>}
          </div>
        );
      },
    };

    const doNotShareCol = {
      Header: 'Do Not Share',
      accessor: (row) => {
        if (row.doNotShare === true) {
          return 'true';
        }

        return 'false';
      },
      Cell: EditableSelectCell,
      id: 'doNotShare',
    };

    const allowMerchRecommendationsCol = {
      Header: 'Allow Merch Recommendations',
      accessor: (row) => {
        if (row.allowMerchRecommendations === true) {
          return 'true';
        }

        return 'false';
      },
      Cell: EditableSelectAllowMerchCell,
      id: 'allowMerchRecommendations',
    };

    const selectCol = {
      id: 'selection', // Unique ID for the selection column
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>
          <input
            type="checkbox"
            {...getToggleAllRowsSelectedProps()}
            indeterminate="false"
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div>
          <input
            type="checkbox"
            {...row.getToggleRowSelectedProps()}
            indeterminate="false"
          />
        </div>
      ),
      isSelectCol: true,
    };

    if (communityMappingEnabled === true) {
      cols = [
        ...cols,
        communityLeaderIdCol,
        communityLeaderNameCol,
        communityLeaderMappingValidCol,
      ];
    }

    if (addressbooksNewFlagsEnabled === true) {
      cols = [...cols, doNotShareCol, allowMerchRecommendationsCol];
    }

    // add selection col if can bulk update
    if (canBulkUpdate === true) {
      cols = [selectCol, ...cols];
    }

    return cols;
  }, [communityMappingEnabled]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFnExp,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // memoize values - the heavy lifting is now done in the above useEffect
  const records = React.useMemo(() => {
    return values;
  }, [values]);

  let hiddenColumns: any = R.keys(
    R.pickBy((val, key) => val === false, userCols)
  );

  const handleSelectedRows = (selectedFlatRows) => {
    setFiltersIsOpen(false);
    setBulkUpdateIsOpen(true);
    const addressbookKeys = [];
    selectedFlatRows.map((val, idx) => {
      addressbookKeys.push(val.original.key);
    });

    setSelectedRows(addressbookKeys);
  };

  const tableData = useTable(
    {
      columns,
      data: values || [],
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
      initialState: {
        sortBy: [
          {
            id: 'created',
            desc: true,
          },
        ],
        filters: [
          {
            id: 'isDeletedFromDD',
            value: 'false',
          },
        ],
        pageSize: 20,
        pageIndex: 0,
      },
      updateData,
      updateProcessedData,
      updatePartyServiceData,
      updateDataAndSetDefaultsForCategory,
      canBulkUpdate,
      handleSelectedRows,
    } as any,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  ) as any;

  React.useEffect(() => {
    // logger.debug(
    //   `[Addressbooks][useEffect][11] called - userCols changed = userCols: ${userCols}`
    // );
    hiddenColumns = R.without(
      ['selection'],
      R.keys(R.pickBy((val, key) => val === false, userCols))
    );

    tableData.setHiddenColumns(hiddenColumns);
  }, [userCols]);

  if (categoriesErrors) {
    return <div>{`Error: ${categoriesErrors}`}</div>;
  }

  if (userColsError) {
    return <div>Error loading cols</div>;
  }

  const toggleColumnsSelect = () =>
    setIsColumnsSelectOpen(!isColumnsSelectOpen);
  const toggleNewAddressbookConfirmation = () =>
    setIsCreateAddressbookConfirmationOpen(
      !isCreateAddressbookConfirmationOpen
    );
  const toggleCreateAddressbook = () => {
    logger.debug(`[Addressbooks] toggleCreateAddressbook called`);
    setIsCreateAddressbookOpen(!isCreateAddressbookOpen);
    setNewAddressbook({});
  };

  let newCatLabel = '';

  if (newAddressbook) {
    newCatLabel =
      (categories[newAddressbook.category] &&
        categories[newAddressbook.category].label) ||
      '[unknown]';
  }

  const toggleFiltersSelect = (id) => {
    setFiltersIsOpen((filter) => !filter);
  };

  const toggleBulkUpdateAddressbooks = (id) => {
    setBulkUpdateIsOpen((open) => !open);
  };

  const bulkRowsCount = (selectedRows && selectedRows.length) || 0;
  const bulkRowsKeys = (selectedRows && selectedRows.join(', ')) || '';

  return (
    <div className={pagesStyles.fullHeight}>
      <Row>
        <Col sm={{ size: 12 }}>
          <Button
            onClick={toggleColumnsSelect}
            className={addressbookStyles.columnsButton}
          >
            Columns
          </Button>
          <Button
            onClick={toggleFiltersSelect}
            className={addressbookStyles.columnsButton}
          >
            Filters
          </Button>
          {!isNaN(parseInt(`${account?.ddAccountId}`, 10)) && canBulkUpdate ? (
            <Button
              onClick={toggleBulkUpdateAddressbooks}
              className={addressbookStyles.columnsButton}
            >
              {bulkUpdateIsOpen ? 'Hide Bulk Update' : `Show Bulk Update`}
            </Button>
          ) : null}
          {!isNaN(parseInt(`${account?.ddAccountId}`, 10)) && (
            <Feature name="create-addressbooks">
              <Button
                onClick={toggleCreateAddressbook}
                className={addressbookStyles.columnsButton}
              >
                Create Addressbook
              </Button>
            </Feature>
          )}
          <Can I="manage" a="export">
            <ExportToExcel
              csvData={tableData.rows.map((row) => row.values) || []}
              fileName={`marketing-lists-export-csv-${moment().format(
                'DD-MM-YYYY hh:mm:ss'
              )}`}
              user={null}
            />
          </Can>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Collapse isOpen={isColumnsSelectOpen}>
            <ColumnsSelector
              columns={columns}
              userCols={userCols}
              tableName={TABLE_NAME}
            />
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 12 }}>
          <Alert color="warning">
            Please Note: Amending the Party Service ID Processed Status will
            impact connected DD Mappings and Reporting.
          </Alert>
        </Col>
      </Row>
      <Collapse isOpen={filtersIsOpen}>
        <Row>
          <Col className={pagesStyles.divider}></Col>
        </Row>
        <Row>
          <Col sm={5}>
            {bulkUpdateLoading === false &&
            isCreateAddressbookOpen === false ? (
              <DDTableFilters
                staggeredLoading
                setIsStaggeredLoading={setIsStaggeredLoading}
                path="addressbooks"
                db={'ifb'}
                setLoading={setLoading}
                title="Find Email Marketing Lists"
                rowsToExport={tableData.rows.map((row) => row.values) || []}
                setFilteredValues={setFilteredValues}
                customQuery={
                  allAccounts
                    ? undefined
                    : {
                        query: 'accountId',
                        value: Number(account.ddAccountId),
                      }
                }
                filters={
                  allAccounts
                    ? [
                        {
                          primary: true,
                          type: 'search',
                          query: 'dd_account_search',
                          customHandler: false,
                          submitable: true,
                          labels: {
                            placeholder: '',
                          },
                          options: [
                            {
                              id: 'name',
                              label: 'DotDigital Addressbook Name',
                              placeholder:
                                'Search for DotDigital Addressbook name',
                            },
                            {
                              id: 'party_name',
                              label: 'Party Service Name',
                              placeholder: 'Search for Party Service Name',
                            },
                            {
                              id: 'accountName',
                              label: 'DotDigital Account Name',
                              placeholder: 'Search for DD Account name',
                            },
                            {
                              id: 'key',
                              label: 'DAM key',
                              placeholder: 'Search for DAM key',
                            },
                            {
                              number: true,
                              id: 'id',
                              label: 'DotDigital Addressbook ID',
                              placeholder:
                                'Search for DotDigital Addressbook ID',
                            },
                            {
                              number: true,
                              id: 'accountId',
                              label: 'DotDigital Account ID',
                              placeholder: 'Search for DD Account ID',
                            },
                            {
                              number: true,
                              id: 'party_id',
                              label: 'Party Service ID',
                              placeholder: 'Search for Party Service ID',
                            },
                            {
                              toggle: true,
                              id: 'country',
                              label: 'Country',
                              value: orderedCountryList,
                            },
                            {
                              toggle: true,
                              id: 'category',
                              label: 'Category',

                              value: Object.values(categories).map((x: any) => {
                                return { id: x.value, label: x.label };
                              }),
                            },
                          ],
                        },
                      ]
                    : [
                        {
                          primary: true,
                          type: 'search',
                          query: 'dd_account_search',
                          customHandler: false,
                          submitable: true,
                          labels: {
                            placeholder: '',
                          },
                          options: [
                            {
                              id: 'name',
                              label: 'DotDigital Addressbook Name',
                              placeholder:
                                'Search for DotDigital Addressbook name',
                            },
                            {
                              id: 'party_name',
                              label: 'Party Service Name',
                              placeholder: 'Search for Party Service Name',
                            },
                            {
                              id: 'key',
                              label: 'DAM key',
                              placeholder: 'Search for DAM key',
                            },
                            {
                              number: true,
                              id: 'id',
                              label: 'DotDigital Addressbook ID',
                              placeholder:
                                'Search for DotDigital Addressbook ID',
                            },
                            {
                              number: true,
                              id: 'party_id',
                              label: 'Party Service ID',
                              placeholder: 'Search for Party Service ID',
                            },
                            {
                              toggle: true,
                              id: 'country',
                              label: 'Country',
                              value: orderedCountryList,
                            },
                            {
                              toggle: true,
                              id: 'category',
                              label: 'Category',

                              value: Object.values(categories).map((x: any) => {
                                return { id: x.value, label: x.label };
                              }),
                            },
                          ],
                        },
                      ]
                }
              />
            ) : null}
          </Col>
        </Row>
      </Collapse>

      <Collapse isOpen={bulkUpdateIsOpen}>
        <Row>
          <Col className={pagesStyles.divider}></Col>
        </Row>
        <Row>
          <Col sm={5}>Bulk Update Addressbooks {bulkRowsCount}</Col>
        </Row>
        <Row>
          <Col sm={12}>Total Addressbooks to update: {bulkRowsKeys}</Col>
        </Row>
        <Row>
          <Col className={pagesStyles.divider}></Col>
        </Row>
        <AddressbooksBulkUpdate
          addressbookKeys={selectedRows}
          ddAccount={account}
          enumerations={enumerations}
          emailOptinCategories={categoriesValues}
          setBulkUpdateLoading={setBulkUpdateLoading}
        />
      </Collapse>

      <Row>
        <Col className={pagesStyles.divider}></Col>
      </Row>
      {records.length === 0 && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="warning">
              No addressbooks found, initiate a search to load data.
            </Alert>
          </Col>
        </Row>
      )}
      {isStaggeredLoading && (
        <Row>
          <Col sm={{ size: 12 }}>
            <Alert color="info">
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <img
                  src="/images/loading.gif"
                  className="loading"
                  style={{ height: 40 }}
                />
                <span>Loading more data...</span>
              </span>
            </Alert>
          </Col>
        </Row>
      )}
      <Row className={pagesStyles.fullHeight}>
        <Col sm={{ size: 12 }} className={pagesStyles.fullHeight}>
          {loading ||
          userColsLoading ||
          categoriesLoading ||
          !categoriesValues ? (
            <Loader loading={true} />
          ) : (
            <AddressbooksTable {...tableData} />
          )}
        </Col>
      </Row>
      <AddressbookMappingModal
        addressbook={mappingAddressbook}
        mappingModalIsOpen={mappingModalIsOpen}
        setMappingModalIsOpen={setMappingModalIsOpen}
        setMappingAddressbook={setMappingAddressbook}
        tableDataUpdated={tableDataUpdated}
        mappingAddressbookIndex={mappingAddressbookIndex}
      />
      {account && (
        <AddressbookCreateModal
          account={account}
          setIsCreateAddressbookOpen={setIsCreateAddressbookOpen}
          isCreateAddressbookOpen={isCreateAddressbookOpen}
          setNewAddressbook={setNewAddressbook}
          setIsCreateAddressbookConfirmationOpen={
            setIsCreateAddressbookConfirmationOpen
          }
        />
      )}
      {newAddressbook && (
        <Modal
          isOpen={isCreateAddressbookConfirmationOpen}
          toggle={(e) => toggleNewAddressbookConfirmation()}
          className={addressbookStyles.createAddressbookModal}
        >
          <ModalHeader
            className={addressbookStyles.createAddressbookModalHeader}
          >
            Addressbook created successfully in DotDigital (
            {newAddressbook.accountName})
          </ModalHeader>
          <ModalBody className={addressbookStyles.createAddressbookModalBody}>
            <Row className={addressbookStyles.createAddressbookModalBodyRow}>
              <Col sm={12}>{`Addressbook key: ${newAddressbook.key}`}</Col>
            </Row>
            <Row className={addressbookStyles.createAddressbookModalBodyRow}>
              <Col sm={12}>{`Addressbook Name: ${newAddressbook.name}`}</Col>
            </Row>
            <Row className={addressbookStyles.createAddressbookModalBodyRow}>
              <Col sm={12}>{`DD Addressbook ID: ${newAddressbook.id}`}</Col>
            </Row>
            <Row className={addressbookStyles.createAddressbookModalBodyRow}>
              <Col
                sm={12}
              >{`Addressbook Country: ${newAddressbook.country}`}</Col>
            </Row>
            <Row className={addressbookStyles.createAddressbookModalBodyRow}>
              <Col sm={12}>{`Addressbook Category: ${newCatLabel}`}</Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={(e) => toggleNewAddressbookConfirmation()}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};
