import * as React from 'react';
import { useState, useContext } from 'react';
import { Loader } from '../../components/Loader';
import { Col, Row, Input } from 'reactstrap';
import { Accounts as DDAccounts } from './dotdigital/Accounts';
import { Accounts as SlicktextAccounts } from './slicktext/Accounts';
import { Accounts as AttentiveAccounts } from './attentive/Accounts';
import { Accounts as TreasureDataAccounts } from './treasure-data/Accounts';
import { Accounts as CommunityAccounts } from './community/Accounts';
import { Accounts as BrazeAccounts } from './braze/Accounts';
import * as matchSorter from 'match-sorter';
import { EnumerationsContext } from '../enumerations/EnumerationsContext';
import { LabelsContext } from '../labels/LabelsContext';
import { CountriesContext } from '../country-manager/CountriesContext';
import { AbilityContext } from '../../auth/Can';
import { logger } from '../../logging';
import { useFeature } from 'flagged';

const R = require('ramda');
const pagesStyles = require('../pages.css');
const accountsStyles = require('./accounts.css');

export interface Credential {
  name: string;
  description: string;
  username: string;
  password: string;
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
export const fuzzyTextFilterFnExp = fuzzyTextFilterFn;

export const AccountsContainer = () => {
  const { enumerations, loadingEnumerations } = useContext(EnumerationsContext);
  const { labels, loadingLabels } = useContext(LabelsContext);
  const { countries, loadingCountries } = useContext(CountriesContext);
  const [system, setSystemType] = useState('dotdigital');
  const [showActive, setShowActive] = useState(true);
  const ability = useContext(AbilityContext);

  if (
    !enumerations.enumerations ||
    loadingEnumerations ||
    !labels ||
    loadingLabels ||
    !countries ||
    loadingCountries
  ) {
    return <Loader loading={true} />;
  }

  const enumerationValues = enumerations.enumerations as any;

  const areAttentiveAccountsEnabled = useFeature('attentive-accounts');
  const isAttentiveAdmin = ability.can('manage', 'attentive_admin');

  const areBrazeAccountsEnabled = useFeature('braze-accounts');
  const hasBrazeAccess = ability.can('read', 'braze');

  const areTreasureDataAccountsEnabled = useFeature('treasure-data-admin');
  const isTreasureDataAdmin = ability.can('manage', 'treasure_data_admin');

  const areCommunityAccountsEnabled = useFeature('community-account-admin');
  const isCommunityAdmin = ability.can('manage', 'community_admin');

  let systems = Object.values(enumerationValues.systems) || [];

  if (!isAttentiveAdmin || !areAttentiveAccountsEnabled) {
    systems = systems.filter((system: any) => system.value !== 'attentive');
  }

  if (!isTreasureDataAdmin || !areTreasureDataAccountsEnabled) {
    systems = systems.filter((system: any) => system.value !== 'treasure_data');
  }

  if (!isCommunityAdmin || !areCommunityAccountsEnabled) {
    systems = systems.filter((system: any) => system.value !== 'community');
  }

  if (!hasBrazeAccess || !areBrazeAccountsEnabled) {
    systems = systems.filter((system: any) => system.value !== 'braze');
  }

  const systemOptions = R.map((system: any) => {
    return (
      <option value={system.value} key={system.value}>
        {system.label}
      </option>
    );
  }, systems);

  return (
    <div className={pagesStyles.fullHeight}>
      {systems && (
        <div className={`${pagesStyles.container} ${pagesStyles.fullHeight}`}>
          <Row className={pagesStyles.headerRow}>
            <Col sm={{ size: 12 }} className={pagesStyles.noPadding}>
              <h3>{`Accounts - ${showActive ? 'Active' : 'Inactive'}`}</h3>
            </Col>
          </Row>
          <Row className={accountsStyles.formRow}>
            <Col sm="12">
              <Input
                type="select"
                name="system"
                id="system"
                value={system}
                onChange={(e) => setSystemType(e.target.value)}
                className={accountsStyles.systemSelector}
              >
                {systemOptions}
              </Input>
            </Col>
          </Row>
          <div className={pagesStyles.fullHeight}>
            {system === 'dotdigital' ? (
              <DDAccounts
                enumerations={enumerations}
                showActive={showActive}
                setShowActive={setShowActive}
                labelsModel={labels}
                countriesModel={countries}
              />
            ) : null}
            {system === 'slicktext' ? (
              <SlicktextAccounts
                enumerations={enumerations}
                showActive={showActive}
                setShowActive={setShowActive}
                countriesModel={countries}
              />
            ) : null}
            {system === 'attentive' ? (
              <AttentiveAccounts
                enumerations={enumerations}
                showActive={showActive}
                setShowActive={setShowActive}
                countriesModel={countries}
              />
            ) : null}
            {system === 'treasure_data' ? (
              <TreasureDataAccounts
                showActive={showActive}
                setShowActive={setShowActive}
              />
            ) : null}
            {system === 'community' ? (
              <CommunityAccounts
                showActive={showActive}
                setShowActive={setShowActive}
                countriesModel={countries}
              />
            ) : null}
            {system === 'braze' ? (
              <BrazeAccounts
                enumerations={enumerations}
                showActive={showActive}
                setShowActive={setShowActive}
                countriesModel={countries}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
