import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
  AttentiveFields,
  validateAttentiveAccountName,
} from './AttentiveFields';
import { Form } from '@availity/form';
import { object, string, number, ref } from 'yup';
import '@availity/yup';
import { logger } from '../../../logging';

const AttentiveFormValidation = object().shape({
  account_name: string().required(),
  account_level: string().required(),
  canopus_id: string().isRequired(false),
  canopus_name: string().isRequired(false),
  label: string().required(),
  country_code: string().required(),
  language_code: string().required(),
  timezone: string().required(),
  party_id: number().required(),
  party_name: string().required(),
});

const accountStyles = require('../account.css');

const AttentiveFormErrorCheck = ({
  errors,
  touched,
  isValidating,
  setHasFormErrors,
  setFormErrors,
  setFormTouched,
}) => {
  logger.debug(
    `[AttentiveForm] AttentiveFormErrorCheck errors: ${JSON.stringify(
      errors
    )}, touched: ${JSON.stringify(touched)}, isValidating: ${JSON.stringify(
      isValidating
    )}`
  );

  const hasErrors = Object.keys(errors).length > 0;
  logger.debug(
    `[AttentiveForm] AttentiveFormErrorCheck hasErrors: ${hasErrors}`
  );

  React.useEffect(() => {
    setFormErrors(errors);
    setHasFormErrors(hasErrors);
    setFormTouched(touched);
  });

  return null;
};

export const AttentiveForm = ({
  canSave,
  model,
  save,
  account,
  system,
  setCanSave,
}) => {
  const [hasFormErrors, setHasFormErrors] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [formTouched, setFormTouched] = React.useState({});
  const defaultAttentiveFormValues = {
    account_name: '',
    account_id: '',
    account_level: '',
    canopus_id: '',
    canopus_name: '',
    party_id: '',
    party_name: '',
    label: '',
    country_code: '',
    language_code: '',
    timezone: '',
    active: true,
    ...model,
  };

  const handleSubmit = async (values, { setFieldError }) => {
    logger.debug(
      `[AttentiveForm] handleSubmit called: [ACTIVE]: ${JSON.stringify(
        values.active
      )} values: ${JSON.stringify(values)}`
    );

    logger.debug(
      `[AttentiveForm] handleSubmit - formErrors: ${JSON.stringify(formErrors)}`
    );

    // handle validateAccountName here
    const isValid = await validateAttentiveAccountName(
      values.account_name,
      values.id,
      setFieldError,
      setHasFormErrors
    );

    logger.debug(
      `[AttentiveForm] handleSubmit - [save about to be called] formErrors: ${JSON.stringify(
        formErrors
      )}, hasFormErrors: ${hasFormErrors}, isValid: ${isValid}`
    );

    if (isValid === true) {
      await save(values, formErrors);
    }
  };

  const submitDisabled = !canSave || hasFormErrors;

  return (
    <Form
      initialValues={defaultAttentiveFormValues}
      onSubmit={handleSubmit}
      validationSchema={AttentiveFormValidation}
    >
      {({ errors, touched, isValidating }) => (
        <React.Fragment>
          <Row>
            <Col sm="12">
              <AttentiveFields
                account_id={account && account.account_id}
                system={system}
                model={model}
                setCanSave={setCanSave}
                errors={errors}
              />
            </Col>
          </Row>
          <Button
            className={accountStyles.submitButton}
            type="submit"
            disabled={submitDisabled}
            color={submitDisabled ? 'secondary' : 'primary'}
          >
            Save
          </Button>
          <AttentiveFormErrorCheck
            errors={errors}
            touched={touched}
            isValidating={isValidating}
            setHasFormErrors={setHasFormErrors}
            setFormErrors={setFormErrors}
            setFormTouched={setFormTouched}
          />
        </React.Fragment>
      )}
    </Form>
  );
};
