import * as React from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router';
import { AccountForm } from '../account/AccountForm';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import { logger, serverLogger } from '../../logging';
import { getDAMApp } from '../../services/firebase';
import { getAuth } from 'firebase/auth';
import { ref } from '../../utils/firebase';
import { push, update } from 'firebase/database';

export const CreateAccountForm = ({ system }) => {
  const damApp = getDAMApp();
  const damAuth = getAuth(damApp);
  const [accountId, setAccountId] = useState('');
  const [user, userLoading] = useAuthState(damAuth as any);

  // consider adding validation for available account types

  const save = async (values, errors) => {
    logger.info(`createAccountForm save called`);
    const isDotdigital = system === 'dotdigital';
    const isSlicktext = system === 'slicktext';
    const isAttentive = system === 'attentive';
    const isTreasureData = system === 'treasure_data';
    const isCommunity = system === 'community';
    const isBraze = system === 'braze';
    const errorItems = Object.keys(errors);
    if (errorItems.length === 0 && !userLoading && user) {
      logger.info('no errors, saving');

      // trim important values
      if (values.ddAccountId) {
        values.ddAccountId = values.ddAccountId.trim();
      }

      if (values.ddAccountName) {
        values.ddAccountName = values.ddAccountName.trim();
      }

      if (values.id) {
        values.id = values.id.trim();
      }

      if (values.name) {
        values.name = values.name.trim();
      }

      if (values.account_id) {
        values.account_id = values.account_id.trim();
      }

      if (values.account_name) {
        values.account_name = values.account_name.trim();
      }

      if (values.party_id) {
        values.party_id = parseInt(values.party_id, 10);
      }

      const accountsRef = ref(`accounts/${system}`);
      const account = {
        ...values,
        createdBy: { uid: user.uid, email: user.email },
      };

      if (isDotdigital) {
        account.createdAt = `${moment().format()}`;
        account.data_acquisition_processing_method = 'asynchronous';
        account.data_acquisition_selection = 'unselected';
        account.createStandardLyticsEntities = false;
        if (values.isB2COrB2B === 'b2c') {
          account.send_subscription_insights = true;
          account.send_genre_insights = true;
        }
      }

      if (isSlicktext) {
        account.created = `${moment().unix()}`;
        account.data_acquisition_processing_method = 'asynchronous';
        account.data_acquisition_selection = 'unselected';
      }

      if (isAttentive || isTreasureData || isCommunity || isBraze) {
        account.created_at = `${moment().unix()}`;
        account.created_by = { uid: user.uid, email: user.email };
        delete account.createdBy;
        if (account.account_id === '') delete account.account_id;
      }

      if (isBraze) {
        account.data_acquisition_processing_method = 'asynchronous';
        account.data_acquisition_selection = 'unselected';
      }

      try {
        const accountRef = await push(accountsRef, account);
        setAccountId(accountRef.key);
        const newAccountRef = ref(`accounts/${system}/${accountRef.key}`);
        serverLogger(
          `[CreateAccountForm] User ${user.email} created account: ${accountRef.key} (${system})`,
          'info'
        );
        await update(newAccountRef, {
          key: accountRef.key,
          id: accountRef.key,
        });
      } catch (e) {
        serverLogger(
          `[CreateAccountForm] User ${user.email} had an error attempting to create account (${system})`,
          'error'
        );
        logger.error(e);
      }
    } else {
      logger.error('errors', errorItems);
    }
  };

  const formModel = {
    // not currently used - this is how we do default values with availity-form
    active: system === 'braze' ? false : true,
  };

  return (
    <div>
      {accountId !== '' && <Redirect to={`/accounts/${system}/${accountId}`} />}
      {accountId === '' && (
        <AccountForm
          save={save}
          model={formModel}
          account={null}
          system={system}
          accountOrig={null}
        />
      )}
    </div>
  );
};
