import * as React from 'react';
import { useState } from 'react';
import { logger } from '../logging';
import * as firebaseService from '../services/firebase';
import {
  equalTo,
  getDatabase,
  orderByChild,
  query,
  ref,
  get,
  startAt,
  endAt,
} from 'firebase/database';

const AsyncTypeahead: any = require('react-bootstrap-typeahead').AsyncTypeahead;

export const DDAddressbookSelector = ({
  addressbookId,
  accountId,
  setAddressbookId,
  disabled,
  excludeNonConsented,
}) => {
  const ifbApp = firebaseService.getIFBApp();
  const ifbDatabase = getDatabase(ifbApp);

  const [options, setOptions] = useState([]);
  const [selectedAddressbook, setSelectedAddressbook] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  React.useEffect(() => {
    let isMounted = true;
    if (addressbookId !== null && accountId !== null) {
      setIsLoading(true);
      const addressbooksRef = ref(ifbDatabase, 'addressbooks');
      const addressbooksQuery = query(
        addressbooksRef,
        orderByChild('id'),
        equalTo(parseInt(addressbookId, 10))
      );

      get(addressbooksQuery).then((snap) => {
        const addressbooks = [];
        snap.forEach((s) => {
          const val = s.val();
          if (
            val['accountId'] === parseInt(accountId, 10) &&
            val['name'] !== 'Test'
          ) {
            addressbooks.push(val);
          }
        });

        if (isMounted) {
          setSelectedAddressbook(addressbooks);
          setIsLoading(false);
        }
      });
    }

    if (accountId !== null && disabled !== true) {
      setIsDisabled(false);
    }

    if (accountId === null) {
      logger.debug(`accountId is null`);
      setIsDisabled(true);
      setSelectedAddressbook([]);
      setOptions([]);
    }

    return () => {
      isMounted = false;
    };
  }, [addressbookId, accountId]);

  const handleSearch = async (queryStr: string) => {
    setIsLoading(true);
    setSelectedAddressbook([]);

    const searchStr = `${accountId}_${queryStr.toLowerCase()}`;
    const addressbooks = [];
    const addressbooksRef = ref(ifbDatabase, 'addressbooks');
    const addressbooksQuery = query(
      addressbooksRef,
      orderByChild('account_addressbookname'),
      startAt(searchStr),
      endAt(`${searchStr}\uf8ff`)
    );

    const addressbooksSnap = await get(addressbooksQuery);
    addressbooksSnap.forEach((snap) => {
      const val = snap.val();
      if (val['name'] !== 'Test' && val.deletedFromDD !== true) {
        if (excludeNonConsented === true) {
          if (val.nonConsented !== true) {
            addressbooks.push({
              id: val.id,
              name: val.name,
              accountId: val.accountId,
              country: val.country,
              account_addressbookname: val.account_addressbookname,
            });
          }
        } else {
          addressbooks.push({
            id: val.id,
            name: val.name,
            accountId: val.accountId,
            country: val.country,
            account_addressbookname: val.account_addressbookname,
          });
        }
      }
    });

    setOptions(addressbooks);
    setIsLoading(false);
  };

  const handleChange = (e) => {
    if (e && e[0] && e[0].id) {
      logger.debug(`handleChange: e[0].id: ${e[0].id}`);
      setSelectedAddressbook(e);
      setAddressbookId(e[0].id);
    } else {
      setAddressbookId(null);
    }
  };

  return (
    <React.Fragment>
      <AsyncTypeahead
        isLoading={isLoading}
        id="dd-addressbooks-selector"
        useCache={false}
        labelKey={(addressbook: any) => addressbook.name}
        minLength={1}
        onSearch={handleSearch}
        onChange={handleChange}
        placeholder={'Find DD Addressbook'}
        selected={selectedAddressbook || []}
        options={options}
        disabled={isDisabled}
      />
    </React.Fragment>
  );
};
