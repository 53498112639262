import * as React from 'react';
import { Table, Button } from 'reactstrap';
import { DownArrow, UpArrow } from '../../components/Icons';
import { useEffect } from 'react';
import { logger } from '../../logging';

const optinsStyles = require('./optins.css');
const iconStyles = require('../../components/icons.css');

export const OptinsTable = ({
  preGlobalFilteredRows,
  state,
  setGlobalFilter,
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  system,
  pageOptions,
  page,
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  canPreviousPage,
  canNextPage,
  community_account_id,
  braze_account_id,
}) => {
  const filters = state.filters;
  const pageIndex = state.pageIndex;
  const pageSize = state.pageSize;

  useEffect(() => {
    // filters changed
    gotoPage(0);
  }, [filters]);

  return (
    <div className={optinsStyles.fullHeight}>
      <div className={optinsStyles.fullHeightTableContainer}>
        <div className={optinsStyles.fitContentTableContainer}>
          <Table
            {...getTableProps()}
            striped
            className={optinsStyles.optinsTable}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                      <Button
                        className={optinsStyles.sortButton}
                        size="sm"
                        color="link"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.isSorted ? 'sorted ' : 'sort'}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <DownArrow
                                fill="#007bff"
                                class={iconStyles.sortButton}
                              />
                            ) : (
                              <UpArrow
                                fill="#007bff"
                                class={iconStyles.sortButton}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </Button>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        {!community_account_id && !braze_account_id && (
          <React.Fragment>
            <div className={optinsStyles.pagination}>
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous Page
              </Button>
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                Next Page
              </Button>
              <div>
                Page{' '}
                <em>
                  {pageIndex + 1} of {pageOptions.length}
                </em>
              </div>
            </div>
            Total Rows: {rows.length}
            <br />
            Page Size: {pageSize}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
