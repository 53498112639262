import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react-dom';
import { useState } from 'react';
export function getMiddleware(props) {
  var middleware = [];
  if (props.flip) {
    middleware.push(flip());
  }
  if (props.align !== 'right' && props.align !== 'left') {
    middleware.push(size({
      apply: function apply(_ref) {
        var rects = _ref.rects,
          elements = _ref.elements;
        Object.assign(elements.floating.style, {
          width: "".concat(rects.reference.width, "px")
        });
      }
    }));
  }
  return middleware;
}
export function getPlacement(props) {
  var x = props.align === 'right' ? 'end' : 'start';
  var y = props.dropup ? 'top' : 'bottom';
  return "".concat(y, "-").concat(x);
}
export function useOverlay(referenceElement, options) {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    floatingElement = _useState2[0],
    attachRef = _useState2[1];
  var _useFloating = useFloating({
      elements: {
        floating: floatingElement,
        reference: referenceElement
      },
      middleware: getMiddleware(options),
      placement: getPlacement(options),
      strategy: options.positionFixed ? 'fixed' : 'absolute',
      whileElementsMounted: autoUpdate
    }),
    floatingStyles = _useFloating.floatingStyles;
  return {
    innerRef: attachRef,
    style: floatingStyles
  };
}
export default useOverlay;