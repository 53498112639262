import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { SlicktextFields, validateSTAccountName } from './SlicktextFields';
import { Form } from '@availity/form';
import { object, string, number, ref } from 'yup';
import '@availity/yup';
import { logger } from '../../../logging';

const SlicktextFormValidation = object().shape({
  name: string().required(),
  party_id: number().isRequired(false),
  party_name: string().isRequired(false),
  country: string().required(),
  language: string().required(),
  timezone: string().required(),
});

const accountStyles = require('../account.css');

const SlicktextFormErrorCheck = ({
  errors,
  touched,
  isValidating,
  setHasFormErrors,
  setFormErrors,
  setFormTouched,
}) => {
  logger.debug(
    `[SlicktextForm] SlicktextFormErrorCheck errors: ${JSON.stringify(
      errors
    )}, touched: ${JSON.stringify(touched)}, isValidating: ${JSON.stringify(
      isValidating
    )}`
  );

  const hasErrors = Object.keys(errors).length > 0;
  logger.debug(
    `[SlicktextForm] SlicktextFormErrorCheck hasErrors: ${hasErrors}`
  );

  React.useEffect(() => {
    setFormErrors(errors);
    setHasFormErrors(hasErrors);
    setFormTouched(touched);
  });

  return null;
};

export const SlicktextForm = ({
  canSave,
  model,
  save,
  account,
  system,
  setCanSave,
}) => {
  const [hasFormErrors, setHasFormErrors] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [formTouched, setFormTouched] = React.useState({});
  const defaultSlicktextFormValues = {
    name: '',
    normalised_name: '',
    stAccountId: '',
    party_id: '',
    party_name: '',
    country: '',
    language: '',
    timezome: '',
    active: true,
    ...model,
  };

  const handleSubmit = async (values, { setFieldError }) => {
    logger.debug(
      `[SlicktextForm] handleSubmit called: [ACTIVE]: ${JSON.stringify(
        values.active
      )} values: ${JSON.stringify(values)}`
    );

    logger.debug(
      `[SlicktextForm] handleSubmit - formErrors: ${JSON.stringify(formErrors)}`
    );

    // handle validateAccountName here
    const isValid = await validateSTAccountName(
      values.name,
      values.normalised_name,
      values.id,
      setFieldError,
      setHasFormErrors
    );

    logger.debug(
      `[SlicktextForm] handleSubmit - [save about to be called] formErrors: ${JSON.stringify(
        formErrors
      )}, hasFormErrors: ${hasFormErrors}, isValid: ${isValid}`
    );

    if (isValid === true) {
      await save(values, formErrors);
    }
  };

  const submitDisabled = !canSave || hasFormErrors;

  return (
    <Form
      initialValues={defaultSlicktextFormValues}
      onSubmit={handleSubmit}
      validationSchema={SlicktextFormValidation}
    >
      {({ errors, touched, isValidating }) => (
        <React.Fragment>
          <Row>
            <Col sm="12">
              <SlicktextFields
                accountId={account && account.id}
                system={system}
                model={model}
                setCanSave={setCanSave}
                errors={errors}
              />
            </Col>
          </Row>
          <Button
            className={accountStyles.submitButton}
            type="submit"
            disabled={submitDisabled}
            color={submitDisabled ? 'secondary' : 'primary'}
          >
            Save
          </Button>
          <SlicktextFormErrorCheck
            errors={errors}
            touched={touched}
            isValidating={isValidating}
            setHasFormErrors={setHasFormErrors}
            setFormErrors={setFormErrors}
            setFormTouched={setFormTouched}
          />
        </React.Fragment>
      )}
    </Form>
  );
};
