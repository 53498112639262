import * as React from 'react';
import { useEffect, useState } from 'react';
import MultiSelectDropdown from './MultiSelectDropdown';
import { TableSearch } from './TableSearch';
import * as firebaseService from '../services/firebase';
import { getDAMApp, getIFBApp } from '../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Loader } from './Loader';
import { Col, Row } from 'reactstrap';
import {
  equalTo,
  getDatabase,
  orderByChild,
  query,
  ref,
  get,
  startAt,
  endAt,
  update,
  startAfter,
  QueryConstraint,
  limitToFirst,
} from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { logger } from '../logging';

interface filter {
  type: 'search' | 'toggle';
  options: any[];
  query: string;
  customHandler: boolean;
  submitable?: boolean;
  exact?: boolean;
  primary?: boolean;
  number?: boolean;
  labels: {
    all?: string;
    some?: string;
    placeholder?: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  onSelect?: Function;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  onDeselect?: Function;
}

interface props {
  path: string;
  db: string;
  filters: filter[];
  title: string;
  setLoading: any;
  setIsStaggeredLoading?: (value: boolean) => void;
  rowsToExport: any[];
  customQuery?: {
    query: string;
    value: string | number | boolean;
    type?: 'starts_with';
  };
  setFilteredValues: React.SetStateAction<any>;
  staggeredLoading?: boolean;
}

interface cloudFilter {
  customQuery?: {
    query: string;
    value: string | number | boolean;
    type?: 'exact' | 'broad' | 'starts_with';
    options?: any[];
  };
  path: string;
  db: string;
  range: number;
  filters: {
    primary: boolean;
    query: string;
    type: 'exact' | 'broad';
    value?: string | number;
    options?: any[];
  }[];
}

async function executeFilter(
  { path, db, range, filters, customQuery }: cloudFilter,
  shouldPaginate: boolean = false,
  lastKV?: { key: string; value: any }
): Promise<{ values: unknown[]; lastKV?: { key: string; value: any } }> {
  const dbApp = db === 'ifb' ? getIFBApp() : getDAMApp();
  const fbDB = getDatabase(dbApp);
  const dataRef = ref(fbDB, path);
  const [primaryFilter] = customQuery
    ? [customQuery]
    : filters.filter((filter) => filter.primary);
  if (!primaryFilter) {
    return { values: [], lastKV: undefined };
  }

  // logger.debug(
  //   `[DDTableFilters][executeFilter] primaryFilter: ${JSON.stringify(
  //     primaryFilter
  //   )}`
  // );

  const queryConstraints: QueryConstraint[] = [];

  const isValueNumberOrBool =
    typeof primaryFilter.value === 'number' ||
    typeof primaryFilter.value === 'boolean';

  if (primaryFilter.type === 'broad') {
    const value = primaryFilter.options[0] || '';
    if (lastKV) queryConstraints.push(equalTo(lastKV.value, lastKV.key));
    else queryConstraints.push(equalTo(value));
  } else if (isValueNumberOrBool) {
    if (lastKV) queryConstraints.push(equalTo(lastKV.value, lastKV.key));
    else queryConstraints.push(equalTo(primaryFilter.value));
  } else {
    // Help Typescript infer that value is expected to be string
    const value = primaryFilter.value as string;

    // logger.debug(
    //   `[DDTableFilters][executeFilter] value: ${value} lastKV: ${JSON.stringify(
    //     lastKV
    //   )}`
    // );

    if (lastKV) queryConstraints.push(startAfter(lastKV.value, lastKV.key));
    else queryConstraints.push(startAt(value.toUpperCase()));

    queryConstraints.push(endAt(`${value.toLowerCase()}\uf8ff`));
  }

  if (shouldPaginate) queryConstraints.push(limitToFirst(range));

  // logger.debug(
  //   `[DDTableFilters][executeFilter] queryConstraints: ${JSON.stringify(
  //     queryConstraints
  //   )}`
  // );

  const dataQuery = query(
    dataRef,
    orderByChild(primaryFilter.query),
    ...queryConstraints
  );
  const data = [];
  let newLastKey = undefined;
  let newLastValue = undefined;
  (await get(dataQuery)).forEach((s) => {
    const d = s.val();
    if (s.key !== lastKV?.key) {
      data.push(d);
      newLastKey = s.key;
      newLastValue = d?.[primaryFilter.query];
    }
  });

  const filteredValues = data.filter((val: any) => {
    let passing = true;

    filters.forEach((filter) => {
      switch (filter.type) {
        case 'broad':
          if (
            !filter.options
              ?.map((option) => String(option).toLowerCase())
              .includes(String(val[filter.query]).toLowerCase()) &&
            filter.options?.length
          ) {
            passing = false;
          }
          break;
        case 'exact':
          if (
            !String(val[filter.query])
              .toLowerCase()
              .startsWith(String(filter.value).toLowerCase())
          ) {
            passing = false;
          }
          break;
      }
    });

    // logger.debug(
    //   `[DDTableFilters][filteredValues] val.target_id: ${val.target_id}, queryValue: ${customQuery.value}`
    // );

    if (
      customQuery &&
      customQuery.query === 'target_id' &&
      customQuery.type === 'starts_with'
    ) {
      // When custom query is target_id and starts_with...
      const queryValue = customQuery.value as string;
      passing =
        val.target_id?.toLowerCase().indexOf(queryValue.toLowerCase()) !== -1;
    } else if (customQuery && customQuery.query === 'target_id') {
      // When custom query is target_id, check for exact match
      const queryValue = customQuery.value as string;
      passing = val.target_id?.toLowerCase() === queryValue.toLowerCase();
    }

    return passing;
  });

  return {
    values: filteredValues,
    lastKV: { key: newLastKey, value: newLastValue },
  };
}

async function filter(filterParams: cloudFilter) {
  // logger.debug(`[DDTableFilters][filter] called`);
  const { values } = await executeFilter(filterParams, false);
  return values;
}

async function* staggeredFilter(filterParams: cloudFilter) {
  // logger.debug(`[DDTableFilters][staggeredFilter] called`);
  let startAfterKV: { key: string; value: any } = undefined;
  let count = 0;
  do {
    const { values, lastKV } = await executeFilter(
      filterParams,
      true,
      startAfterKV
    );
    count++;
    startAfterKV = lastKV;
    yield values;
  } while (startAfterKV && startAfterKV?.key);
}

const DDTableFilterStyles = require('./DDTable.css');

export const DDTableFilters = ({
  filters,
  setFilteredValues,
  title,
  path,
  db,
  customQuery,
  setLoading,
  setIsStaggeredLoading,
  staggeredLoading = false,
}: props) => {
  const damApp = firebaseService.getDAMApp();
  const damDatabase = getDatabase(damApp);
  const auth = getAuth(damApp);
  const [user] = useAuthState(auth as any);

  const userPreferenceRef = ref(
    damDatabase,
    `user_preferences/${user.uid}/filters`
  );
  const [userPreferenceVal, userPreferencesLoading] = useObjectVal(
    userPreferenceRef as any
  );

  const userPreference = React.useMemo(
    () => userPreferenceVal || {},
    [userPreferenceVal]
  );

  const [activeFilters, setActiveFilters] = useState({
    toggle: {},
    search: {},
  });
  const [refresh, setRefresh] = useState(0);
  const [primary, setPrimary] = useState('');
  const [range, setRange] = useState(5000);

  const inputRangeRef = React.useRef(null);

  // logger.debug(`uid: ${uid}`)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const controller = new AbortController();
    if (params.has('q')) {
      const queryStr = params.get('q').split('-');
      (async () => {
        const filterParams: cloudFilter = {
          path,
          db,
          range,
          filters: [
            {
              query: queryStr[0],
              value:
                queryStr[2] === 'number'
                  ? Number(queryStr[1])
                  : queryStr[1] || 0,
              type: 'exact',
              primary: true,
            },
          ],
        };

        if (staggeredLoading) {
          setIsStaggeredLoading?.(true);
          setFilteredValues([]);
          // logger.debug(
          //   `[DDTableFilters][useEffect] staggeredFilter about to be called`
          // );
          const genFn = staggeredFilter(filterParams);
          for await (const values of genFn) {
            if (controller?.signal.aborted) {
              genFn.return(); // abort generator
              return;
            }
            setFilteredValues((prev) => [...prev, ...values]);
            if (setLoading) setLoading(false);
          }
          setIsStaggeredLoading?.(false);
        } else {
          const newRows = await filter(filterParams);
          setFilteredValues(newRows);
        }

        setLoading(false);
      })();
    }
    return () => controller.abort();
  }, []);

  useEffect(() => {
    setActiveFilters((old) => {
      filters.forEach((filter) => {
        if (filter.type === 'toggle' && !old.toggle[filter.query]?.length) {
          old.toggle[filter.query] = [];
          return;
        }
      });
      return old;
    });
    const [firstFilter] = filters;
    setPrimary(firstFilter.query);
  }, []);

  async function filterValues(controller?: AbortController) {
    const filters = [];
    setLoading(true);
    Object.entries(activeFilters.search).forEach(
      (
        searchQuery: [
          string,
          {
            input: string;
            select: string;
            submitable: boolean;
            customHandler: boolean;
            number: boolean;
            id: string;
          }
        ]
      ) => {
        if (searchQuery[1].customHandler) {
          return;
        }
        const searchValue = searchQuery[1].input;
        const filter = {
          value: searchValue,
          query: searchQuery[1].select,
          type: 'exact',
          primary: searchQuery[1].id === primary,
        };
        filters.push(filter);
      }
    );

    Object.entries(activeFilters.toggle).forEach((toggleQuery: any) => {
      if (toggleQuery[2]) {
        return;
      }
      const filter = {
        type: 'broad',
        query: toggleQuery[0],
        options: toggleQuery[1].map((val) => val[0]),
        primary: toggleQuery[0] === primary,
      };

      filters.push(filter);
    });

    const filterProps: cloudFilter = {
      path: path,
      db: db,
      range,
      filters: filters,
      customQuery,
    };

    if (staggeredLoading) {
      setIsStaggeredLoading?.(true);
      setFilteredValues([]);
      // logger.debug(
      //   `[DDTableFilters][filterValues] staggeredFilter about to be called`
      // );
      const genFn = staggeredFilter(filterProps);
      for await (const values of genFn) {
        if (controller?.signal.aborted) {
          genFn.return(); // abort generator
          return;
        }
        setFilteredValues((prev) => [...prev, ...values]);
        if (setLoading) setLoading(false);
      }
      setIsStaggeredLoading?.(false);
    } else {
      const newRows = await filter(filterProps);
      setFilteredValues(newRows);
    }

    setLoading(false);
  }

  useEffect(() => {
    const controller = new AbortController();
    // logger.debug(
    //   `[DDTableFilters][useEffect] filterValues about to be called.
    //   JSON.stringify(activeFilters.search): ${JSON.stringify(
    //     activeFilters.search
    //   )},
    //   refresh: ${refresh},
    //   primary: ${primary},
    //   JSON.stringify(customQuery): ${JSON.stringify(customQuery)}`
    // );
    filterValues(controller);
    return () => controller.abort();
  }, [
    JSON.stringify(activeFilters.search),
    refresh,
    primary,
    JSON.stringify(customQuery),
  ]);

  useEffect(() => {
    if (!userPreference) return;
    setActiveFilters((old) => {
      filters.forEach((filter) => {
        if (filter.customHandler) {
          return;
        }
        if (
          filter.type === 'search' &&
          userPreference[filter.query] &&
          userPreference[filter.query]?.synced
        ) {
          const { input, select, submitable, customHandler } =
            userPreference[filter.query];
          if (
            input !== undefined &&
            select !== undefined &&
            submitable !== undefined
          ) {
            old.search[filter.query] = {
              input,
              select,
              submitable,
              customHandler,
            };
          }
        }
        if (
          filter.type === 'toggle' &&
          userPreference[filter.query] &&
          userPreference[filter.query]?.synced
        ) {
          old.toggle[filter.query] = [
            ...(userPreference[filter.query]?.values || []),
          ];
        }
      });
      return old;
    });
    setRefresh((refresh) => refresh + 1);
  }, [userPreference]);

  if (userPreferencesLoading) {
    return <Loader loading={true} />;
  }

  return (
    <>
      <Row>
        <Col>
          <h5>{title}</h5>
        </Col>
      </Row>

      <div className={DDTableFilterStyles.filterContainer}>
        {filters.map((filter, key) => {
          switch (filter.type) {
            case 'search':
              return (
                <Row
                  key={key}
                  className={DDTableFilterStyles.filterSearchContainer}
                >
                  <Col sm={{ size: 12 }}>
                    <TableSearch
                      defaultValues={{
                        select:
                          activeFilters.search[filter.query]?.select || '',
                        input: activeFilters.search[filter.query]?.input || '',
                      }}
                      options={filter.options}
                      submitable={filter.submitable}
                      onSelect={(id, queryName) => {
                        if (filter.customHandler) {
                          filter.onSelect(id);
                          return;
                        }

                        setActiveFilters((old) => {
                          old.search = {
                            [queryName]: {
                              input: id,
                              select: queryName,
                              submitable: true,
                              customHandler: filter.customHandler,
                              number: filter.number,
                              id: filter.query,
                            },
                          };
                          return old;
                        });
                        setRefresh((refresh) => refresh + 1);
                      }}
                      onSubmit={(input, select, submitable) => {
                        setRefresh((refresh) => refresh + 1);
                        if (filter.customHandler) {
                          filter.onSelect(input, select, submitable);
                        }
                        if (
                          userPreference[filter.query]?.synced &&
                          !filter.customHandler
                        ) {
                          update(userPreferenceRef, {
                            [filter.query]: {
                              ...userPreference[filter.query],
                              input,
                              select,
                              submitable,
                              customHandler: filter.customHandler,
                            },
                          });
                        }
                        setActiveFilters((old) => {
                          old.search = {
                            [filter.query]: {
                              input,
                              select,
                              submitable,
                              customHandler: filter.customHandler,
                              number: filter.number,
                              id: filter.query,
                            },
                          };
                          return old;
                        });
                      }}
                      onDeselect={(queryName) => {
                        setActiveFilters((old) => {
                          old.search[queryName] = {
                            input: ' ',
                            select: queryName,
                            submitable: true,
                            customHandler: filter.customHandler,
                            number: filter.number,
                            id: filter.query,
                          };
                          return old;
                        });
                        setRefresh((refresh) => refresh + 1);
                      }}
                      onChange={(input, select, submitable) => {
                        setRefresh((refresh) => refresh + 1);
                        if (filter.customHandler) {
                          filter.onSelect(input, select, submitable);
                          return;
                        }

                        if (
                          userPreference[filter.query]?.synced &&
                          !filter.customHandler
                        ) {
                          update(userPreferenceRef, {
                            [filter.query]: {
                              ...userPreference[filter.query],
                              input,
                              select,
                              submitable,
                            },
                          });
                        }
                        setActiveFilters((old) => {
                          old.search[filter.query] = {
                            input,
                            select,
                            submitable,
                            customHandler: filter.customHandler,
                            number: filter.number,
                            id: filter.query,
                          };
                          return old;
                        });
                      }}
                    />
                  </Col>
                </Row>
              );
            case 'toggle':
              return (
                <Row
                  key={key}
                  className={DDTableFilterStyles.filterToggleContainer}
                >
                  <Col sm={{ size: 8 }}>
                    <MultiSelectDropdown
                      defaultChecked={
                        userPreference[filter.query]?.synced
                          ? activeFilters.toggle[filter.query]
                          : []
                      }
                      single={filter.query === primary}
                      label={{
                        all: filter.labels.all || 'Loading all rows',
                        some: filter.labels.some || 'Loading filtered',
                      }}
                      values={filter.options}
                      handleSelect={(id, position) => {
                        if (filter.customHandler) {
                          filter.onSelect(id, position);
                          return;
                        }

                        setActiveFilters((old) => {
                          old.toggle[filter.query].push([
                            id,
                            position,
                            filter.customHandler,
                            filter.number,
                            filter.query,
                          ]);
                          if (
                            userPreference[filter.query]?.synced &&
                            !filter.customHandler
                          ) {
                            update(userPreferenceRef, {
                              [filter.query]: {
                                ...userPreference[filter.query],
                                values: old.toggle[filter.query],
                              },
                            });
                          }

                          return old;
                        });
                        setRefresh((refresh) => refresh + 1);
                      }}
                      handleDeselect={(id, position) => {
                        if (filter.customHandler) {
                          filter.onDeselect(id, position);
                          return;
                        }

                        setActiveFilters((old) => {
                          old.toggle[filter.query] = old.toggle[
                            filter.query
                          ].filter((value) => value[0] !== id);
                          if (
                            userPreference[filter.query]?.synced &&
                            !filter.customHandler
                          ) {
                            update(userPreferenceRef, {
                              [filter.query]: {
                                ...userPreference[filter.query],
                                values: old.toggle[filter.query],
                              },
                            });
                          }

                          return old;
                        });
                        setRefresh((refresh) => refresh + 1);
                      }}
                    />
                  </Col>
                </Row>
              );
          }
        })}
      </div>
    </>
  );
};

export const countries = async () => {
  const countryOrder = {
    US: 0,
    AU: 1,
    CA: 2,
    DE: 3,
    FR: 4,
    GB: 5,
    ES: 6,
    SE: 7,
    JP: 8,
    IT: 9,
  };

  const ifbApp = firebaseService.getIFBApp();
  const countriesDB = getDatabase(ifbApp);
  const countriesRef = ref(countriesDB, 'countries');
  const countriesQuery = query(
    countriesRef,
    orderByChild('has_dm_account'),
    equalTo(true)
  );

  const countriesList: any[] = (await get(countriesQuery)).val();
  if (!countriesList) return [];
  const countryLen = Object.values(countryOrder).length;
  const countryArr = new Array(countryLen);
  const sorted = Object.values(countriesList || {}).filter((country) => {
    if (countryOrder[country.id] !== undefined) {
      countryArr[countryOrder[country.id]] = country;
      return false;
    }
    return true;
  });
  return [...countryArr, ...sorted];
};
