import * as React from 'react';
import { Input, Button, FormGroup, FormFeedback } from 'reactstrap';
import { useState, useContext } from 'react';
import { PlusIcon } from '../../components/Icons';
import { AbilityContext } from '../../auth/Can';
import { arrayToOptions } from '../../utils/react';
import { logger } from '../../logging';
import { getDAMApp } from '../../services/firebase';
import moment from 'moment';
import { getFunctions, httpsCallable } from 'firebase/functions';

const iconStyles = require('../../components/icons.css');
const accountStyles = require('./account.css');

const nowUnix = moment().unix();
const dtNow = moment.unix(nowUnix).format('YYYY-MM-DD');
const DEFAULT_CREATION_DATE = moment(dtNow).unix();

export const CreateCredentialsRow = ({
  setError,
  system,
  account,
  accountId,
  reload,
  setReload,
  credentialTypes,
}) => {
  const damApp = getDAMApp();
  const functions = getFunctions(damApp);
  const ability = useContext(AbilityContext);

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [webhookSecret, setWebhookSecret] = useState('');
  const [appName, setAppName] = useState('');
  const [apiKeyName, setApiKeyName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiToken, setApiToken] = useState('');
  const [apiTokenLabel, setApiTokenLabel] = useState('');
  const [apiTokenCreatedDate, setApiTokenCreatedDate] = useState(
    DEFAULT_CREATION_DATE
  );
  const [creating, setCreating] = useState(false);

  const checkCommunityCredential = async () => {
    const callable = httpsCallable(
      functions,
      'accountManager-callableCheckCommunityToken'
    );
    await callable({ account_id: account.account_id, api_token: apiToken });
  };

  const createCredential = async () => {
    setCreating(true);
    // check validity of community cred before attempting to store in vault
    try {
      if (system === 'community') {
        await checkCommunityCredential();
      }
    } catch (e) {
      if (e.status) {
        logger.error(`e.status = ${e.status}`);
      }
      logger.error(`Error with Community Credential: ${e}`);
      setCreating(false);
      setError('Error checking Community Credential');
      return;
    }

    try {
      const callable = httpsCallable(
        functions,
        'accountManager-createCredential'
      );

      const data: any = {
        system,
        accountId,
        name,
        ...(system !== 'treasure_data' &&
          system !== 'community' &&
          system !== 'braze' && { username }),
        ...(system !== 'treasure_data' &&
          system !== 'community' &&
          system !== 'braze' && { password }),
        active: true,
        ...(system === 'slicktext' && { webhookSecret }),
        ...(system === 'attentive' && { webhookSecret }),
        ...(system === 'attentive' && { appName }),
        ...(system === 'attentive' && { apiKey }),
        ...(system === 'treasure_data' && { apiKey }),
        ...(system === 'braze' && { apiKeyName }),
        ...(system === 'braze' && { apiKey }),
        ...(system === 'community' && { apiToken }),
        ...(system === 'community' && { apiTokenLabel }),
        ...(system === 'community' && { apiTokenCreatedDate }),
      };

      await callable(data);

      setName('');
      setUsername('');
      setPassword('');
      setWebhookSecret('');
      setAppName('');
      setApiKeyName('');
      setApiKey('');
      setApiToken('');
      setApiTokenLabel('');
      setApiTokenCreatedDate(DEFAULT_CREATION_DATE);
      setReload(!reload);
      setCreating(false);
    } catch (e) {
      logger.error(e);
      setCreating(false);
      setError(e.message);
    }
  };

  const handleApiTokenCreatedDateChange = (e) => {
    const date = e.target.value;
    const ts = moment(date).unix();
    const dt = moment.unix(ts).format('YYYY-MM-DD');
    logger.debug(
      `[handleApiTokenCreatedDateChange] date: ${date} (${ts}) (${dt})`
    );
    setApiTokenCreatedDate(ts);
  };

  const fields = {
    name,
    ...(system !== 'treasure_data' &&
      system !== 'community' &&
      system !== 'braze' && { username }),
    ...(system !== 'treasure_data' &&
      system !== 'community' &&
      system !== 'braze' && { password }),
    ...(system === 'slicktext' && { webhookSecret }),
    ...(system === 'attentive' && { webhookSecret }),
    ...(system === 'attentive' && { appName }),
    ...(system === 'attentive' && { apiKey }),
    ...(system === 'braze' && { apiKeyName }),
    ...(system === 'braze' && { apiKey }),
    ...(system === 'treasure_data' && { apiKey }),
    ...(system === 'community' && { apiToken }),
    ...(system === 'community' && { apiTokenLabel }),
    ...(system === 'community' && { apiTokenCreatedDate }),
  };

  const dirty = () => {
    // logger.debug(fields);
    for (const [name, field] of Object.entries(fields)) {
      if (field !== '' && name !== 'apiTokenCreatedDate') {
        return true;
      }
    }

    return false;
  };

  const valid = () => {
    for (const [name, field] of Object.entries(fields)) {
      if (system === 'attentive' && name === 'webhookSecret') {
        // we don't require this to be set for attentive
        continue;
      }

      if (name === 'apiTokenCreatedDate') {
        const inValid = isApiTokenCreatedDateInvalid();
        if (inValid) {
          return false;
        }
      } else if (field === '') {
        return false;
      }
    }

    return true;
  };

  const isApiTokenCreatedDateInvalid = () => {
    const nowUnix = moment().unix();
    const dtNow = moment.unix(nowUnix).format('YYYY-MM-DD');
    const tsNow = moment(dtNow).unix();
    const isDateInFuture = apiTokenCreatedDate > tsNow;
    logger.debug(
      `[CreateCredentialsRow] isApiTokenCreatedDateInvalid: now: ${tsNow}, tokenDate: ${apiTokenCreatedDate}, tokenDate > now: ${isDateInFuture}`
    );
    return isDateInFuture;
  };

  const isDisabled =
    account.active === false && system !== 'braze' ? true : false;

  return (
    <tr className={accountStyles.CreateCredentialsRow}>
      <td>
        <FormGroup>
          <Input
            name="name"
            disabled={isDisabled}
            invalid={dirty() && name === ''}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="select"
          >
            {credentialTypes && arrayToOptions(Object.values(credentialTypes))}
          </Input>
          <FormFeedback>Please select a type</FormFeedback>
        </FormGroup>
      </td>
      {system === 'attentive' && (
        <td>
          <FormGroup>
            <Input
              disabled={isDisabled}
              name="appName"
              invalid={dirty() && appName === ''}
              placeholder="App Name"
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
            />
            <FormFeedback>Please enter the app name</FormFeedback>
          </FormGroup>
        </td>
      )}
      {system === 'braze' && (
        <td>
          <FormGroup>
            <Input
              disabled={isDisabled}
              name="apiKeyName"
              invalid={dirty() && apiKeyName === ''}
              placeholder="Api Key Name"
              value={apiKeyName}
              onChange={(e) => setApiKeyName(e.target.value)}
            />
            <FormFeedback>Please enter the api key name</FormFeedback>
          </FormGroup>
        </td>
      )}
      {(system === 'attentive' ||
        system === 'treasure_data' ||
        system === 'braze') && (
        <td>
          <FormGroup>
            <Input
              disabled={isDisabled}
              name="apiKey"
              invalid={dirty() && apiKey === ''}
              placeholder="Api Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
            <FormFeedback>Please enter the api key</FormFeedback>
          </FormGroup>
        </td>
      )}
      {system === 'community' && (
        <td>
          <FormGroup>
            <Input
              disabled={isDisabled}
              name="apiTokenLabel"
              invalid={dirty() && apiTokenLabel === ''}
              placeholder="Api Access Token Label"
              value={apiTokenLabel}
              onChange={(e) => setApiTokenLabel(e.target.value)}
            />
            <FormFeedback>Please enter the api access token label</FormFeedback>
          </FormGroup>
        </td>
      )}
      {system === 'community' && (
        <td>
          <FormGroup>
            <Input
              disabled={isDisabled}
              name="apiToken"
              invalid={dirty() && apiToken === ''}
              placeholder="Api Access Token"
              value={apiToken}
              onChange={(e) => setApiToken(e.target.value)}
            />
            <FormFeedback>Please enter the api access token</FormFeedback>
          </FormGroup>
        </td>
      )}
      {system === 'community' && (
        <td>
          <FormGroup>
            <Input
              type="date"
              disabled={isDisabled}
              name="apiToken"
              invalid={isApiTokenCreatedDateInvalid()}
              value={moment.unix(apiTokenCreatedDate).format('YYYY-MM-DD')}
              onChange={handleApiTokenCreatedDateChange}
            />
            <FormFeedback>
              Please enter the api access token created date
            </FormFeedback>
          </FormGroup>
        </td>
      )}
      {system !== 'treasure_data' &&
        system !== 'community' &&
        system !== 'braze' && (
          <td>
            <FormGroup>
              <Input
                name="username"
                disabled={isDisabled}
                placeholder="API User Name"
                invalid={dirty() && username === ''}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <FormFeedback>Please enter a username</FormFeedback>
            </FormGroup>
          </td>
        )}
      {system !== 'treasure_data' &&
        system !== 'community' &&
        system !== 'braze' && (
          <td>
            <FormGroup>
              <Input
                name="password"
                disabled={isDisabled}
                placeholder="API Password"
                invalid={dirty() && password === ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormFeedback>Please enter a password</FormFeedback>
            </FormGroup>
          </td>
        )}
      {system === 'slicktext' || system === 'attentive' ? (
        <td>
          <FormGroup>
            <Input
              name="webhookSecret"
              disabled={isDisabled}
              placeholder="Webhook key"
              invalid={dirty() && webhookSecret === ''}
              value={webhookSecret}
              onChange={(e) => setWebhookSecret(e.target.value)}
            />
            <FormFeedback>Please enter the webhook secret</FormFeedback>
          </FormGroup>
        </td>
      ) : null}
      <td>
        <Button
          disabled={
            ability.cannot('create', 'credential') ||
            !valid() ||
            isDisabled ||
            creating
          }
          onClick={async (e) => await createCredential()}
          className={accountStyles.addCredButton}
        >
          <PlusIcon fill="white" class={iconStyles.addButton} />
        </Button>
        {creating && (
          <img
            src="/images/loading.gif"
            className={accountStyles.createLoading}
          />
        )}
      </td>
    </tr>
  );
};
