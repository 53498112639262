import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputGroup, Dropdown } from 'reactstrap';
import { CrossIcon } from './Icons';
import { logger } from '../logging';

const pageStyles = require('./multiselect.css');

interface props {
  values: any[];
  handleSelect: any;
  handleDeselect: any;
  label?: {
    all: string;
    some: string;
  };
  defaultChecked?: any[];
  single: boolean;
}

export default function MultiSelectDropdown({
  values,
  handleSelect,
  handleDeselect,
  label,
  defaultChecked,
  single,
}: props) {
  const [countryDropdown, setCountryDropdown] = useState(false);
  const [currentSearch, setCurrentSearch] = useState(new RegExp('^'));
  const [activeValues, setActiveValues] = useState([]);
  const [focus, setFocus] = useState(false);
  const parentContainer = useRef(null);
  const countrySearchRef = useRef(null);

  useEffect(() => {
    logger.debug(`useEffect 1 focus`);
    setCountryDropdown(
      parentContainer.current.contains(document.activeElement)
    );
  }, [focus]);

  const updateSearch = (e) => {
    setCurrentSearch(new RegExp(`^${e.target.value}`.toLowerCase()));
  };

  useEffect(() => {
    logger.debug(`useEffect 2 values`);
    activeValues.forEach((value) => {
      handleDeselect(value[0]);
    });
    setActiveValues([]);
  }, [JSON.stringify(values)]);

  const removeSelection = (id) => {
    setActiveValues((oldValues) =>
      oldValues.filter((value) => value[0] !== id)
    );
    handleDeselect(id);
  };

  useEffect(() => {
    logger.debug(`useEffect 4 single`);
    if (single && activeValues.length >= 1) {
      activeValues.splice(1).forEach((value) => {
        handleDeselect(value[0]);
      });
    }
  }, [single]);

  useEffect(() => {
    logger.debug(`useEffect 5 defaultChecked`);
    if (defaultChecked?.length > 0) {
      setActiveValues(defaultChecked);
    }
  }, [defaultChecked]);

  const handleClick = (value, position) => {
    const id = value && value.id;
    // logger.debug(
    //   `[handleClick] called with value: ${value} (value.id: ${id}), position: ${position}`
    // );
    setFocus(false);
    setCountryDropdown(false);
    setCurrentSearch(new RegExp('^'));
    if (single && activeValues.length >= 1) {
      return;
    }
    if (!defaultChecked?.length && ![].concat(...activeValues).includes(id)) {
      setActiveValues([...activeValues, [id, position]]);
    }

    countrySearchRef.current.value = '';
    handleSelect(id, position);
  };

  return (
    <div ref={parentContainer} className={pageStyles.parentContainer}>
      <div className={pageStyles.inputContainer}>
        <Input
          placeholder={
            activeValues.length === 0 ? label?.all || '' : label?.some || ''
          }
          disabled={single && activeValues.length >= 1}
          onChange={updateSearch}
          innerRef={countrySearchRef}
          onFocus={() => setFocus(true)}
          // onBlur={() => setFocus(false)}
          className={pageStyles.countrySearchBox}
        />
      </div>
      <div hidden={!countryDropdown} className={pageStyles.countryDropdown}>
        {(values || []).map((value, key) => {
          return (
            !activeValues.find((activeValue) => activeValue[0] === value.id) &&
            currentSearch.test(value?.label.toLowerCase()) && (
              <button
                onClick={() => handleClick(value, key)}
                className="list-group-item-action list-group-item"
                key={key}
              >
                {value?.label} ({value?.id})
              </button>
            )
          );
        })}
      </div>
      <div className={pageStyles.countrySelectedContainer}>
        {activeValues.map((value, key) => (
          <Button
            onClick={() => removeSelection(value[0])}
            key={key}
            outline
            className={pageStyles.countrySelectedLabel}
          >
            <span>{values[value[1]]?.label}</span>
            <CrossIcon />
          </Button>
        ))}
      </div>
    </div>
  );
}
