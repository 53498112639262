.formRow {
  margin-top: 20px;
}

.button {
  margin-right: 10px;
}

.fullHeight {
  height: 100%;
}

.fullHeightTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fitContentTableContainer {
  width: fit-content;
  flex: 1;
  overflow-y: auto;
}

.optinsTable {
  border-collapse: separate;
  border-spacing: 0;
}

.optinsTable th {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  z-index: 1;
}

.optinsTable td {
  border-right: 1px solid #dee2e6;
}

.optinsTable th:first-child,
.optinsTable td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid #dee2e6;
}

.optinsTable tr:last-child td {
  /* Apply a left border on the first <td> or <th> in a row */
  border-bottom: 1px solid #dee2e6;
}

.tagsTable select,
.tagsTable input {
  width: 100px;
  margin-top: 5px;
}

.tagsTable {
  overflow-x: inherit;
}

.tagsTableContainer {
  overflow-x: inherit;
}

.searchRow {
  margin-bottom: 10px;
}

.sortButton {
  padding-left: 0;
}

.queryRow {
  margin-bottom: 20px;
}

.pagination button {
  margin: 5px;
}

.columnsButton {
  margin: 15px 0;
}

.rowButton {
  margin-right: 15px;
}

.optinsTableContainer {
  width: fit-content;
}

.optinsModalBody,
.optinsModalFooter,
.optinsModalHeader {
  display: block;
  padding: 50px;
}

.optinsModalBodyRow {
  padding: 20px;
}

.optinsModalFooterRow {
  padding: 20px;
  text-align: center;
}

.optinsModalFooterRow button {
  margin: 10px;
}

.optinsModal {
  max-width: 50%;
}

.ruleInputBottom {
  margin-top: 5px;
}

.hiddenFilters {
  display: none;
}
