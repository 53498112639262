import { useCallback, useEffect, useRef } from 'react';
import useEventCallback from '@restart/hooks/useEventCallback';
import { noop, warn } from '../../utils';
function isLeftClickEvent(event) {
  return event.button === 0;
}
function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}
var InitialTriggerEvents = {
  click: 'mousedown',
  mouseup: 'mousedown',
  pointerup: 'pointerdown'
};

/**
 * The `useClickOutside` hook registers your callback on the document that fires
 * when a pointer event is registered outside of the provided ref or element.
 */
function useClickOutside(ref) {
  var onClickOutside = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : noop;
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    disabled = _ref.disabled,
    _ref$clickTrigger = _ref.clickTrigger,
    clickTrigger = _ref$clickTrigger === void 0 ? 'click' : _ref$clickTrigger;
  var preventMouseClickOutsideRef = useRef(false);
  var waitingForTrigger = useRef(false);
  var handleMouseCapture = useCallback(function (e) {
    var currentTarget = ref.current;
    warn(!!currentTarget, 'ClickOutside captured a close event but does not have a ref to compare it to. ' + 'useClickOutside(), should be passed a ref that resolves to a DOM node');
    preventMouseClickOutsideRef.current = !currentTarget || isModifiedEvent(e) || !isLeftClickEvent(e) || !!currentTarget.contains(e.target) || waitingForTrigger.current;
    waitingForTrigger.current = false;
  }, [ref]);
  var handleInitialMouse = useEventCallback(function (e) {
    var currentTarget = ref.current;
    if (currentTarget !== null && currentTarget !== void 0 && currentTarget.contains(e.target)) {
      waitingForTrigger.current = true;
    } else {
      // When clicking on scrollbars within current target, click events are not
      // triggered, so this ref is never reset inside `handleMouseCapture`. This
      // would cause a bug where it requires 2 clicks to close the overlay.
      waitingForTrigger.current = false;
    }
  });
  var handleMouse = useEventCallback(function (e) {
    if (!preventMouseClickOutsideRef.current) {
      onClickOutside(e);
    }
  });
  useEffect(function () {
    var _ref$current, _ownerWindow$event, _ownerWindow$parent;
    if (disabled || ref == null) return undefined;
    var doc = ((_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.ownerDocument) || document;
    var ownerWindow = doc.defaultView || window;

    // Store the current event to avoid triggering handlers immediately
    // For things rendered in an iframe, the event might originate on the parent window
    // so we should fall back to that global event if the local one doesn't exist
    // https://github.com/facebook/react/issues/20074
    var currentEvent = (_ownerWindow$event = ownerWindow.event) !== null && _ownerWindow$event !== void 0 ? _ownerWindow$event : (_ownerWindow$parent = ownerWindow.parent) === null || _ownerWindow$parent === void 0 ? void 0 : _ownerWindow$parent.event;
    var removeInitialTriggerListener = null;
    if (InitialTriggerEvents[clickTrigger]) {
      doc.addEventListener(InitialTriggerEvents[clickTrigger], handleInitialMouse, true);
      removeInitialTriggerListener = function removeInitialTriggerListener() {
        doc.removeEventListener(InitialTriggerEvents[clickTrigger], handleInitialMouse);
      };
    }
    var handleMouseTrigger = function handleMouseTrigger(e) {
      // skip if this event is the same as the one running when we added the handlers
      if (e === currentEvent) {
        currentEvent = undefined;
        return;
      }
      handleMouse(e);
    };

    // Use capture for this listener so it fires before React's listener, to
    // avoid false positives in the contains() check below if the target DOM
    // element is removed in the React mouse callback.
    doc.addEventListener(clickTrigger, handleMouseCapture, true);
    doc.addEventListener(clickTrigger, handleMouseTrigger, true);
    return function () {
      var _removeInitialTrigger;
      (_removeInitialTrigger = removeInitialTriggerListener) === null || _removeInitialTrigger === void 0 || _removeInitialTrigger();
      doc.removeEventListener(clickTrigger, handleMouseCapture);
      doc.removeEventListener(clickTrigger, handleMouseTrigger);
    };
  }, [ref, disabled, clickTrigger, handleMouseCapture, handleInitialMouse, handleMouse]);
}
export default useClickOutside;