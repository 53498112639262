"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};

// src/index.js
var src_exports = {};
__export(src_exports, {
  default: () => src_default
});
module.exports = __toCommonJS(src_exports);

// src/AvMessage.js
var _lastId;
var AvMessage = class {
  constructor() {
    __publicField(this, "subscribers", {});
    __publicField(this, "getEventData", (event) => {
      const isSameWindow = event.source === window;
      if (!this.isEnabled || // do nothing if not enabled
      !event || !event.data || !event.origin || !event.source || // check event exists and has necessary properties
      !this.isDomain(event.origin)) {
        return;
      }
      let { data } = event;
      if (typeof data === "string") {
        try {
          data = JSON.parse(data);
        } catch (e) {
        }
      }
      if (typeof data === "string") {
        event = data;
        data = void 0;
      } else {
        event = data && data.event || this.DEFAULT_EVENT;
      }
      const metadata = { isSameWindow };
      this.onMessage(event, data, metadata);
    });
    __privateAdd(this, _lastId, 0);
    this.isEnabled = true;
    this.DEFAULT_EVENT = "avMessage";
    this.DOMAIN = /https?:\/\/([\w-]+\.)?availity\.(com|net)/;
    window.addEventListener("message", this.getEventData);
  }
  enabled(value) {
    if (arguments.length > 0) {
      this.isEnabled = !!value;
    }
    return this.isEnabled;
  }
  subscribe(event, callback, options) {
    var _a;
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }
    __privateSet(this, _lastId, __privateGet(this, _lastId) + 1);
    const id = __privateGet(this, _lastId);
    const ignoreSameWindow = (_a = options == null ? void 0 : options.ignoreSameWindow) != null ? _a : true;
    const subscriber = { id, callback, options: { ignoreSameWindow } };
    this.subscribers[event].push(subscriber);
    return () => {
      this.subscribers[event] = this.subscribers[event].filter((subscriber2) => subscriber2.id !== id);
    };
  }
  // remove all subscribers for this event
  unsubscribe(event) {
    delete this.subscribers[event];
  }
  unsubscribeAll() {
    this.subscribers = {};
  }
  onMessage(event, data, metadata) {
    const { isSameWindow } = metadata;
    if (this.subscribers[event]) {
      for (const subscriber of this.subscribers[event]) {
        const { ignoreSameWindow } = subscriber.options;
        const skip = isSameWindow && ignoreSameWindow;
        if (!skip) {
          subscriber.callback(data);
        }
      }
    }
  }
  // if current domain doesn't match regex DOMAIN, return true.
  isDomain(url) {
    return !this.DOMAIN.test(this.domain()) || this.DOMAIN.test(url);
  }
  domain() {
    if (window.location.origin) {
      const url = window.location.origin;
      return url.replace("essentials", "apps");
    }
    if (window.location.hostname) {
      const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;
      return url.replace("essentials", "apps");
    }
    return "*";
  }
  send(payload, target = window.top) {
    if (!this.isEnabled || !payload) {
      return;
    }
    try {
      const message = typeof payload === "string" ? payload : JSON.stringify(payload);
      target.postMessage(message, this.domain());
    } catch (error) {
      console.warn("AvMessage.send()", error);
    }
  }
};
_lastId = new WeakMap();
var AvMessage_default = AvMessage;

// src/index.js
var src_default = new AvMessage_default();
