import React from 'react';
import { Col, Row } from 'reactstrap';

import { BrazeOptinRow } from './BrazeOptinRow';

const brazeStyles = require('./braze.css');

export const BrazeEmailOptin = ({
  account,
  enumerations,
  setTabSaving,
  handleOptinActiveChange,
}) => {
  const channel = {
    value: 'email',
    label: 'Email',
  };

  return (
    <React.Fragment>
      <Row className={brazeStyles.optinEnabledRowHeader}>
        <Col sm={10}>
          <h4>Email Channel Optin</h4>
        </Col>
      </Row>
      <BrazeOptinRow
        channel={channel}
        account={account}
        enumerations={enumerations}
        setTabSaving={setTabSaving}
        showSubscriptionGroup={false}
        handleOptinActiveChange={handleOptinActiveChange}
      />
    </React.Fragment>
  );
};
