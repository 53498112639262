.inputContainer {
  display: flex;
}

.countrySelectedLabel {
  margin-right: 8px;
  margin-top: 10px;
  align-items: center;
  display: flex;
  gap: 4px;
}

.countrySelectedContainer {
  white-space: nowrap;
  display: flex;
}

.countryDropdown {
  width: 100%;
  position: absolute;
  z-index: 2;
}

.countryDropdown button {
  background-color: #fff;
  border: 1px solid #00000020;
  padding: 0.75rem 1.25rem;
  display: block;
  position: relative;
  z-index: 2;
}

.countryDropdown button:active {
  color: #212529;
  background-color: #e9ecef;
}

.countryDropdown button:hover {
  z-index: 1;
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.parentContainer {
  position: relative;
}
