import * as React from 'react';
import { Alert, Button, Col, Row } from 'reactstrap';
import { BrazeFields } from './BrazeFields';
import { Form } from '@availity/form';
import { object, string, number, ref } from 'yup';
import '@availity/yup';
import { logger } from '../../../logging';
import { AbilityContext } from '../../../auth/Can';
import { useContext } from 'react';
import {
  validateBrazePartyId,
  validateAccountId,
  validateBrazeAccountName,
} from './validation';
import { Loader } from '../../../components/Loader';

const BrazeFormValidation = object().shape({
  account_name: string().required(),
  account_id: string().required(),
  party_id: number().required(),
  braze_instance_id: string().required(),
  repertoire_owner: string().required(),
  participation_status: string().required(),
  is_b2c_or_b2b: string().required(),
  is_umg_or_partner: string().required(),
  language: string().required(),
  timezone: string().required(),
  ipMapping: string().required(),
});

const BrazeFormErrorCheck = ({
  errors,
  touched,
  isValidating,
  setHasFormErrors,
  setFormErrors,
  setFormTouched,
}) => {
  // logger.debug(
  //   `[BrazeForm] BrazeFormErrorCheck errors: ${JSON.stringify(
  //     errors
  //   )}, touched: ${JSON.stringify(touched)}, isValidating: ${JSON.stringify(
  //     isValidating
  //   )}`
  // );

  const hasErrors = Object.keys(errors).length > 0;
  // logger.debug(`[BrazeForm] BrazeFormErrorCheck hasErrors: ${hasErrors}`);

  React.useEffect(() => {
    setFormErrors(errors);
    setHasFormErrors(hasErrors);
    setFormTouched(touched);
  });

  return null;
};

export const BrazeForm = ({
  canSave,
  model,
  save,
  account,
  system,
  setCanSave,
  setAccountHasCreds,
}) => {
  const [hasFormErrors, setHasFormErrors] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [formTouched, setFormTouched] = React.useState({});
  const [canAccessBraze, setCanAccessBraze] = React.useState(null);

  const ability = useContext(AbilityContext);

  React.useEffect(() => {
    if (ability) {
      setCanAccessBraze(ability.can('read', 'braze'));
    }
  }, [ability]);

  if (canAccessBraze === null) {
    return <Loader loading={true} />;
  }

  const defaultBrazeFormValues = {
    account_name: '',
    account_id: '',
    party_id: '',
    party_name: '',
    braze_instance_id: '',
    repertoire_owner: '',
    participation_status: '',
    is_b2c_or_b2b: '',
    is_umg_or_partner: '',
    language: '',
    timezone: '',
    ipMapping: '',
    active: false,
    ...model,
  };

  const handleSubmit = async (values, { setFieldError }) => {
    logger.debug(
      `[BrazeForm] handleSubmit called: [ACTIVE]: ${
        values.active
      } values: ${JSON.stringify(values)}`
    );

    // handle validateAccountName here validateBrazeAccountName
    const isAccountNameValid = await validateBrazeAccountName(
      values.account_name,
      values.id,
      setFieldError,
      setHasFormErrors
    );

    // handle async account_id validation
    const isAccountIdValid = await validateAccountId(
      values['account_id'],
      values['key'],
      setFieldError
    );

    const isPartyIdValid = await validateBrazePartyId(
      values.party_id,
      values.party_name,
      values.id,
      setFieldError
    );

    logger.debug(
      `[BrazeForm] handleSubmit - [save about to be called] formErrors: ${JSON.stringify(
        formErrors
      )}, hasFormErrors: ${hasFormErrors}, isAccountNameValid: ${isAccountNameValid}, isAccountIdValid: ${isAccountIdValid}, isPartyIdValid: ${isPartyIdValid}`
    );

    if (
      isAccountNameValid === true &&
      isAccountIdValid === true &&
      isPartyIdValid === true
    ) {
      await save(values, formErrors);
    }
  };

  if (canAccessBraze === false) {
    return (
      <React.Fragment>
        <Row>
          <Col sm="12">
            <Alert color="info">
              Sorry you don't have permission to view Braze accounts.
            </Alert>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  const submitDisabled = !canSave || hasFormErrors;

  return (
    <Form
      initialValues={defaultBrazeFormValues}
      onSubmit={handleSubmit}
      validationSchema={BrazeFormValidation}
    >
      {({ errors, touched, isValidating }) => (
        <React.Fragment>
          <Row>
            <Col sm="12">
              <BrazeFields
                account_id={account && account.account_id}
                system={system}
                model={model}
                setCanSave={setCanSave}
                errors={errors}
                setAccountHasCreds={setAccountHasCreds}
              />
            </Col>
          </Row>
          <Button
            type="submit"
            disabled={submitDisabled}
            color={submitDisabled ? 'secondary' : 'primary'}
          >
            Save
          </Button>
          <BrazeFormErrorCheck
            errors={errors}
            touched={touched}
            isValidating={isValidating}
            setHasFormErrors={setHasFormErrors}
            setFormErrors={setFormErrors}
            setFormTouched={setFormTouched}
          />
        </React.Fragment>
      )}
    </Form>
  );
};
