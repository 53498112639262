import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
  TreasureDataFields,
  validateAccountId,
  validateTDAccountName,
} from './TreasureDataFields';
import { Form } from '@availity/form';
import { object, string, number, ref } from 'yup';
import '@availity/yup';
import { logger } from '../../../logging';

const TreasureDataFormValidation = object().shape({
  account_name: string().required(),
  account_id: string().required(),
});

const accountStyles = require('../account.css');

const TreasureDataFormErrorCheck = ({
  errors,
  touched,
  isValidating,
  setHasFormErrors,
  setFormErrors,
  setFormTouched,
}) => {
  logger.debug(
    `[TreasureDataForm] TreasureDataFormErrorCheck errors: ${JSON.stringify(
      errors
    )}, touched: ${JSON.stringify(touched)}, isValidating: ${JSON.stringify(
      isValidating
    )}`
  );

  const hasErrors = Object.keys(errors).length > 0;
  logger.debug(
    `[TreasureDataForm] TreasureDataFormErrorCheck hasErrors: ${hasErrors}`
  );

  React.useEffect(() => {
    setFormErrors(errors);
    setHasFormErrors(hasErrors);
    setFormTouched(touched);
  });

  return null;
};

export const TreasureDataForm = ({
  canSave,
  model,
  save,
  account,
  system,
  setCanSave,
}) => {
  const [hasFormErrors, setHasFormErrors] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [formTouched, setFormTouched] = React.useState({});
  const defaultTreasureDataFormValues = {
    account_name: '',
    account_id: '',
    active: true,
    ...model,
  };

  const handleSubmit = async (values, { setFieldError }) => {
    logger.debug(
      `[TreasureDataForm] handleSubmit called: [ACTIVE]: ${JSON.stringify(
        values.active
      )} values: ${JSON.stringify(values)}`
    );

    // handle validateAccountName here validateTDAccountName
    const isAccountNameValid = await validateTDAccountName(
      values.account_name,
      values.id,
      setFieldError,
      setHasFormErrors
    );

    // handle async ddAccountId validation
    const isAccountIdValid = await validateAccountId(
      values['account_id'],
      values['key'],
      setFieldError
    );

    logger.debug(
      `[TreasureDataForm] handleSubmit - [save about to be called] formErrors: ${JSON.stringify(
        formErrors
      )}, hasFormErrors: ${hasFormErrors}, isAccountNameValid: ${isAccountNameValid}, isAccountIdValid: ${isAccountIdValid}`
    );

    if (isAccountNameValid === true && isAccountIdValid === true) {
      await save(values, formErrors);
    }
  };

  const submitDisabled = !canSave || hasFormErrors;

  return (
    <Form
      initialValues={defaultTreasureDataFormValues}
      onSubmit={handleSubmit}
      validationSchema={TreasureDataFormValidation}
    >
      {({ errors, touched, isValidating }) => (
        <React.Fragment>
          <Row>
            <Col sm="12">
              <TreasureDataFields
                account_id={account && account.account_id}
                system={system}
                model={model}
                setCanSave={setCanSave}
                errors={errors}
              />
            </Col>
          </Row>
          <Button
            className={accountStyles.submitButton}
            type="submit"
            disabled={submitDisabled}
            color={submitDisabled ? 'secondary' : 'primary'}
          >
            Save
          </Button>
          <TreasureDataFormErrorCheck
            errors={errors}
            touched={touched}
            isValidating={isValidating}
            setHasFormErrors={setHasFormErrors}
            setFormErrors={setFormErrors}
            setFormTouched={setFormTouched}
          />
        </React.Fragment>
      )}
    </Form>
  );
};
